  <div class="body-bg">
    <section class="crancy-wc crancy-wc__full crancy-bg-cover" style="background-image: url('assets/img/credential-bg.svg');">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="crancy-wc__form">
              <div class="crancy-wc__form--middle">
                <div class="crancy-wc__banner">
                  <img src="assets/img/{{pastaImagens}}/tela-login.jpg" alt="Tela de Login">
                </div>

                <!-- bloco de identificação -->
                <div class="crancy-wc__form-inner" *ngIf="box == '1'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB1}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt3}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" role="tabpanel">
                        <div class="form-group crancy-wp__form--group">
                          <div class="form-group__input w-100">
                            <input id="doc" class="crancy-wc__form-input" type="text" name="doc" placeholder="CPF/CNPJ" value="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="getClienteByCQC()">
                          {{label.go}}
                          <span><i class="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- bloco de tipo de comunicação -->
                <div class="crancy-wc__form-inner" *ngIf="box == '2'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB2}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt2}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                        <label class="form-group crancy-wp__form--group" for="wayWhats">
                          <input class="radioButton" type="radio" id="wayWhats" name="wayContact" value="whats">
                          <label class="homec-form-label" for="wayWhats">
                            {{label.getSMS}}
                            <br>
                            <span>{{infoCliente.smsHidden}}</span>
                          </label>
                        </label>
                        <label class="form-group crancy-wp__form--group mg-top-20" for="wayEmail">
                          <input class="radioButton" type="radio" id="wayEmail" name="wayContact" value="email">
                          <label class="homec-form-label" for="wayEmail">
                            {{label.getEmail}}
                            <br>
                            <span>{{infoCliente.emailHidden}}</span>
                          </label>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="getWayContact()">
                          {{label.go}}
                          <span><i class="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- bloco de confirmação de token -->
                <div class="crancy-wc__form-inner" *ngIf="box == '3'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB3}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt4}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                        <div class="form-group form-mg-top30">
                        <ul class="crancy-wc__form-verify">
                          <input id="code-verification-1" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-2" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-3" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-4" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                        </ul>
                      </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="verifyCode()">
                          {{label.go}}
                          <span><i class="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- bloco de nova senha -->
                <div class="crancy-wc__form-inner" *ngIf="box == '4'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB4}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt5}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                        <div class="form-group form-mg-top30">
                          <div class="form-group__input w-100">
                            <input class="crancy-wc__form-input" id ="senha1"
                              placeholder={{label.newPass}} type="password"required="required" (keyup)="strongPass()">
                            <span class="crancy-wc__toggle togle-pass" (click)="togglePass('senha1')"><i class="fas fa-eye"></i></span>
                          </div>
                          <div class="form-group__input w-100 mt-3 mb-3">
                            <input class="crancy-wc__form-input" id ="senha2"
                            placeholder={{label.newPassConfirm}} type="password"required="required" (keyup)="strongPass()">
                            <span class="crancy-wc__toggle togle-pass" (click)="togglePass('senha2')"><i class="fas fa-eye"></i></span>
                          </div>
                          <div class="mb-3 mt-3">
                            <!-- senha com no mínimo de 8 caracteres -->
                            <p *ngIf="strongPassmin8 == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPassmin8}}
                            </p>
                            <p *ngIf="strongPassmin8 == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPassmin8}}
                            </p>
                            <!-- senha com no mínimo de 1 letra maiúscula -->
                            <p *ngIf="strongPass1Upper == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPass1Upper}}
                            </p>
                            <p *ngIf="strongPass1Upper == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPass1Upper}}
                            </p>
                            <p *ngIf="strongPass1Lower == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPass1Lower}}
                            </p>
                            <p *ngIf="strongPass1Lower == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPass1Lower}}
                            </p>
                            <p *ngIf="strongPass1Number == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPass1Number}}
                            </p>
                            <p *ngIf="strongPass1Number == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPass1Number}}
                            </p>
                            <p *ngIf="strongPass1Especial == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPass1Especial}}
                            </p>
                            <p *ngIf="strongPass1Especial == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPass1Especial}}
                            </p>
                            <p *ngIf="strongPassIgual == true" class="text-primary">
                              <i class="fa-regular fa-circle-check"></i>
                              {{label.strongPassIgual}}
                            </p>
                            <p *ngIf="strongPassIgual == false" class="text-danger">
                              <i class="fa-regular fa-circle-xmark"></i>
                              {{label.strongPassIgual}}
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="setSenha()">
                          {{label.btnFinal}}
                          <span><i class="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-footer></app-footer>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>