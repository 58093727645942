import { Component , OnInit} from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import * as translations from './header.translate.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent  implements OnInit {
  translate: any = translations;
  language: any = lib.getSession('language') == null ? 'pt' : lib.getSession('language');
  label: any = {};
  lib = lib;
  linksRedes = (lib.getSession('pastaImagens') == 'cpp')?{
      "facebook":"https://www.facebook.com/casadopicapau",
      "instagram":"https://www.instagram.com/casadopicapau/",
      "linkedin":"https://www.linkedin.com/company/casadopicapau",
      "youtube":"https://www.youtube.com/@casadopica-pau"
    }:{
      "facebook":"https://www.facebook.com/hohljohndeere",
      "instagram":"https://www.instagram.com/hohljohndeere/",
      "linkedin":"https://www.linkedin.com/company/hohljohndeere",
      "youtube":"https://www.youtube.com/@hohljohndeere"
    };
  constructor(private clienteSRV: ClienteService) {
    this.label = lib.getTranslate(this.translate);
    console.log(this.linksRedes);
    
  }
  
  ngOnInit(): void {
    this.buscarDadosCliente();
    
  }

  async buscarDadosCliente(){
    let objSes = lib.getSession('user');
    var cliMongo:any = {};

    cliMongo.response = {};
    cliMongo = lib.getSession("obj_cliente")
    
    if(cliMongo == undefined || cliMongo.response == null)
      cliMongo = await this.clienteSRV.getClienteMongo(objSes.codigo).toPromise();

    if(cliMongo != undefined && cliMongo.response != undefined){
      if(cliMongo.response.imgperfil != undefined && cliMongo.response.imgperfil != ""){
        var ele = document.getElementById('imgPerfil');
        ele?.setAttribute('src', cliMongo.response.imgperfil);
      }

      lib.setSession("obj_cliente", cliMongo);
    }
  }
}