import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';
export interface interObjDocumentos
{
  "filial"  : string,
  "num"     : string,
  "cliente" : string,
  "loja"    : string,
  "tipo"    : string,
  "serie"   : string,
  "parcela" : string,
}

@Injectable({providedIn: 'root'})

export class DocumentosService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getDocument(obj:interObjDocumentos):Observable<objRetorno>{
        return this.http.post("RetornarPdfNFBoleto", obj );
    }
    
    getTermoPrivacidade():Observable<objRetorno>{
        return this.http.get("retornarTermoPrivacidade");
    }

    getConfissaoDivida():Observable<objRetorno>{
        return this.http.get("retornarConfissaoDivida");
    }
}