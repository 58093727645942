    <header class="crancy-header">
      <div class="container g-0">
        <div class="row g-0">
          <div class="col-12">

            <div class="crancy-header__inner">
              <div class="crancy-header__middle">
                <div class="crancy-header__left">
                  <div class="crancy-header__nav-bottom">

                    <div class="logo crancy-sidebar-padding">
                      <a class="crancy-logo" href="/home">

                        <img class="crancy-logo__main logo" src="assets/img/logo.png" alt="#">

                        <img class="crancy-logo__main--small" src="assets/img/logo-icon.png" alt="#">
                      </a>
                    </div>
                  </div>

                  <div id="crancy__sicon" class="crancy__sicon close-icon">
                    <img src="assets/img/arrow-icon.svg">
                  </div>

                  <div class="crancy-header__form drax">
                    <form class="crancy-header__form-inner" action="#">
                      <button class="search-btn" type="submit">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="9.78639" cy="9.78614" r="8.23951" stroke="#9AA2B1" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M15.5176 15.9448L18.7479 19.1668" stroke="#9AA2B1" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <input name="s" value type="text" placeholder="{{label.busca}}">
                    </form>
                  </div>

                </div>
                <div class="crancy-header__right">
                  <div class="crancy-header__group">
                    <div class="crancy-header__group-two">
                      <div class="crancy-header__right">
                        <select class="crancy-wc__footer--language change-language" (change)="lib.changeLanguage($event.target)"> 
                          <option value="" disabled selected>{{label.idioma}}</option>           
                          <option value="pt">Português</option>
                          <option value='en'>English</option>
                          <option value='es'>Español</option>
                        </select>

                        <div class="crancy-header__options drax">

                          <div class="crancy-header__alarm">
                            <a class="crancy-header__blink" href="notifications.html">
                              <i class="fa-regular fa-bell"></i>
                              <span class="crancy-header__count crancy-color4__bg">4</span>
                            </a>

                            <div class="crancy-balance crancy-alarm__hover">
                              <div class="crancy-alarm__hover--inner">
                                <h3 class="crancy-balance__title">{{label.notificacao}}
                                </h3>

                                <ul class="crancy-balance_list">
                                  <li>
                                    <div class="crancy-balance-info">
                                      <div class="crancy-alarm__content">
                                        <h4 class="crancy-balance-name"><a href="notifications.html">Your
                                            Account has been created <strong class="crancy-balance__second">successfully
                                              done</strong></a></h4>
                                        <p class="crancy-alarm__text">2 days ago
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="crancy-balance-info">
                                      <div class="crancy-alarm__content">
                                        <h4 class="crancy-balance-name"><a href="notifications.html">Your
                                            Account has been created <strong class="crancy-balance__second">successfully
                                              done</strong></a></h4>
                                        <p class="crancy-alarm__text">2 days ago
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div class="crancy-balance__button"><a href="notifications.html"
                                  class="crancy-balance__sell-all">{{label.verTodasNotificacoes}}</a></div>
                            </div>
                          </div>
                        </div>

                        <div class="crancy-header__options">
                          <div class="row">
                            <!-- face, insta, linkedin, youtube -->
                            <div class="col-3 text-center">
                              <a href="{{linksRedes.facebook}}" class="link-redes" target="_blank">
                                <i class="fab fa-facebook-f"></i>
                              </a>
                            </div>
                            <div class="col-3 text-center">
                              <a href="{{linksRedes.instagram}}" class="link-redes" target="_blank">
                                <i class="fab fa-instagram"></i>
                              </a>
                            </div>
                            <div class="col-3 text-center">
                              <a href="{{linksRedes.linkedin}}" class="link-redes" target="_blank">
                                <i class="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                            <div class="col-3 text-center">
                              <a href="{{linksRedes.youtube}}" class="link-redes" target="_blank">
                                <i class="fab fa-youtube"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="crancy-header__author">
                          <a href="/#">
                            <div class="crancy-header__author-img"><img id="imgPerfil" src="assets/img/default.png" alt="#"></div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>