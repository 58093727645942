import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class MeiosPagamentoService{
    
    constructor (private http: HttpService<any>){
    }

    getMeiosPagamento():Observable<any>{
        return this.http.post("WsForPag", { "token" : "" } );
    }
}