import { Component, OnInit } from '@angular/core';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { environment } from '../environments/environment';
import { lib } from './shared/services/common/lib.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css',
  ]
})
export class AppComponent implements OnInit {
  private socket!: Socket;
  title = 'web-cliente';
  language: any = lib.getSession('language') == null ? 'pt' : lib.getSession('language');

  ngOnInit() {
    document.getElementsByTagName('html')[0].setAttribute('lang', this.language);//seta a linguagem no html
    //add um css global
    this.changeTheme();
    this.socket = io(
      environment.api.baseUrl,{
        path: '/socket.io',
        reconnection: true,
        transports: ['websocket'],
        upgrade: false
    });

    this.socket.on('connect', function() {
        console.log('connected');
    });

    this.socket.on('call', function(data) {
        console.log(data);
    });

    this.socket.on('connect_error', function(err: any) {
        console.log(err);
    });

    // this.socket.emit('call', 'Hello, server!', function(response: any) {
    //     console.log(response);
    // });

    this.socket.on('full_reload',() => {
      if (lib.getSession('usuario')) {
        lib.setSession('usuario');
        window.location.href = '/';
      }
    });
  }

  changeTheme() {
    //get host 
    let host = window.location.host;
    let pastaImagens = '';
    // se tiver casadopicapau no host, muda o tema
    let theme = 'root';
    if (host.includes('casadopicapau')) {
      pastaImagens = 'cpp';
    }else{
      pastaImagens = 'hohl';
      theme = 'root';
    }
    let css = document.createElement('link');
    css.href = `assets/css/${theme}.css`;
    css.rel = 'stylesheet';
    css.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(css);
    
    //muda o favicon de acordo com o host
    let favicon = document.createElement('link');
    favicon.href = `assets/img/${pastaImagens}/icon-color.png`;
    favicon.rel = 'icon';
    favicon.type = 'image/png';
    document.getElementsByTagName('head')[0].appendChild(favicon);
    lib.setSession('pastaImagens', pastaImagens);
  }
  
}