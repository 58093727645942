import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { lib } from 'src/app/shared/services/common/lib.service';
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import { MatTabsModule } from '@angular/material/tabs';
import { EmailService } from 'src/app/shared/services/email/email.service';
import { WhatsappService } from 'src/app/shared/services/whatsapp/whatsapp.service';
import { TimelineService } from 'src/app/shared/services/timeline/timeline.service';
import { objTimeline } from 'src/app/shared/entities/timeline.entity';
import * as translations from './perfil.translate.json';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})

export class PerfilComponent implements OnInit{

  language: any = lib.getSession('language');
  translate: any = translations;
  label: any = {};

  consideraEndOri: boolean = false;

  strongPassmin8 = false;
  strongPass1Upper = false;
  strongPass1Lower = false;
  strongPass1Especial = false;
  strongPass1Number = false;
  strongPassIgual = false;

  clienteSql: any;
  clienteMongo: any;

  boxOrigem:string = "";
  box:string = '1';
  //1 = informar os dados/senha,
  //2 = tipo de meio de contato,
  //3 = codigo de verificação,
  //4 = sucesso

  tokenInputFull:boolean = false;

  colTimeLineTotal:any = [];
  colTimeLine:any = [];
  timelineRows:number = 10;

  constructor(private routerSRV: Router,
              private clienteSRV: ClienteService,
              private emailService: EmailService,
              private whatsappService: WhatsappService,
              private tlSRV: TimelineService) {
    this.label = lib.getTranslate(this.translate);
  }

  ngOnInit(): void {
    this.buscarDadosCliente();
    this.setTimeline();
  }

  async buscarDadosCliente(){
    lib.preloader('show');

    let objSes = lib.getSession('user');
    let objCli = { "codusr": objSes.codigo, "lojausr": "01" };

    let infoCliente:any = await this.clienteSRV.getClienteFullByCod(objCli).toPromise();

    if(infoCliente != undefined && infoCliente.data != undefined){
      var objCliente = infoCliente.data;

      this.clienteSql = objCliente;

      this.clienteSql.Telefone = this.clienteSql.Telefone.replace(/\D/g, '');//remove todos os caracteres que não são números
      this.clienteSql.smsHidden = this.clienteSql.Telefone.replace(/.(?=.{4})/g, '*');//pega os 4 últimos caracteres do telefone e substitui o resto por *
      
      let email = this.clienteSql.Email.split('@');//separa o email em 2 partes, antes e depois do @
      let emailHidden =(email[0].length>5)? email[0].substr(0,4): email[0].substr(0,2);//pega os 5 primeiros caracteres do email

      emailHidden += '*'.repeat(email[0].length);//substitui o resto por *
      emailHidden += '@' + email[1].replace(/.(?=.{3})/g, '*');//pega os 6 últimos caracteres do email e substitui o resto por *
      this.clienteSql.emailHidden = emailHidden;//atribui o emailHidden ao objeto infoCliente

      $('#inputInfPesNome').val(objCliente["nome"]);
      $('#inputInfPesRazao').val(objCliente["Nome reduzido"]);
      if (objCliente['data nasc']) {
        $('#inputInfPesNascimento').val(`${objCliente['data nasc'].substr(0,4)}-${objCliente['data nasc'].substr(4,2)}-${objCliente['data nasc'].substr(6,2)}`);
      }

      $("#inputInfPesEmail").val(objCliente["Email"]);

      let teleF = objCliente["Telefone"].trim().split(' ').join('');
      switch(teleF.length)
      {
        case  8: teleF = `() ${teleF.substr(0,4)}-${teleF.substr(4,4)}`; break;
        case  9: teleF = `() ${teleF.substr(0,5)}-${teleF.substr(5,4)}`; break;
        case 10: teleF = `(${teleF.substr(0,2)}) ${teleF.substr(2,4)}-${teleF.substr(6,4)}`; break;
        case 11: teleF = `(${teleF.substr(0,2)}) ${teleF.substr(2,5)}-${teleF.substr(7,4)}`; break;
      }
      $("#inputInfPesFone").val(teleF);

      //compõe o endereço
      {
          if (objCliente["Endereco"] != undefined && objCliente["Endereco"].trim() != "") {
            var arraySplit = objCliente["Endereco"].trim().split(',');
            if(arraySplit.length>0)
              $('#inputInfPesEndOriLgr').val(arraySplit[0]);

            if(arraySplit.length>1)
              $('#inputInfPesEndOriNro').val(arraySplit[1]);
          }

          if (objCliente["Bairro"] != undefined && objCliente["Bairro"].trim() != "") {
            $('#inputInfPesEndOriBai').val(objCliente["Bairro"].trim());
          }

          if (objCliente["Complemento"] != undefined && objCliente["Complemento"].trim() != "") {
            $('#inputInfPesEndOriCom').val(objCliente["Complemento"].trim());
          }

          if (objCliente["municipio"] != undefined && objCliente["municipio"].trim() != "") {
            $('#inputInfPesEndOriMun').val(objCliente["municipio"].trim());
          }

          if (objCliente["Estado"] != undefined && objCliente["Estado"].trim() != "") {
            $('#inputInfPesEndOriEst').val(objCliente["Estado"].trim());
          }

          if (objCliente["Cep"] != undefined && objCliente["Cep"].trim() != "") {
              $('#inputInfPesEndOriCep').val(objCliente["Cep"].trim());
          }

          if (objCliente["Codigo municipio"] != undefined && objCliente["Codigo municipio"].trim() != "") {
            $('#hdfInfPesOriIbge').val(objCliente["Codigo municipio"].trim());
          }
      }

      //compõe o endereço de entrega
      {
        if (objCliente["End.Entrega"] != undefined && objCliente["End.Entrega"].trim() != "") {
          var arraySplit = objCliente["Endereco"].trim().split(',');
          if(arraySplit.length>0)
            $('#inputInfPesEndEntLgr').val(arraySplit[0]);

          if(arraySplit.length>1)
            $('#inputInfPesEndEntNro').val(arraySplit[1]);
        }

        if (objCliente["Bairro.Entrega"] != undefined && objCliente["Bairro.Entrega"].trim() != "") {
          $('#inputInfPesEndEntBai').val(objCliente["Bairro.Entrega"].trim());
        }

        if (objCliente["Complemento.Entrega"] != undefined && objCliente["Complemento.Entrega"].trim() != "") {
          $('#inputInfPesEndEntCom').val(objCliente["Complemento.Entrega"].trim());
        }

        if (objCliente["Municipio.Entrega"] != undefined && objCliente["Municipio.Entrega"].trim() != "") {
          $('#inputInfPesEndEntMun').val(objCliente["Municipio.Entrega"].trim());
        }

        if (objCliente["Estado.Entrega"] != undefined && objCliente["Estado.Entrega"].trim() != "") {
          $('#inputInfPesEndEntEst').val(objCliente["Estado.Entrega"].trim());
        }

        if (objCliente["Cep.Entrega"] != undefined && objCliente["Cep.Entrega"].trim() != "") {
          $('#inputInfPesEndEntCep').val(objCliente["Cep.Entrega"].trim());
        }

        if (objCliente["CodMunicipio.Entrega"] != undefined && objCliente["CodMunicipio.Entrega"].trim() != "") {
          $('#hdfInfPesOriIbge').val(objCliente["CodMunicipio.Entrega"].trim());
        }
      }

      var cliMongo:any = {};
      
      cliMongo.response = {};
      cliMongo = lib.getSession("obj_cliente");
      
      if(cliMongo == undefined || cliMongo.response == null)
        cliMongo = await this.clienteSRV.getClienteMongo(objSes.codigo).toPromise();

      if(cliMongo != undefined && cliMongo.response != undefined){
        this.clienteMongo = cliMongo.response;

        if(this.clienteMongo.genero != undefined){
          $('#selectInfPesGenero').val(this.clienteMongo.genero);
        }

        var eleTD = document.getElementById('checkTD') as HTMLInputElement;
        var eleCL = document.getElementById('checkCL') as HTMLInputElement;
        var eleEM = document.getElementById('checkEM') as HTMLInputElement;
        var eleWH = document.getElementById('checkWH') as HTMLInputElement;

        eleTD.checked = true;
        eleCL.checked = true;
        eleEM.checked = true;
        eleWH.checked = true;

        if(this.clienteMongo.notificacoes != undefined){
          if(this.clienteMongo.notificacoes.checkTD != undefined)
            eleTD.checked = this.clienteMongo.notificacoes.checkTD;
           
          if(this.clienteMongo.notificacoes.checkCL != undefined)
            eleCL.checked = this.clienteMongo.notificacoes.checkCL;
          
          if(this.clienteMongo.notificacoes.checkEM != undefined)
            eleEM.checked = this.clienteMongo.notificacoes.checkEM;

          if(this.clienteMongo.notificacoes.checkWH != undefined)
            eleWH.checked = this.clienteMongo.notificacoes.checkWH;
        }

        var ele = document.getElementById('imgPreview');
        if(this.clienteMongo.imgperfil != undefined && this.clienteMongo.imgperfil.trim() != ""){
          ele?.setAttribute('src', this.clienteMongo.imgperfil);
        }
      }
    }

    lib.preloader('hide');
  }

  async validarDados(){

    var temVazio = false;
    var colRequired = $(':input[required]:visible');

    for(var index = 0; index < colRequired.length; index++){
      var ele:any = colRequired[index];
      if(ele.value?.toString() == ''){
        temVazio = true;
        break;
      };
    }

    return !temVazio;
  }

  async Prosseguir(){
    switch(this.box)
    {
      case '1':
        if(await this.validarDados() == false){
          Swal.fire("", this.label.msg_todas_info_vaz, "info")
          return;
        }
  
        var datanasc = $('#inputInfPesNascimento').val()?.toString() || "";
        var ano = datanasc.split('-')[0];
        var mes = datanasc.split('-')[1];
        var dia = datanasc.split('-')[2];
        var nsc = "";

        nsc = nsc + ano.toString();
        if(mes.toString().length==1){nsc = nsc + '0'};
        nsc = nsc + mes.toString();
        if(dia.toString().length==1){nsc = nsc + '0'};
        nsc = nsc + dia.toString();

        var lgrEndOri = "";
        lgrEndOri = lib.replaceSpecialCharacters($('#inputInfPesEndOriLgr').val()?.toString().toUpperCase() || "");
        lgrEndOri = lgrEndOri + ', ' + lib.replaceSpecialCharacters($('#inputInfPesEndOriNro').val()?.toString() || "");

        var lgrEndEnt = "";
        lgrEndEnt = lib.replaceSpecialCharacters($('#inputInfPesEndEntLgr').val()?.toString().toUpperCase() || "");
        lgrEndEnt = lgrEndEnt + ', ' + lib.replaceSpecialCharacters($('#inputInfPesEndEntNro').val()?.toString() || "");

        this.clienteMongo.nome           = $('#inputInfPesNome').val()?.toString().toUpperCase() || "";
        this.clienteMongo.nome_reduzido  = $('#inputInfPesRazao').val()?.toString().toUpperCase() || "";
        this.clienteMongo.data_nasc      = nsc;
        this.clienteMongo.genero         = $('#selectInfPesGenero').val()?.toString() || "0";
        this.clienteMongo.email          = $('#inputInfPesEmail').val()?.toString().toLowerCase() || "";
        this.clienteMongo.telefone       = lib.removeSpaceInText(lib.replaceSpecialCharacters($('#inputInfPesFone').val()?.toString() || ""));
    
        //endereço residencial
        this.clienteMongo.cep            = lib.replaceSpecialCharacters($('#inputInfPesEndOriCep').val()?.toString() || "");
        this.clienteMongo.estado         = lib.replaceSpecialCharacters($('#inputInfPesEndOriEst').val()?.toString().toUpperCase() || "");
        this.clienteMongo.municipio      = lib.replaceSpecialCharacters($('#inputInfPesEndOriMun').val()?.toString().toUpperCase() || "");
        this.clienteMongo.cod_muni       = $('#hdfInfPesOriIbge').val()?.toString() || "";
        this.clienteMongo.endereco       = lgrEndOri;
        this.clienteMongo.bairro         = lib.replaceSpecialCharacters($('#inputInfPesEndOriBai').val()?.toString().toUpperCase() || "");
        this.clienteMongo.complemento    = lib.replaceSpecialCharacters($('#inputInfPesEndOriCom').val()?.toString().toUpperCase() || "");
    
        //endereço de entrega (opçional)
        if(this.consideraEndOri == false){
          this.clienteMongo.cep_entrega    = lib.replaceSpecialCharacters($('#inputInfPesEndEntCep').val()?.toString() || "");
          this.clienteMongo.estado_entrega = lib.replaceSpecialCharacters($('#inputInfPesEndEntEst').val()?.toString().toUpperCase() || "");
          this.clienteMongo.muni_entr      = lib.replaceSpecialCharacters($('#inputInfPesEndEntMun').val()?.toString().toUpperCase() || "");
          this.clienteMongo.cod_muni_entr  = $('#hdfInfPesEntIbge').val()?.toString() || "";
          this.clienteMongo.endereco_entr  = lgrEndEnt;
          this.clienteMongo.bairro_entr    = lib.replaceSpecialCharacters($('#inputInfPesEndEntBai').val()?.toString().toUpperCase() || "");
          this.clienteMongo.compl_entr     = lib.replaceSpecialCharacters($('#inputInfPesEndEntCom').val()?.toString().toUpperCase() || "");
        }else{
          this.clienteMongo.cep_entrega    = this.clienteMongo.cep;
          this.clienteMongo.estado_entrega = this.clienteMongo.estado;
          this.clienteMongo.muni_entr      = this.clienteMongo.municipio;
          this.clienteMongo.cod_muni_entr  = this.clienteMongo.cod_muni;
          this.clienteMongo.endereco_entr  = this.clienteMongo.endereco;
          this.clienteMongo.bairro_entr    = this.clienteMongo.bairro;
          this.clienteMongo.compl_entr     = this.clienteMongo.complemento;
        }
    
        var ele = document.getElementById('imgPreview') as HTMLInputElement;
        if(ele != undefined && ele.src != undefined && ele.src != ""){
          if(this.clienteMongo.imgperfil == undefined)
            this.clienteMongo.imgperfil = "";
    
          this.clienteMongo.imgperfil = ele.src;
        }

        lib.setSession('cliente_edit', this.clienteMongo);

        this.boxOrigem = "";
        this.box = '2';
        break;

      case '2':
        this.box = '3';
        break;

      case '3':
        if(this.boxOrigem == "senha"){
          var senha_edit = lib.getSession('senha_edit');
          await this.setSenha(senha_edit.codigo, senha_edit.senha);
        }else{
          await this.atualizarDadosCliente();
        }
        this.box = '1';
        this.consideraEndOri = false;
        this.buscarDadosCliente();
        break;

    }
  }

  async atualizarDadosCliente(){
    lib.preloader('show');

    var clienteMongoEdit = lib.getSession('cliente_edit');

    //essa api salva primeiro no protheus, depois atualiza o mongo
    let respS = await this.clienteSRV.setDadosNosBancos(clienteMongoEdit).toPromise();
    if(respS != undefined){
      if(respS.response != undefined && respS.response == ""){

        //deu certo: atualiza sessão do cliente
        this.clienteMongo = clienteMongoEdit;
        lib.setSession('obj_cliente', { response: this.clienteMongo });
        Swal.fire("", this.label.msg_InfPes_ok, "success");

      }else if(respS.coderror != undefined && respS.coderror.trim() != ""){

        //deu errado: volta para edição
        Swal.fire("", this.label.msg_InfPes_err, "error");

      }
    }else{

      //deu errado: volta para edição
      Swal.fire("", this.label.msg_InfPes_err, "error");

    }

    lib.preloader('hide');
  }

  async alterarOpcaoNotificacao(tipo:string){
    if(this.clienteMongo.notificacoes == undefined)
      this.clienteMongo.notificacoes = {};

    var ele = document.getElementById('check'+tipo) as HTMLInputElement;
    
    if(this.clienteMongo.notificacoes[ele.id] == undefined)
      this.clienteMongo.notificacoes[ele.id] = ""

    this.clienteMongo.notificacoes[ele.id] = ele.checked;

    let respS = await this.clienteSRV.setDadosMongo(this.clienteMongo).toPromise();
  }

  togglePass(id:string){
    let pass = document.querySelector('#'+id) as HTMLInputElement;
    if (pass.type == 'password') {
      pass.type = 'text';
    }else{
      pass.type = 'password';
    }
  }

  alterarSenha(){
    var senha0 = $('#intpu-senha-atu').val()?.toString() || "";
    var senha1 = $('#intpu-senha-new1').val()?.toString() || "";
    var senha2 = $('#intpu-senha-new2').val()?.toString() || "";

    let objLogin = lib.getSession('login');
    let objSes = lib.getSession('user');

    if(senha0.trim() == ""){
      Swal.fire("", this.label.msg_AltSen_senha0_inf, "info");
      return;
    }

    if(senha0.trim() != objLogin.password){
      Swal.fire("", this.label.msg_AltSen_senha0_dif, "info");
      return;
    }

    if(senha1.trim() == ""){
      Swal.fire("", this.label.msg_AltSen_senha1_inf, "info");
      return;
    }

    if(senha2.trim() == ""){
      Swal.fire("", this.label.msg_AltSen_senha2_inf, "info");
      return;
    }

    lib.setSession('senha_edit', { codigo: objSes.codigo, senha: senha1});
    this.boxOrigem = "senha";
    this.box = '2';

  }

  strongPass(){
    let senha1 = (document.getElementById('intpu-senha-new1') as HTMLInputElement).value as string;
    let senha2 = (document.getElementById('intpu-senha-new2') as HTMLInputElement).value as string;
    this.strongPassmin8 = false;
    this.strongPass1Upper = false;
    this.strongPass1Lower = false;
    this.strongPass1Especial = false;
    this.strongPass1Number = false;
    this.strongPassIgual = false;
    if (senha1.length >= 6) {
      this.strongPassmin8 = true;
    }
    if (senha1.match(/[A-Z]/)) {
      this.strongPass1Upper = true;
    }
    if (senha1.match(/[a-z]/)) {
      this.strongPass1Lower = true;
    }
    if (senha1.match(/[!@#$%*.]/)) {
      this.strongPass1Especial = true;
    }
    if (senha1.match(/[0-9]/)) {
      this.strongPass1Number = true;
    }
    if (senha1 == senha2 && senha1 != "") {
      this.strongPassIgual = true;
    }    
  }

  async setSenha(codigo_cliente:string, senha1:string){
    if (this.strongPassmin8 == false || this.strongPass1Upper == false || this.strongPass1Lower == false || this.strongPass1Especial == false || this.strongPass1Number == false || this.strongPassIgual == false) {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.msgErrorSenhaText,
        icon: 'error',
      })
      return;
    }

    await this.clienteSRV.setNovaSenha({codigo:codigo_cliente, senha:senha1}).subscribe((res:any) => {
      lib.preloader('hide');
      if (res.coderror) {
        Swal.fire( res.coderror[0][this.language as keyof typeof res.coderror], '', 'info');
        return;
      }

      let deviceToken = navigator.userAgent;
      this.clienteSRV.setDeviceToken({codigo:codigo_cliente, token: deviceToken}).subscribe((res:any) => {

        lib.setSession('login', {user: codigo_cliente, password: senha1});

        $('#intpu-senha-atu').val("");
        $('#intpu-senha-new1').val("");
        $('#intpu-senha-new2').val("");

        let pass0 = document.querySelector('#intpu-senha-atu') as HTMLInputElement;
        let pass1 = document.querySelector('#intpu-senha-new1') as HTMLInputElement;
        let pass2 = document.querySelector('#intpu-senha-new2') as HTMLInputElement;

        pass0.type = 'password';
        pass1.type = 'password';
        pass2.type = 'password';

        Swal.fire("", this.label.msgSuccess, "success");
      });
    });

  }

  getBase64() {
    var ele = document.getElementById('file-input') as HTMLInputElement;

    if(ele.files != undefined && ele.files?.length > 0){
      var reader = new FileReader();
      reader.readAsDataURL(ele.files[0]);
      reader.onload = function () {
        var ele = document.getElementById('imgPreview');
        if(reader != undefined && reader.result != undefined){
          ele?.setAttribute('src', reader.result.toString());
        }else{
          ele?.setAttribute('src', '');
        }
      };
      reader.onerror = function (error) {};
    }
  }

  buscarCep(tipo:string){
    var cep = ""

    switch(tipo)
    {
      case "ori": cep = $('#inputInfPesEndOriCep').val()?.toString() || ""; break;
      case "ent": cep = $('#inputInfPesEndEntCep').val()?.toString() || ""; break;
    }

    cep = lib.replaceSpecialCharacters(cep);

    if (cep.length == 8) {
      lib.preloader('show');
      $.getJSON(`https://viacep.com.br/ws/${cep}/json/?callback=?`)
      .done((dados)=>{
        lib.preloader('hide');
        if (dados.erro == undefined) {
          switch(tipo)
          {
            case "ori":
              $('#inputInfPesEndOriLgr').val( (dados.logradouro  != undefined)? dados.logradouro.trim()           : "" );
              $('#inputInfPesEndOriBai').val( (dados.bairro      != undefined)? dados.bairro.trim()               : "" );
              $('#inputInfPesEndOriCom').val( (dados.complemento != undefined)? dados.complemento.trim()          : "" );
              $('#inputInfPesEndOriMun').val( (dados.localidade  != undefined)? dados.localidade.trim()           : "" );
              $('#inputInfPesEndOriEst').val( (dados.uf          != undefined)? dados.uf.trim()                   : "" );
              $('#inputInfPesEndOriCep').val( (dados.cep         != undefined)? dados.cep.trim()                  : "" );
              $('#hdfInfPesOriIbge'    ).val( (dados.ibge        != undefined)? dados.ibge.trim().substring(2, 7) : "" );
              break;

            case "ent":
              $('#inputInfPesEndEntLgr').val( (dados.logradouro  != undefined)? dados.logradouro.trim()           : "" );
              $('#inputInfPesEndEntBai').val( (dados.bairro      != undefined)? dados.bairro.trim()               : "" );
              $('#inputInfPesEndEntCom').val( (dados.complemento != undefined)? dados.complemento.trim()          : "" );
              $('#inputInfPesEndEntMun').val( (dados.localidade  != undefined)? dados.localidade.trim()           : "" );
              $('#inputInfPesEndEntEst').val( (dados.uf          != undefined)? dados.uf.trim()                   : "" );
              $('#inputInfPesEndEntCep').val( (dados.cep         != undefined)? dados.cep.trim()                  : "" );
              $('#hdfInfPesEntIbge'    ).val( (dados.ibge        != undefined)? dados.ibge.trim().substring(2, 7) : "" );
              break;
          }
        }
      }).fail((err)=>{
        lib.preloader('hide');
      })
    }
  }

  async getWayContact(){
    //get way contact from input radio name="wayContact"
    let way = (document.querySelector('input[name="wayContact"]:checked') as HTMLInputElement);//pega o input radio marcado
    if (!way) {//se não tiver nenhum marcado
      Swal.fire({
        title: this.label.msgWarningTitle,
        text: this.label.msgErrorWayContactText,
        icon: 'warning',
      });
      return;
    }
    this.clienteSql.wayContact = way.value;
    lib.preloader('show');
    if (way.value == 'email') {//se o meio de contato for email
      await this.emailService.enviarToken({codigo: this.clienteSql.codigo}).toPromise();
    }
    if (way.value == 'whats') {//se o meio de contato for whatsapp
      await this.whatsappService.enviarToken({number: this.clienteSql.Telefone}).toPromise();
    }
    this.ativarInputsToken();//ativa os inputs de token
    lib.preloader('hide');//esconde o preloader
    this.Prosseguir();
  }

  async canceltWayContact(){
    this.box = '1';
    this.consideraEndOri = false;
    this.buscarDadosCliente();
  }

  async verifyCode(){
    let code1 = (document.getElementById('code-verification-1') as HTMLInputElement).value as string;
    let code2 = (document.getElementById('code-verification-2') as HTMLInputElement).value as string;
    let code3 = (document.getElementById('code-verification-3') as HTMLInputElement).value as string;
    let code4 = (document.getElementById('code-verification-4') as HTMLInputElement).value as string;

    if ( code1 == "" || code2 == "" || code3 == "" || code4 == "") {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.msgErrorNotCodeText,
        icon: 'error',
      })
      return;
    }

    let code = `${code1}${code2}${code3}${code4}`;//junta os 4 inputs de código

    lib.preloader('show');

    if (this.clienteSql.wayContact == 'email') {//se o meio de contato for email
      let verifyCode:any = await this.clienteSRV.verificarTokenEmail({codigo: this.clienteSql.codigo, token: code}).toPromise();
      lib.preloader('hide');

      //console.log(verifyCode);
      if(verifyCode.response == true){//se o código for válido
        this.Prosseguir();
        return;
      }

      if(verifyCode.coderror){
        Swal.fire( verifyCode.coderror[0][this.language as keyof typeof verifyCode.coderror], '', 'info');
        this.canceltWayContact();
        return;
      } 
    }

    if (this.clienteSql.wayContact == 'whats') {//se o meio de contato for whatsapp
      try{
        let verifyCode:any = await this.whatsappService.validarPin({number: this.clienteSql.Telefone, pin: code}).toPromise();
        lib.preloader('hide');

        if (verifyCode.msg == 'PIN code verified') {//se o código for válido
          this.Prosseguir();
          return;
        }
      }catch(err){
        lib.preloader('hide');
        Swal.fire( 'Ops!', 'Código PIN não confere.', 'warning');
        this.canceltWayContact();
        return;
      }
    }
  }

  ativarInputsToken(){
    setTimeout(() => {
    //quando o cliente clicar em um id code-verification-1, ele vai para o próximo
    let code1 = document.getElementById('code-verification-1') as HTMLInputElement;
    let code2 = document.getElementById('code-verification-2') as HTMLInputElement;
    let code3 = document.getElementById('code-verification-3') as HTMLInputElement;
    let code4 = document.getElementById('code-verification-4') as HTMLInputElement;
    code1.addEventListener('keyup', (event) => {//ao digitar o 1º digito vai para o próximo
      if (code1.value.length == 1 && this.tokenInputFull == false) {
        code2?.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
      if (code1.value.length > 1) {//se o input tiver mais de 1 digito
        this.tokenInputFull = true;
        let aux = code1.value.split('');//separa os digitos
        code1.value = aux[0];//atribui o 1º digito ao input
        code2.value = aux[1];//atribui o 2º digito ao input
        code3.value = aux[2];//atribui o 3º digito ao input
        code4.value = aux[3];//atribui o 4º digito ao input
        this.verifyCode();
      }
    });
    code2.addEventListener('keyup', (event) => {//ao digitar o 2º digito vai para o próximo
      if (code2.value.length == 1 && this.tokenInputFull == false) {
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
      if (event.key == "Backspace") {//se apertar backspace
        this.tokenInputFull = false;
        code1.focus();
        code1.setSelectionRange(0, code1.value.length);
      }
    });
    code3.addEventListener('keyup', (event) => {
      if (code3.value.length == 1 && this.tokenInputFull == false) {
        code4.focus();
        code4.setSelectionRange(0, code4.value.length);
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code2.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
    });
    //ao digitar o 4º digito e apertar Enter, verifica o código
    code4.addEventListener('keyup', (event) => {
      if (event.key == "Enter") {
        this.verifyCode();
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
    });
    }, 1000);
  }

  changeCheckbox(chk:string){
    var ele = document.getElementById(chk) as HTMLInputElement;
    ele.checked = !ele.checked;
    this.changeConsidera();
  }

  changeConsidera(){
    var ele = document.getElementById("chkConsideraEndOri") as HTMLInputElement;
    this.consideraEndOri = ele.checked;
  }

  setTimeline(){
    let objSes = lib.getSession('user');

    var objTl:objTimeline = 
    {
      cod: objSes.codigo,
      pag: "perfil",
      sts: true,
      msg: ""
    };

    this.tlSRV.setTimeline(objTl).toPromise();
  }

  getTimeline(){
    lib.preloader('show');

    setTimeout(
      async ()=>{
        this.colTimeLine = [];
        var colTlprevia:any = [];

        let objSes = lib.getSession('user');
        var respTL = await this.tlSRV.getTimeline(objSes.codigo).toPromise();

        if(this.colTimeLineTotal.length == 0){
          if(respTL != undefined && respTL.response != undefined && respTL.response.length>0){
            var colTL = JSON.parse(JSON.stringify(respTL.response));
            
            colTL.forEach((itTL:any) => {
              var _ord = "";
              var _dat = "";
              var _pag = "";
              var _sts = "";
              var _des = "";
              var _msg = "";
  
              _ord = itTL.dat + itTL.hor;
              _dat = `${itTL.dat.substr(6, 2)}/${itTL.dat.substr(4, 2)}/${itTL.dat.substr(0, 4)} ` +
                     `${itTL.hor.substr(0, 2)}:${itTL.hor.substr(2, 2)}:${itTL.hor.substr(4, 2)}`
  
              _pag = itTL.pag
              switch(itTL.pag)
              {
                case "login"    : _pag = "login"                  ; break;
                case "perfil"   : _pag = "perfil do cliente"      ; break;
                case "compras"  : _pag = "compras realizadas"     ; break;
                case "renegocia": _pag = "renegociação de títulos"; break;
              }
  
              _sts = itTL.sts;
              if(itTL.sts == true){
                _des = "com sucesso";
              }else{
                _des = "sem sucesso";
                if(itTL.msg != ""){
                  var ling = this.language;
                  _msg = itTL.msg[ling];
                }
              }
              
              colTlprevia.push({ord:_ord, dat:_dat, pag:_pag, sts:_sts, des:_des, msg:_msg});
            });
          }
        }else{
          colTlprevia = this.colTimeLineTotal;
        }

        if(colTlprevia.length>0){
          colTlprevia.sort(function(a:any, b:any) {
              return b.ord - a.ord;
          });

          this.colTimeLineTotal = colTlprevia;
          this.colTimeLine = colTlprevia.slice(0, this.timelineRows);
        }

        lib.preloader('hide');
      }, 3000)
  }

  buscarMais(){
    this.timelineRows = this.timelineRows + 10;
    this.getTimeline();
  }
}