import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

export interface interVerificarTokenEmail{
    codigo: string,
    token: string
}
export interface interSetSenhaByTokenEmail{
    codigo: string,
    senha: string
}

export interface interDeviceToken{
    codigo: string,
    token: string
}

@Injectable({providedIn: 'root'})

export class ClienteService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    setCliente(objCliente:any):Observable<objRetorno>{
        return this.http.post("cadastrarCliente", objCliente);
    }

    setSenha(login:string, senha:string):Observable<objRetorno>{

        var objBody =
        {
            "cod" : login,
            "psw" : senha
        }

        return this.http.post("cadastrarSenha", objBody);
    }

    getOptIn(cod:string):Observable<objRetorno>{
        return this.http.get("buscaOptIn/" + cod);
    }

    setOptIn(cod:string, aceite:boolean, tipo:string):Observable<objRetorno>{
        let date = new Date().toISOString().slice(0, 19).replace('T', ' ');
        var objBody =
        {
            "cod_cliente" : cod,     //codigo do cliente
            "dat_optin"   : date,    //data
            "aceite"      : aceite,  //aceite ou não
            "tipo"        : tipo      //tipo: 1 - aceite para receber mensagens por mídias sociais
        }

        return this.http.post("realizaOptIn", objBody);
    }
    
    getClienteByCQC(doc:string):Observable<objRetorno>{
        return this.http.post("get-cliente-by-doc", { "doc" : doc });
    }

    verificarTokenEmail(obj:interVerificarTokenEmail):Observable<objRetorno>{
        return this.http.post("verificar-token-email", obj);
    }

    setNovaSenha(obj:interSetSenhaByTokenEmail):Observable<objRetorno>{
        return this.http.post("set-nova-senha", obj);
    }

    setDeviceToken(obj:interDeviceToken):Observable<objRetorno>{
        //console.log(obj);
        
        return this.http.post("set-device-token", obj)
    }

    getClienteFullByCod(cliente:any):Observable<objRetorno>{
        return this.http.post("WsConsultCli", cliente);
    }

    getClienteMongo(cliente:any):Observable<objRetorno>{
        return this.http.post("get-clientemongo-by-doc", {codigo:cliente});
    }

    setDadosNosBancos(cliente:any):Observable<objRetorno>{
        return this.http.post("set-dados-bancos", cliente);
    }

    setDadosMongo(cliente:any):Observable<objRetorno>{
        return this.http.post("set-dados-mongo", cliente);
    }

}