import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';
import { objTimeline } from '../../entities/timeline.entity';

@Injectable({providedIn: 'root'})

export class TimelineService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    setTimeline(objTl:objTimeline):Observable<objRetorno>{
        return this.http.post("setTimeline", objTl);
    }

    getTimeline(codigo:string):Observable<objRetorno>{
        return this.http.get("getTimeline/" + codigo);
    }
}