import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

export interface interObjSendPin{
  number: string
}
export interface interObjValidarPin{
  number: string,
  pin: string
}
@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor (private http: HttpService<objRetorno>){}

  enviarToken(obj:interObjSendPin):Observable<objRetorno>{
      return this.http.post("sendpin", obj );
  }

  validarPin(obj:interObjValidarPin):Observable<objRetorno>{
    return this.http.post("verifypin", obj );
  }
}
