import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class DevolucaotrocaService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    setDevolucaotroca(objDT:any):Observable<objRetorno>{
        return this.http.post("setDevolucaotroca", objDT);
    }

    getDevolucaotroca(cliente:string):Observable<objRetorno>{
        return this.http.get("getDevolucaotroca/" + cliente);
    }
}