import { Component, OnInit } from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { DocumentosService } from 'src/app/shared/services/documentos/documentos.service';
import { TitulosService } from 'src/app/shared/services/titulos/titulos.service';
import { RenegociaService } from 'src/app/shared/services/renegocia/renegocia.service';
import { PagamentosService } from 'src/app/shared/services/pagamentos/pagamentos.service';
import { TimelineService } from 'src/app/shared/services/timeline/timeline.service';
import { objTimeline } from 'src/app/shared/entities/timeline.entity';
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import * as translations from './renegocia.translate.json';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-renegocia',
    templateUrl: './renegocia.component.html',
    styleUrls: ['./renegocia.component.css'],
})

export class RenegociaComponent implements OnInit{

    label: any = {};
    language: any = lib.getSession('language');
    translate: any = translations;
    colNotasGeral: any = [];
    colNotas: any = [];
    colMinhasReneg: any = [];
    colMinhasRenegT: any = [];
    colTitulosSel:any = [];
    minhasRenegociacoes:boolean = false;
    displaySucessoVlrDat:string = "";
    consultarPix:boolean = false;

    mostrarVazio:boolean = false;
    pastaImagens = lib.getSession('pastaImagens');

    datVencimento:string = "";
    nroParcela:string = "1";
    qtdTitRenegAberto:number = 0;

    displayModalAceiteReneg:string = "none";
    colTitulosPix:any = [];

    constructor(private documentosSRV: DocumentosService,
                private titulosSRV: TitulosService,
                private renegociaSRV: RenegociaService,
                private pgtosSRV: PagamentosService,
                private tlSRV: TimelineService,
                private clienteSRV: ClienteService)
    {
        lib.validarUsuarioLogado();
        this.label = lib.getTranslate(this.translate);
    }

    async ngOnInit() {

        //let objSes = lib.getSession('user');
        //objSes.codigo = 'VDFEED'
        //lib.setSession('user', objSes);

        this.buscarTitulosVencidos();
        this.setTimeline();
    }

    async buscarTitulosVencidos(){

        let objSes = lib.getSession('user');
        let cod_cliente = objSes.codigo;

        lib.preloader('show');
        this.mostrarVazio = false;

        try{
            let res = await this.titulosSRV.getRelacaoTitulos(cod_cliente).toPromise() as any;

            if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado") {

                //quando não tem nada... inclui espaço
                this.mostrarVazio = true;

                return lib.preloader('hide');
            } else {

                this.colNotasGeral = [];
                this.colNotas = [];

                let date_now = new Date();
                let month    = '' + (date_now.getMonth() + 1);
                let day      = '' + date_now.getDate();
                let year     = date_now.getFullYear();
    
                if (month.length < 2) {month = '0' + month;};
                if (day.length < 2) {day = '0' + day;};

                let date_now_str = year + month + day;

                this.colNotasGeral = Object.values(res.response);
                res.response.T1 = Object.values(res.response);
                res.response.TF = res.response.T1.filter((w:any) => w.E1_STATUS == "A" &&
                                                                    w.E1_VENCTO < date_now_str &&
                                                                    w.E1_TIPO != "NCC" &&
                                                                    w.E1_TIPO != "RA"  &&
                                                                    w.E1_PREFIXO != "RNF" &&
                                                                    w.E1_PREFIXO != "NUB" 
                                                                    ) as any;

                this.qtdTitRenegAberto = res.response.T1.filter((w:any) => w.E1_STATUS == "A" &&
                                                                           w.E1_VENCTO < date_now_str &&
                                                                           w.E1_TIPO != "NCC" &&
                                                                           w.E1_TIPO != "RA"  &&
                                                                           (w.E1_PREFIXO == "RNF" || w.E1_PREFIXO == "NUB" )).length;

                //Separa as nfs
                var lstNFS = [...new Set(res.response.TF.map((item:any) => item.E1_NUM))];

                lstNFS.forEach((itNF:any) => {
                    var colTitulos = res.response.TF.filter((w:any) => w.E1_NUM == itNF);
                    var filial     = colTitulos[0].E1_FILIAL;
                    var numero     = colTitulos[0].E1_NUM;
                    var emissao    = colTitulos[0].E1_EMISSAO;
                    var loja       = colTitulos[0].E1_LOJA;
                    var serie      = colTitulos[0].E1_PREFIXO;
                    var baixardoc  = true;

                    if(colTitulos[0].E1_PREFIXO == "RF" || colTitulos[0].E1_PREFIXO == "RNF"){
                        baixardoc = false;
                    }

                    colTitulos.forEach((itTT:any) => {
                        if(itTT.E1_PARCELA.trim() == ""){
                            itTT.E1_PARCELA = "001";
                            itTT.E1_PARCELA_VAZIA = "true";
                        }else{
                            itTT.E1_PARCELA_VAZIA = "false";
                        }

                        var dt = itTT.E1_VENCTO;
                        itTT.E1_VENCTO = `${dt.substr(6, 2)}/${dt.substr(4, 2)}/${dt.substr(0, 4)}`;

                        itTT.E1_SALDO_CONVERTIDO       = lib.convertMoney(parseFloat(itTT.E1_SALDO));
                        itTT.TX_PERMANECIA_CONVERTIDO  = lib.convertMoney(parseFloat(itTT.TX_PERMANECIA));
                        itTT.E1_SALDO_FINAL_CONVERTIDO = lib.convertMoney(parseFloat(itTT.E1_SALDO));

                        itTT.PERMITEONLINE = true;
                        if(parseFloat(itTT.E1_SALDO) < 300 ||
                           parseFloat(itTT.E1_SALDO) > 99999.99){
                            itTT.PERMITEONLINE = false;
                        }

                        if(this.qtdTitRenegAberto>0){
                            itTT.PERMITEONLINE = false;
                        }
                    });

                    colTitulos.sort(function(a:any, b:any) {
                        return a.E1_PARCELA - b.E1_PARCELA;
                    });

                    this.colNotas.push
                    ({
                        E1_FILIAL  : filial,
                        E1_NUM     : numero,
                        E1_EMISSAO : emissao,
                        E1_CLIENTE : cod_cliente,
                        E1_LOJA    : loja,
                        E1_SERIE   : serie,
                        baixardoc  : baixardoc,
                        TITULOS    : colTitulos
                    });
                })

                this.colNotas.sort(function(a:any, b:any) {
                    return b.E1_EMISSAO - a.E1_EMISSAO;
                });

                if(this.colNotas.length==0){
                    this.mostrarVazio = true;
                }
            }

        } catch (err) {
            console.log(err);
        }

        lib.preloader('hide');
    }

    checkBoxTodos(event:any, nf:string){
        var eleChkTodos = document.getElementById('chkt_'+nf) as HTMLInputElement;
        var colCheckBox = document.getElementsByName('chkp_'+nf)

        colCheckBox.forEach((itChk:any) => {
            var eleChkParcela = document.getElementById(itChk.id) as HTMLInputElement;
            eleChkParcela.checked = eleChkTodos.checked;
        })

        this.somaParcelas();
        this.recalculaTitulos();
    }

    checkBoxParcela(event:any, nf:string, pc:string){
        var eleChkTodos   = document.getElementById('chkt_'+nf) as HTMLInputElement;
        var colCheckBox   = document.getElementsByName('chkp_'+nf);
        var nroChk        = 0;
        var nroChkChecked = 0;

        colCheckBox.forEach((itChkp:any) => {
            nroChk++;
            if(itChkp.checked){nroChkChecked++;};
        });

        if(nroChkChecked == nroChk){
            eleChkTodos.checked = true;
        }else{
            eleChkTodos.checked = false;
        }

        this.somaParcelas();
        this.recalculaTitulos();
    }

    somaParcelas(){
        var colCheckBox    = document.getElementsByTagName("input");
        var qtdParcelasSel = 0;
        var vlrParcelasSel = 0;

        for(var index = 0; index < colCheckBox.length; index++){
            if(colCheckBox[index].id.indexOf('chkp_')>-1){
                if(colCheckBox[index].checked){
                    var numeroParcela = colCheckBox[index].id.replace(`chkp_`, '');
                    var eleHdfValor = document.getElementById(`hdf_${numeroParcela}_vlr`) as HTMLInputElement;
                    
                    vlrParcelasSel = vlrParcelasSel + parseFloat(eleHdfValor.value);
                    qtdParcelasSel++;
                }
            }
        }

        var eleDivTotal  = document.getElementById(`divTotal`)               as HTMLInputElement;
        var eleQtdTotal  = document.getElementById(`lblQtdTotal`)            as HTMLInputElement;
        var eleVlrTotal  = document.getElementById(`lblVlrTotal`)            as HTMLInputElement;
        var eleVlrTotalR = document.getElementById(`lblVlrTotalRecalculado`) as HTMLInputElement; 
        
        var msgTotal    = this.label.msg_totalprcsel;
        
        if(qtdParcelasSel > 0){
            eleQtdTotal.value  = msgTotal.replace('<qtdParcelasSel>', qtdParcelasSel); // `Total da ${qtdParcelasSel} parcela(s) selecionada(s)`;
            eleVlrTotal.value  = `R$ ${lib.convertMoney(vlrParcelasSel)}`;
            eleVlrTotalR.value = `R$ ${lib.convertMoney(vlrParcelasSel)}`;
            eleDivTotal.style.display = "block";
        }else{
            eleQtdTotal.value = "";
            eleVlrTotal.value = "";
            eleDivTotal.style.display = "none";
        }
    }

    async concluirRenegociacao(){
        const respDoc = await this.documentosSRV.getConfissaoDivida().toPromise() as any;

        if(respDoc != undefined && respDoc.response != undefined){

            //identifica os dados do cliente
            let objSes = lib.getSession('user');
            var cliMongo:any = {};
        
            cliMongo.response = {};
            cliMongo = lib.getSession("obj_cliente")
            
            if(cliMongo == undefined || cliMongo.response == null){
                cliMongo = await this.clienteSRV.getClienteMongo(objSes.codigo).toPromise();
                lib.setSession("obj_cliente", cliMongo);
            }

            var strhtml = respDoc.response.toString();
            strhtml = strhtml.replace('&lt;nome&gt;', cliMongo.response.nome);
            strhtml = strhtml.replace('&lt;documento&gt;', cliMongo.response.CGC);
            strhtml = strhtml.replace('&lt;endereco&gt;', cliMongo.response.endereco);
            strhtml = strhtml.replace('&lt;cidade&gt;', cliMongo.response.municipio + ' - ' + cliMongo.response.estado);
            strhtml = strhtml.replace('&lt;cep&gt;', cliMongo.response.cep);

            var dat_sel = $('#inputDateVencimento').val() || "";
            var par_sel = $('#selectNumeroParcelas').val() || "";
            var str_vlr_reneg = $('#lblVlrTotalRecalculado').val() || "";
            var vlr_reneg = parseFloat( str_vlr_reneg.toString().replace( 'R$', '' ).replace( '.', '').replace( ',', '.') );
            var vlrextenso = this.valorPorExtenso(vlr_reneg);

            strhtml = strhtml.replace('&lt;valor&gt;', lib.convertMoney(vlr_reneg));
            strhtml = strhtml.replace('&lt;valorextenso&gt;', vlrextenso);

            strhtml = strhtml.replace('&lt;valor&gt;', lib.convertMoney(vlr_reneg));
            strhtml = strhtml.replace('&lt;valorextenso&gt;', vlrextenso);

            strhtml = strhtml.replace('&lt;valor&gt;', lib.convertMoney(vlr_reneg));
            strhtml = strhtml.replace('&lt;valorextenso&gt;', vlrextenso);

            //cria informação dos titulos vencidos
            {
                var colCheckBox = document.getElementsByTagName("input");
                var dL      = new Date(),
                    yearL   = '' + (dL.getFullYear()),
                    monthL  = '' + (dL.getMonth() + 1),
                    dayL    = '' + (dL.getDate())
    
                if (monthL.length < 2) {monthL = '0' + monthL;};
                if (dayL.length < 2) {dayL = '0' + dayL;};

                if(dat_sel == ""){
                    dat_sel = `${yearL}-${monthL}-${dayL}`;
                }
    
                var ano = dat_sel.toString().split('-')[0];
                var mes = dat_sel.toString().split('-')[1];
                var dia = dat_sel.toString().split('-')[2];
    
                var dat_ven  = new Date( parseFloat(ano) , parseFloat(mes)-1 , parseFloat(dia) );
                var dat_atu  = new Date( parseFloat(yearL) , parseFloat(monthL)-1 , parseFloat(dayL) );
                var dat_aux2 = new Date( parseFloat(yearL) , parseFloat(monthL)-1 , parseFloat(dayL) );
                var dat_free = new Date(dat_aux2.setDate(dat_aux2.getDate() + 5));
    
                var htmlTitulos = ""
                for(var index = 0; index < colCheckBox.length; index++){
                    if(colCheckBox[index].id.indexOf('chkp_')>-1){
                        var chave = colCheckBox[index].id.replace('chkp_', '');
                        var hdf_tit = document.getElementById( 'hdf_'+chave+'_tit' ) as HTMLInputElement;
                        var hdf_tip = document.getElementById( 'hdf_'+chave+'_tip' ) as HTMLInputElement;
                        var hdf_vlr = document.getElementById( 'hdf_'+chave+'_vlr' ) as HTMLInputElement;
                        var hdf_vct = document.getElementById( 'hdf_'+chave+'_vct' ) as HTMLInputElement;
                        var hdf_vaz = document.getElementById( 'hdf_'+chave+'_vaz' ) as HTMLInputElement;
                        var hdf_ems = document.getElementById( 'hdf_'+chave+'_ems' ) as HTMLInputElement;
    
                        var parcela = "";
                        if(hdf_vaz.value == "false"){
                            parcela = chave.split('_')[1];
                        }
    
                        var objNf = this.colNotas.filter((w:any) => w.E1_NUM == hdf_tit.value);
                        var objTi = objNf[0].TITULOS.filter((w:any) => w.E1_PARCELA == parcela);
    
                        if(colCheckBox[index].checked){
                            var recalcular = true;
    
                            if(par_sel != "1"){
                                recalcular = true;
                            }else{
                                if(dat_ven>dat_free){
                                    recalcular = true;
                                }else{
                                    recalcular = false;
                                }
                            }
    
                            var vlr_recalculado = "";
                            var yd1 = objTi[0].E1_VENCTO.toString().split('/')[2]
                            var md1 = objTi[0].E1_VENCTO.toString().split('/')[1]
                            var dd1 = objTi[0].E1_VENCTO.toString().split('/')[0]
                            var d1 = new Date( parseFloat(yd1), parseFloat(md1)-1, parseFloat(dd1) )
    
                            var diasVencido = Math.floor
                            (
                                (
                                    Date.UTC(dat_atu.getFullYear(), dat_atu.getMonth(), dat_atu.getDate()) -
                                    Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
                                ) / (1000 * 60 * 60 * 24)
                            );
    
                            if(recalcular){
                                //calcula juros
                                var valorJuros = parseFloat(objTi[0].TX_PERMANECIA) * diasVencido;
                                valorJuros = Math.round((valorJuros) * 100) / 100;
        
                                //calcula valor
                                var valorFinal = parseFloat(objTi[0].E1_VALOR) + valorJuros;
                                valorFinal = Math.round((valorFinal) * 100) / 100;
                                vlr_recalculado = lib.convertMoney(valorFinal);
                            }else{
                                vlr_recalculado = lib.convertMoney(parseFloat(objTi[0].E1_VALOR));
                            }
    
                            var htmlTitulos = htmlTitulos + `
                            <tr>
                                <td style="border: solid 1px black;" >${hdf_tit.value}</td>
                                <td style="border: solid 1px black;" >${parcela}</td>
                                <td style="border: solid 1px black;" >${hdf_tip.value}</td>
                                <td style="border: solid 1px black;" >${hdf_ems.value.substr(6, 2)}/${hdf_ems.value.substr(4, 2)}/${hdf_ems.value.substr(0, 4)}</td>
                                <td style="border: solid 1px black;" >${hdf_vct.value}</td>
                                <td style="border: solid 1px black;" >${lib.convertMoney(parseFloat(hdf_vlr.value))}</td>
                                <td style="border: solid 1px black;" >${vlr_recalculado}</td>
                                <td style="border: solid 1px black;" >${diasVencido}</td>
                            </tr>`;
                        }
                    }
                }
    
                var titulos = `
                <table style="font-size: 12px;">
                    <thead>
                        <tr>
                            <td style="border: solid 2px black; font-weight: 600;" >N.º Título</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Parcela</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Tipo</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Emissão</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Vencimento</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Vlr. Original</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Vlr. Receber</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Dias Atraso</td>
                        </tr>
                    </thead>
                    <tbody>
                        ${htmlTitulos}
                    </tbody>
                </table>`;
                strhtml = strhtml.replace('&lt;titulos&gt;', titulos);
            }

            switch(par_sel)
            {
                case "1": strhtml = strhtml.replace('&lt;nroparcelas&gt;', "1 (uma) parcela mensal"); break;
                case "2": strhtml = strhtml.replace('&lt;nroparcelas&gt;', "2 (duas) parcelas mensais"); break;
                case "3": strhtml = strhtml.replace('&lt;nroparcelas&gt;', "3 (três) parcelas mensais"); break;
            }

            //cria informação de pagamentos
            {
                var htmlboletos = "";
                var str_dat_reneg = dat_sel;
                var str_par_reneg = par_sel;

                var ano = str_dat_reneg.toString().split('-')[0]
                var mes = str_dat_reneg.toString().split('-')[1]
                var dia = str_dat_reneg.toString().split('-')[2]

                var dat_reneg = new Date( parseFloat(ano), parseFloat(mes)-1, parseFloat(dia) );
                var par_reneg = parseFloat( str_par_reneg.toString() );
                var valorAcumulado = 0;

                for(var indexG = 0; indexG < par_reneg; indexG++){
                    var valorParcela = 0;

                    if(indexG>0){
                        //calcula data das proximas parcelas
                        dat_reneg = new Date(dat_reneg.setDate(dat_reneg.getDate() + 30));
                    }

                    var y_dat_reneg = '' + dat_reneg.getFullYear();
                    var m_dat_reneg = '' + (dat_reneg.getMonth() + 1);
                    var d_dat_reneg = '' + dat_reneg.getDate();

                    if (m_dat_reneg.length < 2) {m_dat_reneg = '0' + m_dat_reneg;};
                    if (d_dat_reneg.length < 2) {d_dat_reneg = '0' + d_dat_reneg;};

                    var valorParcela = Math.round(( vlr_reneg / par_reneg ) * 100) / 100; 
                    
                    if((indexG+1)==par_reneg){
                        //calcula valor da ultima parcela
                        valorParcela = Math.round(( vlr_reneg - valorAcumulado ) * 100) / 100;
                    }else{
                        valorAcumulado = valorAcumulado + valorParcela;
                    }

                    var htmlboletos = htmlboletos + `
                    <tr>
                        <td style="border: solid 1px black;" >00${(indexG+1)}</td>
                        <td style="border: solid 1px black;" >${d_dat_reneg}/${m_dat_reneg}/${y_dat_reneg}</td>
                        <td style="border: solid 1px black;" >R$ ${lib.convertMoney(parseFloat(valorParcela.toString()))}</td>
                    </tr>`;
                }

                var boletos = `
                <table style="font-size: 12px;">
                    <thead>
                        <tr>
                            <td style="border: solid 2px black; font-weight: 600;" >Parcela N.º</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Data Vencimento</td>
                            <td style="border: solid 2px black; font-weight: 600;" >Valor</td>
                        </tr>
                    </thead>
                    <tbody>
                        ${htmlboletos}
                        <tr>
                            <td style="border: solid 1px black;" >&nbsp;</td>
                            <td style="border: solid 1px black;" >&nbsp;</td>
                            <td style="border: solid 1px black;" >R$ ${lib.convertMoney(parseFloat(vlr_reneg.toString()))}</td>
                        </tr>
                    </tbody>
                </table>`;
                strhtml = strhtml.replace('&lt;boletos&gt;', boletos);
            }

            var dataassinatura = "";
            dataassinatura = dataassinatura + dayL;
            switch(monthL)
            {
                case "01": dataassinatura = dataassinatura + " de Janeiro de "; break;
                case "01": dataassinatura = dataassinatura + " de Fevereiro de "; break;
                case "01": dataassinatura = dataassinatura + " de Março de "; break;
                case "01": dataassinatura = dataassinatura + " de Abril de "; break;
                case "01": dataassinatura = dataassinatura + " de Maio de "; break;
                case "01": dataassinatura = dataassinatura + " de Junho de "; break;
                case "01": dataassinatura = dataassinatura + " de Julho de "; break;
                case "01": dataassinatura = dataassinatura + " de Agosto de "; break;
                case "01": dataassinatura = dataassinatura + " de Setembro de "; break;
                case "01": dataassinatura = dataassinatura + " de Outubro de "; break;
                case "01": dataassinatura = dataassinatura + " de Novembro de "; break;
                case "01": dataassinatura = dataassinatura + " de Dezembro de "; break;
            }
            dataassinatura = dataassinatura + yearL;
            strhtml = strhtml.replace('&lt;dataassinatura&gt;', dataassinatura);
            
            $('#divAceito').html(strhtml);
            this.displayModalAceiteReneg = "block";
            setTimeout(()=>{this.aplicaCssTermo();}, 500);
        }
    }

    cancelar(){
        this.displayModalAceiteReneg = "none";
    }

    async renegociar(){
        this.displayModalAceiteReneg = "none";
        lib.preloader('show');

        let objSes = lib.getSession('user');
        let cod_cliente = objSes.codigo;
        let loj_cliente = objSes.loja;

        var colCheckBox = document.getElementsByTagName("input");
        var titulos:any = [];
        var renegociacoes:any = [];
        var vlrTotal = 0;

        let date_now = new Date();
        let month    = '' + (date_now.getMonth() + 1);
        let day      = '' + date_now.getDate();
        let year     = '' + date_now.getFullYear();
        let hour     = '' + date_now.getHours();
        let minute   = '' + date_now.getMinutes();
        let second   = '' + date_now.getSeconds();

        if (month.length  < 2) {month  = '0' + month;};
        if (day.length    < 2) {day    = '0' + day;};
        if (hour.length   < 2) {hour   = '0' + hour;};
        if (minute.length < 2) {minute = '0' + minute;};
        if (second.length < 2) {second = '0' + second;};

        var idReneg = year + month + day + hour + minute + second;
        var dtReneg = year + month + day;
        var hrReneg = hour + minute + second;
        
        var fil_inclusoes = "";
        
        //cria array de titulos para baixa
        for(var index = 0; index < colCheckBox.length; index++){
            if(colCheckBox[index].id.indexOf('chkp_')>-1){
                if(colCheckBox[index].checked){
                    var chave = colCheckBox[index].id.replace('chkp_', '');
                    var hdf_tit = document.getElementById( 'hdf_'+chave+'_tit' ) as HTMLInputElement;
                    var hdf_fil = document.getElementById( 'hdf_'+chave+'_fil' ) as HTMLInputElement;
                    var hdf_pfx = document.getElementById( 'hdf_'+chave+'_pfx' ) as HTMLInputElement;
                    var hdf_tip = document.getElementById( 'hdf_'+chave+'_tip' ) as HTMLInputElement;
                    var hdf_vlr = document.getElementById( 'hdf_'+chave+'_vlr' ) as HTMLInputElement;
                    var hdf_txp = document.getElementById( 'hdf_'+chave+'_txp' ) as HTMLInputElement;
                    var hdf_vct = document.getElementById( 'hdf_'+chave+'_vct' ) as HTMLInputElement;
                    var hdf_vaz = document.getElementById( 'hdf_'+chave+'_vaz' ) as HTMLInputElement;
                    var hdf_ems = document.getElementById( 'hdf_'+chave+'_ems' ) as HTMLInputElement;

                    if(fil_inclusoes == "")
                        fil_inclusoes = "0201"; // hdf_fil.value.toString();

                    var parcela = "";
                    if(hdf_vaz.value == "false"){
                        parcela = chave.split('_')[1];
                    }

                    titulos.push(
                        {
                            "codigo"        : cod_cliente,
                            "id_reneg"      : idReneg,
                            "tipo_reneg"    : 0,
                            "data_reneg"    : dtReneg,
                            "hora_reneg"    : hrReneg,
                            "filial"        : hdf_fil.value,
                            "numerotitulo"  : hdf_tit.value,
                            "prefixo"       : hdf_pfx.value,
                            "tipo"          : hdf_tip.value,
                            "parcela"       : parcela,
                            "tx_permanencia": parseFloat(hdf_txp.value),
                            "valor"         : parseFloat(hdf_vlr.value),
                            "vencimento"    : `${hdf_vct.value.split('/')[2]}${hdf_vct.value.split('/')[1]}${hdf_vct.value.split('/')[0]}`,
                            "valor_final"   : parseFloat(hdf_vlr.value) + parseFloat(hdf_txp.value),
                            "emissao"       : hdf_ems.value
                        }
                    )

                    vlrTotal = vlrTotal + parseFloat(hdf_vlr.value) + parseFloat(hdf_txp.value);
                }
            }
        }

        //cria array de titulos para inclusão
        var str_dat_reneg = $('#inputDateVencimento').val() || "";    //data 
        var str_par_reneg = $('#selectNumeroParcelas').val() || "";   //parcela 
        var str_vlr_reneg = $('#lblVlrTotalRecalculado').val() || ""; //valor 

        var ano = str_dat_reneg.toString().split('-')[0]
        var mes = str_dat_reneg.toString().split('-')[1]
        var dia = str_dat_reneg.toString().split('-')[2]

        var dat_reneg = new Date( parseFloat(ano), parseFloat(mes)-1, parseFloat(dia) );
        var par_reneg = parseFloat( str_par_reneg.toString() );
        var vlr_reneg = parseFloat( str_vlr_reneg.toString().replace( 'R$', '' ).replace( '.', '').replace( ',', '.') );
        var valorAcumulado = 0;

        for(var indexG = 0; indexG < par_reneg; indexG++){
            var valorParcela = 0;

            if(indexG>0){
                //calcula data das proximas parcelas
                dat_reneg = new Date(dat_reneg.setDate(dat_reneg.getDate() + 30));
            }

            var y_dat_reneg = '' + dat_reneg.getFullYear();
            var m_dat_reneg = '' + (dat_reneg.getMonth() + 1);
            var d_dat_reneg = '' + dat_reneg.getDate();

            if (m_dat_reneg.length < 2) {m_dat_reneg = '0' + m_dat_reneg;};
            if (d_dat_reneg.length < 2) {d_dat_reneg = '0' + d_dat_reneg;};

            var valorParcela = Math.round(( vlr_reneg / par_reneg ) * 100) / 100; 
            
            if((indexG+1)==par_reneg){
                //calcula valor da ultima parcela
                valorParcela = Math.round(( vlr_reneg - valorAcumulado ) * 100) / 100;
            }else{
                valorAcumulado = valorAcumulado + valorParcela;
            }

            renegociacoes.push(
                {
                    "codigo"  : cod_cliente,
                    "loja"    : loj_cliente,
                    "filial"  : fil_inclusoes,
                    "parcela" : `00${(indexG+1)}`,
                    "data"    : `${y_dat_reneg}${m_dat_reneg}${d_dat_reneg}`,
                    "valor"   : valorParcela
                }
            )

        }

        //ordernar titulos por emissão do mais velho para o mais novo
        titulos.sort(function(a:any, b:any) {
            return a.vencimento - b.vencimento;
        });

        let res = await this.renegociaSRV.setConcluirRenegociacao(titulos, renegociacoes).toPromise() as any;
        
        if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado"){
            Swal.fire
            (
                this.label.msg_titulo_algo,
                this.label.msg_descri_algo + "<br><br>" + res.coderror.pt,
                "info"
            );

            lib.preloader('hide');
        }else{

            if(res.response.code != undefined && res.response.code == 500){
                Swal.fire
                (
                    this.label.msg_titulo_algo,
                    this.label.msg_descri_algo,
                    "info"
                );
    
                lib.preloader('hide');
                return;
            }

            if(res.response.status != undefined && res.response.status.trim() != "ok"){
                Swal.fire
                (
                    this.label.msg_titulo_algo,
                    this.label.msg_descri_algo,
                    "info"
                );
    
                lib.preloader('hide');
                return; 
            }

            var continuar:boolean = true;

            //percorre response para gerar cada pix
            for await (const itRes of res.response){
                var valorParcelaPix = itRes.valor

                //gerar pix
                await this.criarCobrancaPix(valorParcelaPix, "0201") //; //res.response.filial);
                    .then( (resPix) => {
                        let pix_id = "";
                        let pix_cc = "";
                        let pix_qr = "";
        
                        if(resPix.status){
                            console.log(resPix.data.id);
        
                            pix_id = resPix.data.id;
                            pix_cc = resPix.data.pixCopiaECola;
                            pix_qr = resPix.data.imagemBase64;
                        }
        
                        titulos.push(
                            {
                                "codigo"        : cod_cliente,
                                "id_reneg"      : idReneg,
                                "tipo_reneg"    : 1,
                                "data_reneg"    : dtReneg,
                                "hora_reneg"    : hrReneg,
                                "filial"        : itRes.filial,
                                "numerotitulo"  : itRes.numero,
                                "prefixo"       : itRes.prefixo,
                                "tipo"          : itRes.tipo,
                                "loja"          : itRes.loja_cliente,
                                "parcela"       : itRes.parcela,
                                "tx_permanencia": 0,
                                "valor"         : valorParcelaPix,
                                "vencimento"    : itRes.data_vencimento,
                                "valor_final"   : valorParcelaPix,
                                "pix_id"        : pix_id,
                                "pix_cc"        : pix_cc,
                                "pix_qr"        : pix_qr,

                                "displaySucessoVlrDat" : `R$ ${lib.convertMoney(valorParcelaPix)} ${this.label.msg_reneg_comvenc} ${itRes.data_vencimento}`
                            }
                        )
                    })
                    .catch( (erro) => {
                        continuar = false;
                    })
            }

            this.colTitulosPix = titulos.filter( (w:any) => w.tipo_reneg == 1);

            let resMD = await this.renegociaSRV.setSalvarRenegociacao(titulos).toPromise() as any;

            if ((resMD.coderror != undefined && resMD.coderror != '') || resMD.response == "Nenhum dado para ser consultado"){
                Swal.fire
                (
                    this.label.msg_titulo_algo,
                    this.label.msg_descri_algo,
                    "info"
                );
    
                lib.preloader('hide');
                return;
            }else{
                var eleDivTotal  = document.getElementById(`divTotal`)               as HTMLInputElement;
                var eleQtdTotal  = document.getElementById(`lblQtdTotal`)            as HTMLInputElement;
                var eleVlrTotal  = document.getElementById(`lblVlrTotal`)            as HTMLInputElement;
                var eleVlrTotalR = document.getElementById(`lblVlrTotalRecalculado`) as HTMLInputElement; 
               
                eleQtdTotal.value = "";
                eleVlrTotal.value = "";
                eleVlrTotalR.value = "";
                eleDivTotal.style.display = "none";

                this.buscarTitulosVencidos();
                this.colMinhasReneg = [];
                this.minhasRenegociacoes = true;

                setTimeout(
                    ()=>{
                        $('#divMinhasRenegSucesso').show();
                        lib.preloader('hide');

                        this.consultarPix = true;
                        //this.consultarCobrancaPix(pix_id);

                    }, 1000
                );
            }
        }
    }

    minhasRenegAbreFecha(){
        if(this.minhasRenegociacoes){
            this.consultarPix = false;
            this.minhasRenegociacoes = false;
        }else{
            this.minhasRenegociacoes = true;
            this.buscarMinhasReneg();
        }
    }

    async buscarMinhasReneg(){
        let objSes = lib.getSession('user');
        let cod_cliente = objSes.codigo;
        lib.preloader('show');

        try{
            let res = await this.renegociaSRV.getMinhasRenegociacoes(cod_cliente).toPromise() as any;

            if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado"){
                return lib.preloader('hide');
            }else{
                this.colMinhasReneg = [];
                this.colMinhasRenegT = [];
                var lstGeral = res.response;

                let date_now = new Date();
                let month    = '' + (date_now.getMonth() + 1);
                let day      = '' + date_now.getDate();
                let year     = date_now.getFullYear();
    
                if (month.length < 2) {month = '0' + month;};
                if (day.length < 2) {day = '0' + day;};

                let date_now_str = year + month + day;

                //Separa as nfs
                var lstIdsReneg = [...new Set(lstGeral.map((item:any) => item.id_reneg))];

                lstIdsReneg.forEach((itIdReneg:any) => {
                    var lstTitulosReneg = lstGeral.filter((w:any) => w.id_reneg == itIdReneg);

                    lstTitulosReneg.forEach((itTT:any) => {

                        var tituloAtual = this.colNotasGeral.filter((itAtu:any) =>  itAtu.E1_FILIAL == itTT.filial && 
                                                                                    itAtu.E1_NUM == itTT.numerotitulo &&
                                                                                    itAtu.E1_PARCELA == itTT.parcela)

                        var dt = "";
                        var hr = "";

                        dt = itTT.data_reneg;
                        itTT.data_reneg     = `${dt.substr(6, 2)}/${dt.substr(4, 2)}/${dt.substr(0, 4)}`;

                        hr = itTT.hora_reneg;
                        itTT.hora_reneg     = `${hr.substr(0, 2)}:${hr.substr(2, 2)}:${hr.substr(4, 2)}`

                        dt = itTT.vencimento;
                        if(itTT.tipo_reneg == 0){
                            itTT.vencimento = `${dt.substr(6, 2)}/${dt.substr(4, 2)}/${dt.substr(0, 4)}`;
                        }else{
                            dt = `${ parseFloat(dt.split('/')[2]) + 2000 }${dt.split('/')[1]}${dt.split('/')[0]}`;
                        }

                        itTT.situacao = 0; //pendente
                        if(tituloAtual.length > 0 && tituloAtual[0].E1_STATUS == "B"){
                            itTT.situacao = 2; //pago
                        }else{
                            if(itTT.tipo_reneg == 1 && dt < date_now_str){
                                itTT.situacao = 1; //vencido
                            }
                        }

                        itTT.valor          = lib.convertMoney(parseFloat(itTT.valor))
                        itTT.tx_permanencia = lib.convertMoney(parseFloat(itTT.tx_permanencia))
                        itTT.valor_final    = lib.convertMoney(parseFloat(itTT.valor_final))
                    });

                    var lstTitulos1Reneg = lstTitulosReneg.filter((w:any) => w.tipo_reneg == 1);
                    var lstTitulos0Reneg = lstTitulosReneg.filter((w:any) => w.tipo_reneg == 0);

                    //for(var indexR = 0; indexR < lstTitulos1Reneg.length; indexR++){
                    //}

                    this.colMinhasReneg  = lstTitulos1Reneg;
                    this.colMinhasRenegT = lstTitulos0Reneg;

                    //(
                    //    {
                    //        id_reneg       : lstTitulos1Reneg[0].id_reneg      ,
                    //        tipo_reneg     : lstTitulos1Reneg[0].tipo_reneg    ,
                    //        data_reneg     : lstTitulos1Reneg[0].data_reneg    ,
                    //        hora_reneg     : lstTitulos1Reneg[0].hora_reneg    ,
                    //        filial         : lstTitulos1Reneg[0].filial        ,
                    //        numerotitulo   : lstTitulos1Reneg[0].numerotitulo  ,
                    //        prefixo        : lstTitulos1Reneg[0].prefixo       ,
                    //        tipo           : lstTitulos1Reneg[0].tipo          ,
                    //        parcela        : lstTitulos1Reneg[0].parcela       ,
                    //        tx_permanencia : lstTitulos1Reneg[0].tx_permanencia,
                    //        valor          : lstTitulos1Reneg[0].valor         ,
                    //        vencimento     : lstTitulos1Reneg[0].vencimento    ,
                    //        valor_final    : lstTitulos1Reneg[0].valor_final   ,
                    //        situacao       : lstTitulos1Reneg[0].situacao      ,
                    //        cliente        : cod_cliente,
                    //        loja           : lstTitulos1Reneg[0].loja          ,
                    //        pix_cc         : lstTitulos1Reneg[0].pix_cc        ,
                    //        pix_id         : lstTitulos1Reneg[0].pix_id        ,
                    //        pix_qr         : lstTitulos1Reneg[0].pix_qr        ,
                    //        "titulos"      : lstTitulos0Reneg
                    //    }
                    //)
                })
            }

        }catch (err) {
            console.log(err);
        }

        lib.preloader('hide');
    }

    async baixarDocumento(compra: any, tipo: string) {
        lib.preloader('show');

        //tipo = NF ou BL
        let json =
        {
            "filial"  : compra.E1_FILIAL,
            "num"     : compra.E1_NUM,
            "cliente" : compra.E1_CLIENTE,
            "loja"    : compra.E1_LOJA,
            "serie"   : compra.E1_SERIE,
            "tipo"    : tipo,
            "parcela" : ""
        }

        try{
            const res = await this.documentosSRV.getDocument(json).toPromise() as any;

            var objRet = JSON.parse(res.dados.replace('\\t','').replaceAll( '\\', '').replaceAll(' ', '').replace('"{"dados":', '').replace(',}}"', '}'));
            var strBase64 = "";
            var nomArquivo = "";
        
            if(tipo == "NF"){
                strBase64 = objRet.Nota64;
                var arrayNome = objRet.Nota.split('/');
                nomArquivo = arrayNome[arrayNome.length - 1];
            }
        
            if(tipo == "BL"){
                strBase64 = objRet.Boleto64;
                var arrayNome = objRet.Boleto.split('/');
                nomArquivo = arrayNome[arrayNome.length - 1];
            }
            
            if (strBase64 != undefined && strBase64.trim() != ""){
                var link = document.createElement('a');
                link.href = 'data:application/pdf;base64,' + strBase64;
                link.download = nomArquivo;
                link.target = "pdf-frame";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } 
            lib.preloader('hide');
        }catch{
            lib.preloader('hide');
            Swal.fire(this.label.msg_erro_tit, this.label.msg_erro_des, "warning");
            return;
        }
    }

    async baixarSetCanhoto(item: any) {
        lib.preloader('show');

        try{
            //item é um ponteiro para o objeto da compra
            let resMonitoraNota = await this.titulosSRV.monitorarNF(item.E1_NUM, item.E1_SERIE, item.E1_FILIAL).toPromise() as any;
            if(resMonitoraNota.dados){
                resMonitoraNota = resMonitoraNota.dados;
            }else{
                Swal.fire(this.label.msg_erro_tit, this.label.msg_erro_des, "warning");
                lib.preloader('hide');
                return;
            }

            let resCanhoto = await this.titulosSRV.retornarSetCanhoto(resMonitoraNota.DOC_CHV).toPromise() as any;

            if(resCanhoto.coderror){
                Swal.fire(this.label.msg_erro_tit, resCanhoto.coderror, "warning");
                lib.preloader('hide');
                return;
            }

            resCanhoto = resCanhoto.response.dados;
            let imgCanhoto = resCanhoto.pedido_recebedor_assinatura;
            if(imgCanhoto == '' || imgCanhoto == undefined || imgCanhoto == null){
                Swal.fire(this.label.msg_erro_tit, this.label.msg_erro_naoencontrado, "warning");
                lib.preloader('hide');
                return false;
            }

            var link = document.createElement('a');
            link.href = imgCanhoto;
            link.download = "canhoto.jpg";
            link.target = "img-frame";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            lib.preloader('hide');
            return true;
        }catch{
            lib.preloader('hide');
            Swal.fire(this.label.msg_erro_tit, this.label.msg_erro_des, "warning");
            return;
        }
    }

    async baixarBoletoRenegociado(numero:string, parcela:string){
        var hdf_renegociado_num = document.getElementById( 'hdf_renegociado_num_' + numero + "_" + parcela ) as HTMLInputElement;
        var hdf_renegociado_fil = document.getElementById( 'hdf_renegociado_fil_' + numero + "_" + parcela ) as HTMLInputElement;
        var hdf_renegociado_pfx = document.getElementById( 'hdf_renegociado_pfx_' + numero + "_" + parcela ) as HTMLInputElement;
        var hdf_renegociado_cli = document.getElementById( 'hdf_renegociado_cli_' + numero + "_" + parcela ) as HTMLInputElement;
        var hdf_renegociado_loj = document.getElementById( 'hdf_renegociado_loj_' + numero + "_" + parcela ) as HTMLInputElement;
        var hdf_renegociado_tip = document.getElementById( 'hdf_renegociado_tip_' + numero + "_" + parcela ) as HTMLInputElement;

        var compra = {
            E1_FILIAL  : hdf_renegociado_fil.value,
            E1_NUM     : hdf_renegociado_num.value,
            E1_CLIENTE : hdf_renegociado_cli.value,
            E1_LOJA    : hdf_renegociado_loj.value,
            E1_SERIE   : hdf_renegociado_pfx.value,
        }
        this.baixarDocumento(compra, hdf_renegociado_tip.value);
    }

    async baixarBoletoPendente(pendente:any){
        var compra = {
            E1_FILIAL  : pendente.filial,
            E1_NUM     : pendente.numerotitulo,
            E1_CLIENTE : pendente.cliente,
            E1_LOJA    : pendente.loja,
            E1_SERIE   : pendente.prefixo,
        }
        this.baixarDocumento(compra, pendente.tipo);
    }

    async copiarLinkPixRenegociado(numero:string, parcela:string){
        var strCopy = $('#hdfLinkPix_' + numero + "_" + parcela).val()?.toString() || "";
        this.copiar_cola(strCopy)
    }

    async copiarLinkPixPendente(strCopy:string){
        strCopy = strCopy || "";
        this.copiar_cola(strCopy)
    }

    async copiar_cola(strCopy:string){
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = strCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    async criarCobrancaPix(valor:any, filial:string) {
        var objRetorno =
        {
            status:true,
            msg:"",
            data:{
                id            : "",
                imagemBase64  : "",
                pixCopiaECola : ""
            }
        }

        let obj =
        {
            valor: valor,
            grupo: filial.substr(0, 2)
        }

        let resPix = await this.pgtosSRV.criarCobrancaPix(obj).toPromise() as any;

        if (resPix == undefined || resPix == ''){
            objRetorno.status = false;
            objRetorno.msg = this.label.msg_pix_naogerou;
        }else{
            if (resPix != undefined && resPix.error){
                objRetorno.status = false;
                objRetorno.msg = this.label.msg_pix_naogerou;
            }

            if(objRetorno.status){
                objRetorno.data =
                {
                    "id"            : resPix.txid,
                    "imagemBase64"  : resPix.imagemBase64,
                    "pixCopiaECola" : resPix.pixCopiaECola
                }
            }
        }

        return objRetorno;
    }

    async consultarCobrancaPix(txid:string){
        setTimeout(
            async () => {
                let resConsulta = await this.pgtosSRV.consultarCobrancaPix(txid).toPromise() as any;

                if(resConsulta != undefined){
                    if(resConsulta.erro){
                    }else if (resConsulta.status == 'CONCLUIDA'){
                        $('.pix-pago').show();

                        setTimeout(
                            () => {
                                this.consultarPix = false;
                                this.minhasRenegociacoes = false;
                                this.buscarTitulosVencidos();
                            }, 4000
                        )
                    }else{
                        if(this.consultarPix)
                            this.consultarCobrancaPix(txid);
                    }
                }
        
            }, 5000
        )
    }

    setTimeline(){
        let objSes = lib.getSession('user');

        var objTl:objTimeline = 
        {
            cod: objSes.codigo,
            pag: "renegocia",
            sts: true,
            msg: ""
        };

        this.tlSRV.setTimeline(objTl).toPromise();
    }

    async recalculaTitulos(){
        //data atual
        var dL      = new Date(),
            yearL   = '' + (dL.getFullYear()),
            monthL  = '' + (dL.getMonth() + 1),
            dayL    = '' + (dL.getDate())

        if (monthL.length < 2) {monthL = '0' + monthL;};
        if (dayL.length < 2) {dayL = '0' + dayL;};

        var dat_sel = $('#inputDateVencimento').val() || "";   //data informada
        var par_sel = $('#selectNumeroParcelas').val() || "";  //parcela informada

        if(dat_sel == ""){
            dat_sel = `${yearL}-${monthL}-${dayL}`;
        }

        var ano = dat_sel.toString().split('-')[0];
        var mes = dat_sel.toString().split('-')[1];
        var dia = dat_sel.toString().split('-')[2];

        var dat_ven  = new Date( parseFloat(ano) , parseFloat(mes)-1 , parseFloat(dia) );
        var dat_atu  = new Date( parseFloat(yearL) , parseFloat(monthL)-1 , parseFloat(dayL) );
        var dat_aux1 = new Date( parseFloat(yearL) , parseFloat(monthL)-1 , parseFloat(dayL) );
        var dat_max  = new Date(dat_aux1.setDate(dat_aux1.getDate() + 30));
        var dat_aux2 = new Date( parseFloat(yearL) , parseFloat(monthL)-1 , parseFloat(dayL) );
        var dat_free = new Date(dat_aux2.setDate(dat_aux2.getDate() + 5));

        if(dat_ven<dat_atu){
            Swal.fire ( "Ops!", this.label.msg_datamenorhoje, "info" );
            return;
        }

        if(dat_ven>dat_max){
            Swal.fire ( "Ops!", this.label.msg_datamaior30, "info" );
            return;
        }

        //atualiza variaveis
        this.datVencimento = `${ano}-${mes}-${dia}`;
        this.nroParcela = par_sel.toString();

        //recalcula
        var totalGeralRecalculado = 0;
        var colCheckBox = document.getElementsByTagName("input");

        for(var index = 0; index < colCheckBox.length; index++){
            if(colCheckBox[index].id.indexOf('chkp_')>-1){
                var chave = colCheckBox[index].id.replace('chkp_', '');
                var hdf_tit = document.getElementById( 'hdf_'+chave+'_tit' ) as HTMLInputElement;
                var hdf_fil = document.getElementById( 'hdf_'+chave+'_fil' ) as HTMLInputElement;
                var hdf_pfx = document.getElementById( 'hdf_'+chave+'_pfx' ) as HTMLInputElement;
                var hdf_tip = document.getElementById( 'hdf_'+chave+'_tip' ) as HTMLInputElement;
                var hdf_vlr = document.getElementById( 'hdf_'+chave+'_vlr' ) as HTMLInputElement;
                var hdf_txp = document.getElementById( 'hdf_'+chave+'_txp' ) as HTMLInputElement;
                var hdf_vct = document.getElementById( 'hdf_'+chave+'_vct' ) as HTMLInputElement;
                var hdf_vaz = document.getElementById( 'hdf_'+chave+'_vaz' ) as HTMLInputElement;
                var hdf_ems = document.getElementById( 'hdf_'+chave+'_ems' ) as HTMLInputElement;

                var parcela = "001";
                if(hdf_vaz.value == "false"){
                    parcela = chave.split('_')[1];
                }

                var objNf = this.colNotas.filter((w:any) => w.E1_NUM == hdf_tit.value);
                var objTi = objNf[0].TITULOS.filter((w:any) => w.E1_PARCELA == parcela);

                if(colCheckBox[index].checked){
                    var recalcular = true;

                    if(par_sel != "1"){
                        recalcular = true;
                    }else{
                        if(dat_ven>dat_free){
                            recalcular = true;
                        }else{
                            recalcular = false;
                        }
                    }

                    //calcula dias vencido
                    var yd1 = objTi[0].E1_VENCTO.toString().split('/')[2]
                    var md1 = objTi[0].E1_VENCTO.toString().split('/')[1]
                    var dd1 = objTi[0].E1_VENCTO.toString().split('/')[0]
                    var d1 = new Date( parseFloat(yd1), parseFloat(md1)-1, parseFloat(dd1) )

                    var diasVencido = Math.floor
                    (
                        (
                            Date.UTC(dat_atu.getFullYear(), dat_atu.getMonth(), dat_atu.getDate()) -
                            Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
                        ) / (1000 * 60 * 60 * 24)
                    );

                    if(recalcular){
                        //calcula juros
                        var valorJuros = parseFloat(objTi[0].TX_PERMANECIA) * diasVencido;
                        valorJuros = Math.round((valorJuros) * 100) / 100;

                        //calcula valor
                        var valorFinal = parseFloat(objTi[0].E1_VALOR) + valorJuros;
                        valorFinal = Math.round((valorFinal) * 100) / 100;

                        objTi[0].E1_SALDO_FINAL_CONVERTIDO = lib.convertMoney(valorFinal);

                        totalGeralRecalculado = totalGeralRecalculado + valorFinal;
                    }else{
                        objTi[0].E1_SALDO_FINAL_CONVERTIDO = lib.convertMoney(parseFloat(objTi[0].E1_VALOR));

                        totalGeralRecalculado = totalGeralRecalculado + parseFloat(objTi[0].E1_VALOR);
                    }
                }else{
                    objTi[0].E1_SALDO_FINAL_CONVERTIDO = lib.convertMoney(parseFloat(objTi[0].E1_VALOR));
                }
            }
        }

        var eleVlrTotalR = document.getElementById(`lblVlrTotalRecalculado`) as HTMLInputElement;
        eleVlrTotalR.value = `R$ ${lib.convertMoney(totalGeralRecalculado)}`;
    }

    aplicaCssTermo(){
        var eleDiv = document.getElementById('divAceito') as HTMLDivElement;
    
        for (var indexEle = 0; indexEle < eleDiv.children.length; indexEle++) {
            var ele = eleDiv.children[indexEle];
            var nome = `${ele.tagName}${indexEle}a`;
    
            ele.setAttribute('id', nome)
            switch(ele.tagName)
            {
              case 'P': 
                var eleP = document.getElementById(nome) as HTMLElement;
                eleP.style.color = "#2F3032";
                eleP.style.fontSize = "13px";
                eleP.style.paddingTop = "10px";
                break;
    
              case 'UL':
                var eleUL = document.getElementById(nome) as HTMLElement;
                eleUL.style.color = "#2F3032";
                eleUL.style.fontSize = "13px";
                eleUL.style.paddingLeft = "20px";
                eleUL.style.listStyle = "inside";
                eleUL.style.listStylePosition = "outside";
                break;
    
              case 'LI':
                var eleLI = document.getElementById(nome) as HTMLElement;
                eleLI.style.paddingBottom = "10px";
                break;
            }
        }
    }

    valorPorExtenso (vlr:number){
        var retorno:string = "zero";

        var Num     = parseFloat(vlr.toString());
        var inteiro = parseInt(vlr.toString()); // parte inteira do valor

        if(vlr>0 && inteiro<1000000000000000){
            var numAux     = parseFloat( Num.toFixed(2) );
            var inteiroAux = parseFloat( inteiro.toFixed(2) );

            var resto = numAux - inteiroAux; // parte fracionária do valor
            resto = parseFloat( resto.toFixed(2) );

            var vlrS =  inteiro.toString();

            var cont=vlrS.length;
            var extenso="";
            var auxnumero;
            var auxnumero2;
            var auxnumero3;

            var unidade    = ["", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"];
            var centena    = ["", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"];
            var dezena     = ["", "", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"];
            var qualificaS = ["reais", "mil", "milhão", "bilhão", "trilhão"];
            var qualificaP = ["reais", "mil", "milhões", "bilhões", "trilhões"];

            for (var i=cont;i > 0;i--){
                var verifica1="";
                var verifica2=0;
                var verifica3=0;
                auxnumero2="";
                auxnumero3="";
                auxnumero=0;
                auxnumero2 = vlrS.substr(cont-i,1);    
                auxnumero = parseInt(auxnumero2);

                if((i==14)||(i==11)||(i==8)||(i==5)||(i==2)){
                    auxnumero2 = vlrS.substr(cont-i,2);
                    auxnumero = parseInt(auxnumero2);
                }

                if((i==15)||(i==12)||(i==9)||(i==6)||(i==3)){
                    extenso=extenso+centena[auxnumero];
                    auxnumero2 = vlrS.substr(cont-i+1,1)
                    auxnumero3 = vlrS.substr(cont-i+2,1)

                    if((auxnumero2!="0")||(auxnumero3!="0"))
                    extenso+=" e ";
                }else{
                    if(auxnumero>19){
                        auxnumero2 = vlrS.substr(cont-i,1);
                        auxnumero = parseInt(auxnumero2);
                        extenso=extenso+dezena[auxnumero];
                        auxnumero3 = vlrS.substr(cont-i+1,1)

                        if((auxnumero3!="0")&&(auxnumero2!="1"))
                        extenso+=" e ";
                    }else if((auxnumero<=19)&&(auxnumero>9)&&((i==14)||(i==11)||(i==8)||(i==5)||(i==2))){
                        extenso=extenso+unidade[auxnumero];   
                    }else if((auxnumero<10)&&((i==13)||(i==10)||(i==7)||(i==4)||(i==1))){
                        auxnumero3 = vlrS.substr(cont-i-1,1);
                        if((auxnumero3!="1")&&(auxnumero3!=""))
                        extenso=extenso+unidade[auxnumero]; 
                    }

                    if(i%3==1){
                        verifica3 = cont-i;
                        if(verifica3==0)
                        verifica1 = vlrS.substr(cont-i,1);
            
                        if(verifica3==1)
                        verifica1 = vlrS.substr(cont-i-1,2);
            
                        if(verifica3>1)
                        verifica1 = vlrS.substr(cont-i-2,3);
            
                        verifica2 = parseInt(verifica1);
                    }else if(verifica2!=0){
                        extenso=extenso+" "+qualificaP[4]+" ";
                    }
                }

                if(i==10){
                    if(verifica2==1){
                        extenso=extenso+" "+qualificaS[3]+" ";
                    }else if(verifica2!=0){
                        extenso=extenso+" "+qualificaP[3]+" ";
                    }
                }

                if(i==7){
                    if(verifica2==1){
                        extenso=extenso+" "+qualificaS[2]+" ";
                    }else if(verifica2!=0){
                        extenso=extenso+" "+qualificaP[2]+" ";
                    }
                }

                if(i==4){
                    if(verifica2==1){
                        extenso=extenso+" "+qualificaS[1]+" ";
                    }else if(verifica2!=0){
                        extenso=extenso+" "+qualificaP[1]+" ";
                    }
                }

                if(i==1) {
                    if(verifica2==1){
                        extenso=extenso+" "+qualificaS[0]+" ";
                    }else {
                        extenso=extenso+" "+qualificaP[0]+" ";
                    }
                }
            }

            resto = resto * 100;
            var aexCent=0;

            if(resto<=19&&resto>0)
                extenso+=" e "+unidade[resto]+" centavos";

            if(resto>19){
                aexCent=parseInt( (resto/10).toString() );
                extenso+=" e "+dezena[aexCent]
                resto=resto-(aexCent*10);
        
                if(resto!=0)
                    extenso+=" e "+unidade[resto]+" centavos";
                else
                    extenso+=" centavos";
            }

            retorno = extenso;
        }

        return retorno;
    }
    
}