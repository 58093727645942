import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class RenegociaService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getMinhasRenegociacoes(cod_cliente:string):Observable<objRetorno>{
        return this.http.post("buscaRenegociacoes",  { "cod_cliente" : cod_cliente });
    }

    setConcluirRenegociacao(titulos:any, renegociacoes:any):Observable<objRetorno>{
        return this.http.post("concluirRenegociacao",  { "titulos" : titulos, "renegociacoes" : renegociacoes });
    }

    setSalvarRenegociacao(titulos:any):Observable<objRetorno>{
        return this.http.post("salvarRenegociacao",  { "titulos" : titulos });
    }
}