import { Component, OnInit } from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { ComprasService } from 'src/app/shared/services/compras/compras.service';
import { MeiosPagamentoService } from 'src/app/shared/services/meiospagamento/meiospagamento.service';

export interface interDetalhePrd
{
    prd : string;
    qtd : string;
    vlr : string;
};

export interface interResumoPgt
{
    for : string;
    vlr : string;
    qtd : string;
    des : string;
};

export interface interDetalhePgt
{
    for : string;
    vlr : string;
    qtd : string;
    dat : string;
};

@Component({
  selector: 'app-compradetalhes',
  templateUrl: './compradetalhes.component.html',
  styleUrls: [
    './compradetalhes.component.css',
  ],
})

export class CompraDetalhesComponent implements OnInit {

  colDetPrd: interDetalhePrd[];
  colResPgt: interResumoPgt[];
  colDetPgt: interDetalhePgt[];

  constructor(private comprasSRV : ComprasService,
              private meiospgtSRV : MeiosPagamentoService)
  {
    this.colDetPrd = [];
    this.colResPgt = [];
    this.colDetPgt = [];
  }

  ngOnInit() {
    this.buscarDetalhesCompra();
  };

  async buscarDetalhesCompra(){

    let numorc = "048973";
    let filial = "0201";
    let colMeiosPgt: any[];

    await this.meiospgtSRV.getMeiosPagamento().subscribe(
      res => { colMeiosPgt = Object.values(res.dados); },
      err => {},
    )

    this.comprasSRV.getDetalhesCompra(filial, numorc).subscribe(
      res => {

        if(res.coderror != undefined && res.coderror != ""){
        }else{
          //let objSL1 = Object.values(res.response.SL1);
          let objSL2 = Object.values(res.response.SL2);
          let objSL4 = Object.values(res.response.SL4);

          this.colDetPrd = [];
          for(var index = 0; index < objSL2.length; index++){
            this.colDetPrd.push(
            {
              prd : res.response.SL2[index].L2_PRODUTO + ' - ' + res.response.SL2[index].L2_DESCRI,
              qtd : res.response.SL2[index].L2_QUANT,
              vlr : res.response.SL2[index].L2_VLRITEM
            });
          }

          this.colResPgt = [];
          this.colDetPgt = [];
          for(var index = 0; index < objSL4.length; index++){

            this.colDetPgt.push(
              {
                for : res.response.SL4[index].L4_FORMA,
                qtd : "1",
                vlr : res.response.SL4[index].L4_VALOR,
                dat : ""
              });

            var forAtual = res.response.SL4[index].L4_FORMA;
            var objForma = this.colResPgt.filter(w => w.for == forAtual);

            if(objForma.length == 0){
              this.colResPgt.push(
                {
                  for : res.response.SL4[index].L4_FORMA,
                  qtd : "1",
                  vlr : res.response.SL4[index].L4_VALOR,
                  des : ""
                });
            }else{
              var qtdaux = parseFloat(objForma[0].qtd) + 1
              var vlraux = parseFloat(objForma[0].vlr) + parseFloat(res.response.SL4[index].L4_VALOR);

              objForma[0].qtd = qtdaux.toString();
              objForma[0].vlr = vlraux.toString();
            }
          }
        }

        for(var index = 0; index < this.colResPgt.length; index++){
          let forExistente = colMeiosPgt.filter(w => w.X5_CHAVE == this.colResPgt[index].for)[0];
          switch(this.colResPgt[index].for)
          {
            case "DE":
              this.colResPgt[index].des = `R$ ${this.colResPgt[index].vlr} no depósito em conta.`; break;

            case "CC":
              this.colResPgt[index].des = `R$ ${this.colResPgt[index].vlr} no cartão de crédito em ${this.colResPgt[index].qtd}x`; break;

            default:
              this.colResPgt[index].des = `R$ ${this.colResPgt[index].vlr} no ${forExistente.X5_DESCRI.toLowerCase() }`; break;
          }
        }

      },
      err => {
        console.log(err);
      }
    );

  };

  buscarDetalhesTitulos(){
  };

}