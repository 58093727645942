import { Component , OnInit} from '@angular/core';
import * as translations from './primeiro-acesso.translate.json';
import { lib } from 'src/app/shared/services/common/lib.service';
import Swal from 'sweetalert2'
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import { EmailService } from 'src/app/shared/services/email/email.service';
import { WhatsappService } from 'src/app/shared/services/whatsapp/whatsapp.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './primeiro-acesso.component.html',
  styleUrls: ['./primeiro-acesso.component.css']
})
export class PrimeiroAcessoComponent implements OnInit {
  translate: any = translations;
  currentYear: any = new Date().getFullYear();
  language: any = lib.getSession('language') == null ? 'pt' : lib.getSession('language');
  label: any = {};
  lib = lib;
  infoCliente: any = {};
  box:string = '1';//1 = encontrar a conta, 2 = tipo de meio de contato, 3 = codigo de verificação, 4 = nova senha
  tokenInputFull:boolean = false;
  pastaImagens = lib.getSession('pastaImagens');
  strongPassmin8 = false;
  strongPass1Upper = false
  strongPass1Lower = false
  strongPass1Especial = false
  strongPass1Number = false
  strongPassIgual = false
  constructor(
    private clienteService: ClienteService,
    private emailService: EmailService,
    private whatsappService: WhatsappService
  ) {
    lib.getSession('language') == null ? lib.setSession('language', 'pt') : '';//se não tiver linguagem setada, seta pt
    this.label = lib.getTranslate(this.translate);
    lib.preloader('hide');
  }

  ngOnInit(): void {
    setTimeout(() => {  
      // this.ativarInputsToken();
    }, 1000);

  }

  nextStage(id:string){
    this.box = id;
    if (id == '5') {
      Swal.fire({
        title: this.label.msgSuccess,
        text: this.label.msgSuccessText,
        icon: 'success',
        confirmButtonText: this.label.msgSuccessBtn
      }).then((result) => {
        if (result.value) {
          window.location.href = '/login';
        }
      })
    }
  }

  async getClienteByCQC(){//pega o cliente pelo cpf ou cnpj
    let doc = document.getElementById('doc') as HTMLInputElement;
    //console.log(doc.value);
    if (doc.value == "") {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.txt3,
        icon: 'error',
      })
      return;
    }
    
    //verifica se o cpf é valido ou é um cnpj valido
    if (!lib.validaCPF(doc.value) && !lib.validaCNPJ(doc.value)) {
      Swal.fire({
        title: this.label.msgWarningTitle,
        text: this.label.msgErrorDocText,
        icon: 'error',
        confirmButtonText: "Ok"
      })
      lib.preloader('hide');
      return;
    }
    lib.preloader('show');
    let infoCliente:any = await this.clienteService.getClienteByCQC(doc.value).toPromise();//pega o cliente pelo cpf ou cnpj
    lib.preloader('hide');
    if (infoCliente.coderror){//se tiver algum erro
      Swal.fire( infoCliente.response.msg[0][this.language as keyof typeof infoCliente.response.msg], '', 'info');
      return;
    }
    this.infoCliente = infoCliente.response;//
    this.infoCliente.telefone = this.infoCliente.telefone.replace(/\D/g, '');//remove todos os caracteres que não são números
    this.infoCliente.smsHidden = this.infoCliente.telefone.replace(/.(?=.{4})/g, '*');//pega os 4 últimos caracteres do telefone e substitui o resto por *
    
    let email = this.infoCliente.email.split('@');//separa o email em 2 partes, antes e depois do @
    let emailHidden =(email[0].length>5)? email[0].substr(0,4): email[0].substr(0,2);//pega os 5 primeiros caracteres do email
    //console.log(emailHidden);
    emailHidden += '*'.repeat(email[0].length);//substitui o resto por *
    emailHidden += '@' + email[1].replace(/.(?=.{3})/g, '*');//pega os 6 últimos caracteres do email e substitui o resto por *
    this.infoCliente.emailHidden = emailHidden;//atribui o emailHidden ao objeto infoCliente
    this.nextStage('2');
  }

  async getWayContact(){
    //get way contact from input radio name="wayContact"
    let way = (document.querySelector('input[name="wayContact"]:checked') as HTMLInputElement);//pega o input radio marcado
    if (!way) {//se não tiver nenhum marcado
      Swal.fire({
        title: this.label.msgWarningTitle,
        text: this.label.msgErrorWayContactText,
        icon: 'warning',
      });
      return;
    }
    this.infoCliente.wayContact = way.value;
    lib.preloader('show');
    if (way.value == 'email') {//se o meio de contato for email
      await this.emailService.enviarToken({codigo: this.infoCliente.codigo}).toPromise();
    }
    if (way.value == 'whats') {//se o meio de contato for whatsapp
      await this.whatsappService.enviarToken({number: this.infoCliente.telefone}).toPromise();
    }
    this.ativarInputsToken();//ativa os inputs de token
    this.nextStage('3');//vai para a próxima etapa
    lib.preloader('hide');//esconde o preloader
  }

  async verifyCode(){//verifica o código de verificação
    let code1 = (document.getElementById('code-verification-1') as HTMLInputElement).value as string;
    let code2 = (document.getElementById('code-verification-2') as HTMLInputElement).value as string;
    let code3 = (document.getElementById('code-verification-3') as HTMLInputElement).value as string;
    let code4 = (document.getElementById('code-verification-4') as HTMLInputElement).value as string;
    if ( code1 == "" || code2 == "" || code3 == "" || code4 == "") {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.msgErrorNotCodeText,
        icon: 'error',
      })
      return;
    }
    let code = `${code1}${code2}${code3}${code4}`;//junta os 4 inputs de código
    lib.preloader('show');
    if (this.infoCliente.wayContact == 'email') {//se o meio de contato for email
      let verifyCode:any = await this.clienteService.verificarTokenEmail({codigo: this.infoCliente.codigo, token: code}).toPromise();
      lib.preloader('hide');
      //console.log(verifyCode);
      if(verifyCode.response == true){//se o código for válido
        this.nextStage('4');
        return;
      }
      if(verifyCode.coderror){
        Swal.fire( verifyCode.coderror[0][this.language as keyof typeof verifyCode.coderror], '', 'info');
        return;
      } 
    }
    if (this.infoCliente.wayContact == 'whats') {//se o meio de contato for whatsapp

      try{
        let verifyCode:any = await this.whatsappService.validarPin({number: this.infoCliente.telefone, pin: code}).toPromise();

        lib.preloader('hide');
        if (verifyCode.msg == 'PIN code verified') {//se o código for válido
          this.nextStage('4');
          return;
        }
      }catch(err){
        lib.preloader('hide');
        Swal.fire( 'Ops!', 'Código PIN não confere.', 'warning');
        return;
      }

    }
  }

  ativarInputsToken(){
    setTimeout(() => {
    //quando o cliente clicar em um id code-verification-1, ele vai para o próximo
    let code1 = document.getElementById('code-verification-1') as HTMLInputElement;
    let code2 = document.getElementById('code-verification-2') as HTMLInputElement;
    let code3 = document.getElementById('code-verification-3') as HTMLInputElement;
    let code4 = document.getElementById('code-verification-4') as HTMLInputElement;
    code1.addEventListener('keyup', (event) => {//ao digitar o 1º digito vai para o próximo
      if (code1.value.length == 1 && this.tokenInputFull == false) {
        code2?.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
      if (code1.value.length > 1) {//se o input tiver mais de 1 digito
        this.tokenInputFull = true;
        let aux = code1.value.split('');//separa os digitos
        code1.value = aux[0];//atribui o 1º digito ao input
        code2.value = aux[1];//atribui o 2º digito ao input
        code3.value = aux[2];//atribui o 3º digito ao input
        code4.value = aux[3];//atribui o 4º digito ao input
        this.verifyCode();
      }
    });
    code2.addEventListener('keyup', (event) => {//ao digitar o 2º digito vai para o próximo
      if (code2.value.length == 1 && this.tokenInputFull == false) {
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
      if (event.key == "Backspace") {//se apertar backspace
        this.tokenInputFull = false;
        code1.focus();
        code1.setSelectionRange(0, code1.value.length);
      }
    });
    code3.addEventListener('keyup', (event) => {
      if (code3.value.length == 1 && this.tokenInputFull == false) {
        code4.focus();
        code4.setSelectionRange(0, code4.value.length);
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code2.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
    });
    //ao digitar o 4º digito e apertar Enter, verifica o código
    code4.addEventListener('keyup', (event) => {
      if (event.key == "Enter") {
        this.verifyCode();
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
    });
    }, 1000);
  }

  ativarInputsSenha(){
    setTimeout(() => {
      //quando o cliente clicar em um id code-verification-1, ele vai para o próximo
      let senha1 = document.getElementById('senha1') as HTMLInputElement;
      let senha2 = document.getElementById('senha2') as HTMLInputElement;
      senha1.addEventListener('keyup', (event) => {
        if (event.key == "Enter") {
          senha2.focus();
          senha2.setSelectionRange(0, senha2.value.length);
        }
      });
      senha2.addEventListener('keyup', (event) => {
        if (event.key == "Enter") {
          this.setSenha();
        }
      });
    }, 1000);
  }

  setSenha(){
    let senha1 = (document.getElementById('senha1') as HTMLInputElement).value as string;
    if (this.strongPassmin8 == false || this.strongPass1Upper == false || this.strongPass1Lower == false || this.strongPass1Especial == false || this.strongPass1Number == false || this.strongPassIgual == false) {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.msgErrorSenhaText,
        icon: 'error',
      })
      return;
    }
    //alerta com o termo de uso e apos aceitar, chama a função setNovaSenha

    //Swal.fire({
    //  title: this.label.msgWarningTitleTermo,
    //  text: this.label.msgWarningTermoText,
    //  icon: 'warning',
    //  showCancelButton: true,
    //  confirmButtonText: this.label.msgWarningTermoBtn,
    //  cancelButtonText: this.label.msgWarningTermoBtnCancel
    //}).then((result) => {
    //  if (result.isConfirmed) {
    //    lib.preloader('show');
    //    this.clienteService.setOptIn(this.infoCliente.codigo, true, '1').subscribe((res:any) => {
            this.clienteService.setNovaSenha({codigo:this.infoCliente.codigo, senha:senha1}).subscribe((res:any) => {
              lib.preloader('hide');
              if (res.coderror) {
                Swal.fire( res.coderror[0][this.language as keyof typeof res.coderror], '', 'info');
                return;
              }
              let deviceToken = navigator.userAgent;
              this.clienteService.setDeviceToken({codigo:this.infoCliente.codigo, token: deviceToken}).subscribe((res:any) => {
                this.nextStage('5');
              });
            });
    //    });
    //  }
    //})

  }

  togglePass(id:string){
    let pass = document.querySelector('#'+id) as HTMLInputElement;
    if (pass.type == 'password') {
      pass.type = 'text';
    }else{
      pass.type = 'password';
    }
  }

  strongPass(){
    let senha1 = (document.getElementById('senha1') as HTMLInputElement).value as string;
    let senha2 = (document.getElementById('senha2') as HTMLInputElement).value as string;
    this.strongPassmin8 = false;
    this.strongPass1Upper = false;
    this.strongPass1Lower = false;
    this.strongPass1Especial = false;
    this.strongPass1Number = false;
    this.strongPassIgual = false;
    if (senha1.length >= 6) {
      this.strongPassmin8 = true;
    }
    if (senha1.match(/[A-Z]/)) {
      this.strongPass1Upper = true;
    }
    if (senha1.match(/[a-z]/)) {
      this.strongPass1Lower = true;
    }
    if (senha1.match(/[!@#$%*.]/)) {
      this.strongPass1Especial = true;
    }
    if (senha1.match(/[0-9]/)) {
      this.strongPass1Number = true;
    }
    if (senha1 == senha2 && senha1 != "") {
      this.strongPassIgual = true;
    }
    //console.log(senha1, senha2);
    
  }

}
