<div class="crancy-body-area ">
    <app-menu></app-menu>
    <app-header></app-header>

    <section class="crancy-adashboard crancy-show">
        <div class="container">
            <div class="row">
                <div class="col-12 mg-top-30">
                    <img src="assets/img/banner//{{pastaImagens}}/banner.png" style="border-radius: 10px;width: 100%;"/>
                </div>
            </div>

            <div class="row">
                <div class="col-10 mg-top-30">
                    <div class="crancy-pbreadcrumb">
                        <h4 class="crancy-sidebar__title">{{label.titulo}}</h4>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="crancy-body">
                        <div class="crancy-dsinner">
                            <div *ngFor="let compra of minhasCompras">
                                <div class="crancy-page-inner crancy-pricing mg-top-30">
                                    <div class="row">
                                        <div class=" col-12">
                                            <div class="crancy-section-title mg-btm-20low row ">
                                                <div class="col">
                                                    <div class="col text-strong"> {{label.numPedido}} </div>
                                                    <div class="col"> #{{compra.doc}} </div>
                                                </div>
                                                <div class="col">
                                                    <div class="col text-strong"> {{label.data}} </div>
                                                    <div class="col"> {{compra.ems}} </div>
                                                </div>
                                                <button class=" btn col text-strong text-destaque" (click)="toggleDetalhes(compra)">
                                                    {{label.detalhePedido}}
                                                    <span *ngIf="!compra.temAtraso">
                                                        <i id="itd-{{compra.orc}}" class="fa fa-angle-down"></i>
                                                        <i id="itu-{{compra.orc}}" class="fa fa-angle-up d-none"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="crancy-section-title mg-btm-0 d-none" id="box-detalhe-compra-{{compra.orc}}">
                                                <hr>
                                                <!-- info da devolucao/troca -->
                                                <div class="box-produtos row">
                                                    <hr>
                                                    <div class="info-produto mb-3 row" *ngFor="let produto of compra.devolucoes">
                                                        <div class="text-left col-12 col-md-5 row">
                                                            <div class="text-left col-12 col-md-4 text box-desc-prod">
                                                                <img src="{{produto.img}}" alt="cpp" class="img-fluid">
                                                            </div>
                                                            <div class=" text-left col-12 col-md-8">
                                                                {{produto.desproduto}}
                                                                <br>
                                                                {{label.qtd}}: {{produto.quantidade}}
                                                            </div>
                                                        </div>
                                                        <div class="text-end col-12 col-md-5 text-info-devtro">
                                                            {{label.tipsol}}: {{produto.destipo}}
                                                            <br>
                                                            {{label.motsol}}: {{produto.desmotivo}}
                                                            <br>
                                                            {{label.dessol}}: {{produto.descricao}}
                                                        </div>
                                                        <div class="text-end col-12 col-md-2">
                                                            R$ {{produto.valor}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>
</div>