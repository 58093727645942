import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';
import { lib } from '../common/lib.service';

@Injectable({providedIn: 'root'})

export class ComprasService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getHistoricoComprasFiltros(cod_cliente:any, pag:any, linhas:any, emissao:any, emissaoate:any, notas:any, produto:any):Observable<objRetorno>{
        let jwt = lib.getSession('user').jwt;
        let filtros =
        {
             "cod_cliente" : cod_cliente,
             "pag"         : pag,
             "linhas"      : linhas,
             "notas"       : notas,
             "descproduto" : produto,
             "emissao"     : emissao,
             "emissaoate"  : emissaoate,
        }
        return this.http.post("buscarHistorico", filtros );
    }

    getHistoricoCompras(cod_cliente:any, pag:any, linhas:any):Observable<objRetorno>{
        //console.log(cod_cliente);
        
        let jwt = lib.getSession('user').jwt;
        return this.http.post("buscarHistorico", { "cod_cliente" : cod_cliente, "pag" : pag, "linhas" : linhas } );
    }

    getDetalhesCompra(filial:string, numorc:string):Observable<objRetorno>{
        return this.http.post("buscarDetalhesVenda", { "filial" : filial, "numorc" : numorc } );
    };
}