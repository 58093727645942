<div class="crancy-body-area ">
    <app-menu></app-menu>
    <app-header></app-header>

    <section class="crancy-adashboard crancy-show">
      <div class="container">
          Detalhes da compra
          <br><br>

          <div class="box-det-session"><span> Itens </span></div>
          <ul *ngIf="colDetPrd && colDetPrd.length !== 0">
            <li *ngFor="let item of colDetPrd">
              <div class="row box-det-row">
                <div class="col-md-12">
                  <div class="col-md-6 box-det-prd-lbl"><span> Produto: </span></div>
                  <div class="col-md-6 box-det-prd-spa"><span> {{item.prd}} </span></div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-6 box-det-prd-lbl"><span> Quantidade: </span></div>
                  <div class="col-md-6 box-det-prd-spa"><span> {{item.qtd}} </span></div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-6 box-det-prd-lbl"><span> Valor: </span></div>
                  <div class="col-md-6 box-det-prd-spa"><span> {{item.vlr}} </span></div>
                </div>
              </div> 
            </li>
          </ul>
          <br><br>

          <div class="box-det-session"><span> Pagamentos </span></div>
          <ul *ngIf="colResPgt && colResPgt.length !== 0">
            <li *ngFor="let item of colResPgt">
              <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12 box-det-prd-spa"><span> {{item.des}} </span></div>
                </div>
              </div> 
            </li>
          </ul>
          <br><br>
      </div>
    </section>

    <app-footer></app-footer>
</div>