<div class="crancy-body-area ">
  <app-menu></app-menu>
  <app-header></app-header>

  <section class="crancy-adashboard crancy-show">
    <div class="container">

      <!-- bloco de banner -->
      <div class="row">
        <div class="col-12 mg-top-30">
          <img src="assets/img/banner//{{pastaImagens}}/banner.png" style="border-radius: 10px;width: 100%;"/>
        </div>
      </div>

      <!-- bloco de titulos da pagina -->
      <div class="row" *ngIf="!minhasRenegociacoes">
        <div class="col-8 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <h4 class="crancy-sidebar__title">{{label.titulo}}</h4>
          </div>
        </div>
        <div class="col-4 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <div style="text-align: right;">
              <span class="span-minhas-reneg" (click)="minhasRenegAbreFecha()">{{label.lnk_minhasreneg}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- bloco controle do botão acesso minhas renegociações/voltar -->
      <div class="row" *ngIf="minhasRenegociacoes">
        <div class="col-9 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <h4 class="crancy-sidebar__title">{{label.titulo_minhareneg}}</h4>
          </div>
        </div>
        <div class="col-3 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <button class="btn btn-padrao" (click)="minhasRenegAbreFecha()">
              <i class='fa fa-window-close-o' style="font-size: 25px;"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- bloco para forçar footer -->
      <div class="mostra-vazio" *ngIf="mostrarVazio">
      </div>

      <!-- bloco de mensagem -->
      <div class="row" id="divMsgMaster" *ngIf="qtdTitRenegAberto>0">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-dsinner" id="divMsgContent">
              <div class="crancy-page-inner crancy-pricing mg-top-30 box-msg-super">
                <div class="box-msg-info">
                  <i class='fa fa-exclamation-triangle'></i>
                  {{label.msg_negocio_1}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- bloco de vencidos -->
      <div class="row" *ngIf="!minhasRenegociacoes">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-dsinner">

              <!-- lista de vencidos e seleção -->
              <div *ngFor="let itemNota of colNotas">
                <div class="crancy-page-inner crancy-pricing mg-top-30">
                  <div class="row">

                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col-3">
                          <div class="col text-strong"> {{label.lbl_numeronota}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col text-strong"> {{label.lbl_vencimento}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col text-strong"> {{label.lbl_vlroriginal}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col text-strong"> {{label.lbl_encargos}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col text-strong"> {{label.lbl_vlrfinal}} </div>
                        </div>
                        <div class="col-1">
                          <div class="col text-strong"> <input type="checkbox" style="display: none;" name="chkt_{{itemNota.E1_NUM}}" id="chkt_{{itemNota.E1_NUM}}" (click)="checkBoxTodos( $event, itemNota.E1_NUM )" > </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-12" *ngFor="let itemTitulo of itemNota.TITULOS">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_tit" value="{{itemTitulo.E1_NUM}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_fil" value="{{itemTitulo.E1_FILIAL}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_pfx" value="{{itemTitulo.E1_PREFIXO}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_tip" value="{{itemTitulo.E1_TIPO}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_vlr" value="{{itemTitulo.E1_SALDO}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_txp" value="{{itemTitulo.TX_PERMANECIA}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_vct" value="{{itemTitulo.E1_VENCTO}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_vaz" value="{{itemTitulo.E1_PARCELA_VAZIA}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_ems" value="{{itemTitulo.E1_EMISSAO}}" >
                        <input type="hidden" id="hdf_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}_loj" value="{{itemTitulo.E1_LOJA}}" >
                        <div class="col-3">
                          <div class="col"> {{itemTitulo.E1_NUM}} / {{itemTitulo.E1_PARCELA}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> {{itemTitulo.E1_VENCTO}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> R$ {{itemTitulo.E1_SALDO_CONVERTIDO}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> R$ {{itemTitulo.TX_PERMANECIA_CONVERTIDO}} </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> R$ {{itemTitulo.E1_SALDO_FINAL_CONVERTIDO}} </div>
                        </div>
                        <div class="col-1">
                          <div class="col" *ngIf="itemTitulo.PERMITEONLINE==true">
                            <input type="checkbox" name="chkp_{{itemTitulo.E1_NUM}}" id="chkp_{{itemTitulo.E1_NUM}}_{{itemTitulo.E1_PARCELA}}" (click)="checkBoxParcela( $event, itemTitulo.E1_NUM, itemTitulo.E1_PARCELA )">
                          </div>
                          <div class="col" *ngIf="itemTitulo.PERMITEONLINE==false">
                            <i class="fa-brands fa-whatsapp ico-whats-reneg"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-12" *ngIf="itemNota.baixardoc==true">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col-3">
                          <div class="col">
                            <button class="btn dropdown-toggle text-destaque text-strong" type="button" data-bs-toggle="dropdown" aria-expanded="false"> {{label.downloads}} </button>
                            <ul class="dropdown-menu">
                              <li><div class="dropdown-item" (click)="baixarDocumento(itemNota, 'NF')" > <i class="fa-solid fa-download"></i> {{label.baixarNfse}}</div></li>
                              <li><div class="dropdown-item" (click)="baixarSetCanhoto(itemNota)"             > <i class="fa-solid fa-download"></i> {{label.setCanhoto}}</div></li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="colg"> </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> </div>
                        </div>
                        <div class="col-2">
                          <div class="col"> </div>
                        </div>
                        <div class="col-1">
                          <div class="col"> </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- bloco de detalhemanto e prosseguir -->
              <div class="crancy-page-inner crancy-pricing mg-top-30" id="divTotal" style="display: none;">

                <div class="row">
                  <div class=" col-12">                      
                    <div class="crancy-section-title mg-btm-20low row ">
                      <div class="col-12 input-center">
                        <span class="span-tit-detalhamento">{{label.lbl_detalhamento}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class=" col-9">

                    <div class="row">
                      <div class=" col-12">                      
                        <div class="crancy-section-title mg-btm-20low row ">
                          <div class="col-1 input-center">
                            <span class="span-icon-totais">
                              <i class="fa-solid fa-list-check icon-totais"></i>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="col"> <input readonly type="text" class="input-totais" id="lblQtdTotal" > </div>
                          </div>
                          <div class="col-3">
                            <div class="col"> <input readonly type="text" class="input-totais input-right" id="lblVlrTotal" > </div>
                          </div>
                          <!-- <div class="col-3">
                            &nbsp;
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class=" col-12">
                        <div class="crancy-section-title mg-btm-20low row ">
                          <div class="col-1 input-center">
                            <span class="span-icon-totais">
                              <i class="fa-solid fa-calendar-days icon-totais"></i>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="col"> 
                              <input readonly type="text" class="input-totais" value="{{label.lbl_escolhavencimento}}">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="col">
                              <input type="date" class="input-totais input-right" id="inputDateVencimento" placeholder="" (change)="recalculaTitulos();" value="{{datVencimento}}">
                            </div>
                          </div>
                          <!-- <div class="col-3">
                            &nbsp;
                          </div> -->
                        </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class=" col-12">
                        <div class="crancy-section-title mg-btm-20low row ">
                          <div class="col-1 input-center">
                            <span class="span-icon-totais">
                              <i class="fa-solid fa-x icon-totais"></i>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="col"> 
                              <input readonly type="text" class="input-totais" value="{{label.lbl_escolhaparcelas}}">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="col">
                              <select class="input-totais input-right" id="selectNumeroParcelas" (change)="recalculaTitulos();" value="{{nroParcela}}">
                                <option value="1">1x</option>
                                <option value="2">2x</option>
                                <option value="3">3x</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="col-3">
                            &nbsp;
                          </div> -->
                        </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class=" col-12">                      
                        <div class="crancy-section-title mg-btm-20low row ">
                          <div class="col-1 input-center">
                            <span class="span-icon-totais">
                              <i class="fa-solid fa-money-bill-wave icon-totais"></i>
                            </span>
                          </div>
                          <div class="col-8">
                            <div class="col">
                              <input readonly type="text" class="input-totais" value="{{label.lbl_totalrecalculado}}">
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="col">
                              <input readonly type="text" class="input-totais input-right" id="lblVlrTotalRecalculado">
                            </div>
                          </div>
                          <!-- <div class="col-3">
                            &nbsp;
                          </div> -->
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class=" col-3" style="text-align: center;">
                    
                    <span>{{label.lbl_cliente}}</span>
                    <div class="div-info-1">
                      {{label.msg_info1_a}}<br>
                      {{label.msg_info1_b}} <i class='fa fa-thumbs-up' style="font-size: 19px;color:#777;"></i>
                    </div>

                  </div>
                </div>

                <br><br>
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                    <button class="btn btn-padrao" (click)="concluirRenegociacao()">
                      <i class="fa fa-handshake-o"></i> {{label.btn_concluir}}
                    </button>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- bloco de sucesso na renegociação -->
      <div class="row" *ngIf="minhasRenegociacoes">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-dsinner">

              <div class="crancy-page-inner crancy-pricing mg-top-30" *ngIf="colMinhasReneg.length>0">

                <div class="row">
                  <div class=" col-12 linha-dif-tot-reg">
                    <div class="crancy-section-title mg-btm-20low row ">
                      <div class="col-4">
                        <div class="col text-strong"> {{label.lbl_datareneg}} </div>
                      </div>
                      <div class="col-3">
                        <div class="col text-strong"> {{label.lbl_vencimento}} </div>
                      </div>
                      <div class="col-3">
                        <div class="col text-strong"> {{label.lbl_vlrfinal}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_sitreneg}} </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngFor="let itemNota of colMinhasReneg">
                  <div class=" col-12 linha-dif-tot-reg">
                    <div class="crancy-section-title mg-btm-20low row ">
                      <div class="col-4">
                        <div class="col"> {{itemNota.data_reneg}} {{itemNota.hora_reneg}} </div>
                      </div>
                      <div class="col-3">
                        <div class="col"> {{itemNota.vencimento}} </div>
                      </div>
                      <div class="col-3">
                        <div class="col"> {{itemNota.valor_final}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col">
                          <span *ngIf="itemNota.situacao==1" class="sps-minhasreneg-stsven">
                            {{label.lbl_vencido}}
                          </span>
                          <span *ngIf="itemNota.situacao==0" class="sps-minhasreneg-stspen">
                            {{label.lbl_pendente}}
                          </span>
                          <span *ngIf="itemNota.situacao==2" class="sps-minhasreneg-stspen">
                            {{label.lbl_pago}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" col-12 linha-dif-tot-reg" *ngIf="itemNota.situacao==0 && itemNota.pix_id.trim()!=''">
                    <div class="crancy-section-title mg-btm-20low row ">
                      <div class="col-4">
                        <button class="btn btn-padrao btn-down-sucess-reneg" (click)="baixarBoletoPendente(itemNota)">
                          <img class="img-down-sucess-reneg-bar" src="../../../assets/img/bar_icon_dark.png">
                        </button>
                        <br>
                        <span class="span-info-down-sucess">{{label.msg_sucesso_down}}</span>
                        <br>
                        <span class="span-info-down-sucess" style="color: indianred;">{{label.msg_sucesso_registrobanco}}</span>
                      </div>
                      <div class="col-4">
                        <button class="btn btn-padrao btn-down-sucess-reneg" (click)="copiarLinkPixPendente(itemNota.pix_cc)">
                          <img class="img-down-sucess-reneg-pix" src="../../../assets/img/pix_icon_dark.png">
                        </button>
                        <br>
                        <span class="span-info-down-sucess">{{label.msg_sucesso_lnk}}</span>
                      </div>
                      <div class="col-4">
                        <button class="btn btn-padrao btn-down-sucess-reneg" (click)="copiarLinkPixPendente(itemNota.pix_cc)">
                          <img class="img-down-sucess-reneg-qrc" src="{{itemNota.pix_qr}}">
                        </button>
                        <br>
                        <span class="span-info-down-sucess">{{label.msg_sucesso_qrc}}</span>
                      </div>
                      <div class="col-12">
                        <span class="span-info-down-sucess">{{label.msg_desconsiderar}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class=" col-12 div-tit-prcs-reneg">
                    <div class="crancy-section-title mg-btm-20low row div-lst-prcs-reneg">
                      <div class="col-1">
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_numeronota}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_vencimento}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_vlroriginal}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_encargos}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col text-strong"> {{label.lbl_vlrfinal}} </div>
                      </div>
                      <div class="col-1">
                      </div>
                    </div>
                  </div>
                  <div class=" col-12" *ngFor="let itemNota of colMinhasRenegT">                        
                    <div class="crancy-section-title mg-btm-20low row div-lst-prcs-reneg">
                      <div class="col-1">
                      </div>
                      <div class="col-2">
                        <div class="col"> {{itemNota.numerotitulo}} / {{itemNota.parcela}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col"> {{itemNota.vencimento}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col"> R$ {{itemNota.valor}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col"> R$ {{itemNota.tx_permanencia}} </div>
                      </div>
                      <div class="col-2">
                        <div class="col"> R$ {{itemNota.valor_final}} </div>
                      </div>
                      <div class="col-1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="divMinhasRenegSucesso" style="display: none;">
                <div class="crancy-page-inner crancy-pricing mg-top-30">
                  <div class="row">
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col-12">
                          {{label.msg_reneg_sucesso_tit}}&nbsp;&nbsp;{{label.msg_reneg_sucesso1}}                           
                          <br><br><br>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngFor="let itemPix of colTitulosPix">

                    <input type="hidden" id="hdf_renegociado_num_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.numerotitulo}}" >
                    <input type="hidden" id="hdf_renegociado_fil_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.filial}}" >
                    <input type="hidden" id="hdf_renegociado_pfx_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.prefixo}}" >
                    <input type="hidden" id="hdf_renegociado_cli_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.codigo}}" >
                    <input type="hidden" id="hdf_renegociado_loj_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.loja}}" >
                    <input type="hidden" id="hdf_renegociado_vlr_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.valor}}" >
                    <input type="hidden" id="hdf_renegociado_tip_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="{{itemPix.tipo}}" >

                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row " style="font-size: 20px;">
                        <div class="col-12">
                          {{itemPix.displaySucessoVlrDat}}
                        </div>
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">

                        <div class="col-4">
                          <button class="btn btn-padrao btn-down-sucess-reneg" (click)="baixarBoletoRenegociado( itemPix.numerotitulo, itemPix.parcela )">
                            <img class="img-down-sucess-reneg-bar" src="../../../assets/img/bar_icon_dark.png">
                          </button>
                          <br>
                          <span class="span-info-down-sucess">{{label.msg_sucesso_down}}</span>
                          <br>
                          <span class="span-info-down-sucess" style="color: indianred;">{{label.msg_sucesso_registrobanco}}</span>
                        </div>
                        <div class="col-4">
                          <button class="btn btn-padrao btn-down-sucess-reneg" (click)="copiarLinkPixRenegociado( itemPix.numerotitulo, itemPix.parcela )">
                            <img class="img-down-sucess-reneg-pix" src="../../../assets/img/pix_icon_dark.png">
                          </button>
                          <br>
                          <span class="span-info-down-sucess">{{label.msg_sucesso_lnk}}</span>
                          <input type="hidden" id="hdfLinkPix_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" value="">
                        </div>
                        <div class="col-4">
                          <button class="btn btn-padrao btn-down-sucess-reneg" (click)="copiarLinkPixRenegociado( itemPix.numerotitulo, itemPix.parcela )">
                            <img class="img-down-sucess-reneg-qrc" id="imgQrCodePix_{{itemPix.numerotitulo}}_{{itemPix.parcela}}" src="{{itemPix.pix_qr}}">
                          </button>
                          <br>
                          <span class="span-info-down-sucess">{{label.msg_sucesso_qrc}}</span>
                        </div>

                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="pix-pago" style="display:none;">
                            <i class="text-success fa-solid fa-circle-check" aria-hidden="true"></i>
                            <span class=" text-success f-span-info-f">Pagamento em PIX Aprovado. Aguarde alguns minutos e seu boleto será baixado no financeiro.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col-12" style="font-size: 13px;">
                          <br><br><br>
                          {{label.msg_reneg_sucesso2}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <app-footer></app-footer>

  <div class='modal modal-background-color' tabindex="-1" role="dialog" [ngStyle]="{'display':displayModalAceiteReneg}">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
        <div class='modal-content modal-content-detcli'>
            <div class='modal-header'>
                <div class="modal-header-titulo">
                  <h4 class='modal-title'>
                    <i class="fa-regular fa-circle-question fa-beat icon-question-modal"></i>
                    <span class="span-tit-modal">{{label.qst_concluir_title}}</span>
                </h4>
                </div>
            </div>

            <div class='modal-body'>
                <div class="step-inner-content">
                  <div class="info-produto mb-3 row">
                    <div class="text-left col-12 col-md-12 row">
                      <div class="div-modalbody-msg">
                        {{label.qst_concluir}}
                        <br>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class='modal-body' style="overflow-y: scroll; max-height: 300px;">
              <div class="step-inner-content">
                <div class="info-produto mb-3 row">
                  <div class="text-left col-12 col-md-12 row">
                    <div class="div-modalbody-msg" id="divAceito">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='modal-body'>
              <div class="step-inner-content">
                <div class="info-produto mb-3 row">
                  <div class="text-left col-12 col-md-12 row">
                    <div class="div-modalbody-msg">
                      <br>
                      {{label.qst_concluir2}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='modal-footer' id='buttons'>
              <div class="div-footer-modal">
                <span class="btn btn-padrao-modal" style="width: auto;" (click)="cancelar()">
                  Cancelar
                </span>

                <span class="btn btn-padrao-modal" style="width: auto;" (click)="renegociar()">
                  Aceitar e Prosseguir
                </span>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>