import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class LoginService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    validaLogin(login:string, senha:string, token:string):Observable<objRetorno>{

        var objBody =
        {
            "usr" : login,
            "psw" : senha,
            "token" : token
        }

        return this.http.post("login", objBody);
    }
}