<div class="crancy-wc__footer--top">
    <div class="crancy-wc__footer">
        <ul class="crancy-wc__footer--list list-none ">
            <li>
                <span class="span-footer-termos" (click)="abreModalTermo()">
                    {{label.termo}}
                </span>
            </li>
            <!-- <li><a href="#">{{label.privacidade}}</a></li> -->
            <!-- <li><a href="#">{{label.ajuda}}</a></li> -->
            <!-- <li>
                <select class="crancy-wc__footer--language change-language" (change)="lib.changeLanguage($event.target)"> 
                <option value="" disabled selected>{{label.idioma}}</option>           
                <option value="pt">Português</option>
                <option value='en'>English</option>
                <option value='es'>Español</option>
                </select>
            </li> -->
        </ul>
    </div>
    <p class="crancy-wc__footer--copyright">@ {{currentYear}} 
        <a routerLink="/home">
            Grupo Luiz Höhl.
        </a> 
        {{label.direitos}} 
    </p>
</div>

<div class='modal modal-background-color' tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleAceito}">
    <div class="modal-dialog modal-dialog-centered">
        <div class='modal-content modal-content-detcli'>
            <div class='modal-header'>
                <h4 class='modal-title'>
                    <span class="span-tit-modal"></span>
                </h4>
            </div>

            <div class='modal-body'>
                <div id="divTermoAceito" class="step-inner-content" style="padding-bottom: 0px;">
                </div>
            </div>

            <div class='modal-footer' id='buttons'>
                <div class="div-footer-modal">
                    <span class="span-close-modal" (click)="fecharModalTermo();">
                        FECHAR
                        <i class='fa fa-sign-out'></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
  </div>