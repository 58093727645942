import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class TitulosService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getRelacaoTitulos(cod_cliente:string):Observable<objRetorno>{
        return this.http.post("buscar-titulos", { "cliente" : cod_cliente} );
    }
    monitorarNF(numero_nfs:string, serie_nfs:string, filial:string):Observable<objRetorno>{
        return this.http.post("admin/WsMonitoraNF", { "NumNota": numero_nfs, "Serie"  : serie_nfs, "Filial" : filial, "Retransmitir": false} );
    }
    retornarSetCanhoto(chave:string):Observable<objRetorno>{
        return this.http.get("retornarSetCanhoto/" + chave);
    }
}