import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/services/menu/menu.service';
import { lib } from '../../shared/services/common/lib.service';
import * as translations from './menu.translate.json';

export interface interMenu
{
    cod : string;
    des : string;
    url : string;
    ico : string;
    ord : number;
    atv : boolean
};

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  translate: any = translations;
  label: any = {};
  msgsNumber: number = 1;
  colMenu: interMenu[];
  pastaImagens = lib.getSession('pastaImagens');

  constructor( private menuSRV: MenuService ) {
    this.label = lib.getTranslate(this.translate);
    this.colMenu = [];
  }

  ngOnInit(): void {
    const csAction = document.querySelectorAll('.crancy-smenu, .crancy-header, .crancy-adashboard');

    document.querySelectorAll('#crancy__sicon').forEach(button => {
      button.addEventListener('click', () => {
        csAction.forEach(el => {
          el.classList.toggle('crancy-close');
        });
        lib.setSession('iscicon', csAction[0].classList.contains('crancy-close'));
      });
    });

    if (lib.getSession('iscicon') === 'true') {
      csAction.forEach(el => {
        el.classList.add('crancy-close');
      });
    }

    this.buscarMenus();
  }

  buscarMenus(){

    //menuSRV
    this.menuSRV.getMenus("CF").subscribe(
      res => {
        let colObj = res.response.menus;
        for(var index = 0; index < colObj.length; index++){
          if(res.response.menus[index].atv == true){
            this.colMenu.push(
              {
                cod : res.response.menus[index].cod,
                des : this.label[res.response.menus[index].des],
                url : res.response.menus[index].url,
                ico : res.response.menus[index].ico,
                ord : res.response.menus[index].ord,
                atv : res.response.menus[index].atv,
              });
          }
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  sair() {
    lib.removeSession('user');
    lib.removeSession('iscicon');
    window.location.href = '/login';
  }
}