  <div class="body-bg">
    <section class="crancy-wc crancy-wc__full crancy-bg-cover" style="background-image: url('assets/img/credential-bg.svg');">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="crancy-wc__form">

              <div class="crancy-wc__form--middle">
                <div class="crancy-wc__banner">
                  <img src="assets/img/{{pastaImagens}}/tela-login.jpg" alt="Tela de Login">
                </div>

                <div class="crancy-wc__form-inner" *ngIf="box == '1'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.titulo}}</h3>
                  </div>
                  <div class="crancy-wc__form-main">
                    <div class="form-group">
                      <div class="form-group__input">
                        <input class="crancy-wc__form-input" type="text" name="user" placeholder="CPF/CNPJ" id="user"
                          required="required">
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group__input">
                        <input class="crancy-wc__form-input" id ="pass"
                          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"  type="password"required="required">
                        <span class="crancy-wc__toggle toggle-pass" (click)="togglePass('pass')"><i class="fas fa-eye"></i></span>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__check-inline">
                        <div class="crancy-wc__checkbox">
                          <input class="crancy-wc__form-check" id="checkbox" name="checkbox" type="checkbox">
                          <label for="checkbox">{{label.lembre}}</label>
                        </div>
                        <div class="crancy-wc__forgot">
                          <a href="esqueceu-senha" class="forgot-pass">{{label.esqueceu}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-mg-top25">
                      <div class="crancy-wc__button">
                        <button class="ntfmax-wc__btn" (click)="checkLogin()">{{label.entrar}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <a href="primeiro-acesso" class="forgot-pass">{{label.primeiroAcesso}}</a>
                  </div>
                  <div class="mt-4 row">
                    <!-- face, insta, linkedin, youtube -->
                    <div class="col-3 text-center">
                      <a href="{{linksRedes.facebook}}" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </div>
                    <div class="col-3 text-center">
                      <a href="{{linksRedes.instagram}}" target="_blank">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </div>
                    <div class="col-3 text-center">
                      <a href="{{linksRedes.linkedin}}" target="_blank">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                    <div class="col-3 text-center">
                      <a href="{{linksRedes.youtube}}" target="_blank">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- bloco de tipo de comunicação -->
                <div class="crancy-wc__form-inner" *ngIf="box == '2'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB2}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt2}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                        <label class="form-group crancy-wp__form--group" for="wayWhats">
                          <input class="radioButton" type="radio" id="wayWhats" name="wayContact" value="whats">
                          <label class="homec-form-label" for="wayWhats">
                            {{label.getSMS}}
                            <br>
                            <span>{{infoCliente.smsHidden}}</span>
                          </label>
                        </label>
                        <label class="form-group crancy-wp__form--group mg-top-20" for="wayEmail">
                          <input class="radioButton" type="radio" id="wayEmail" name="wayContact" value="email">
                          <label class="homec-form-label" for="wayEmail">
                            {{label.getEmail}}
                            <br>
                            <span>{{infoCliente.emailHidden}}</span>
                          </label>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="getWayContact()">
                          {{label.go}}
                          <span><i class="fa-solid fa-arrow-right"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                                <!-- bloco de confirmação de token -->
                <div class="crancy-wc__form-inner" *ngIf="box == '3'">
                  <div class="crancy-wc__heading">
                    <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB3}}</h3>
                    <p>{{label.txt1}}</p>
                  </div>
                  <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                    <h4 class="crancy-wc__form--inner--heading">{{label.txt4}}</h4>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                        <div class="form-group form-mg-top30">
                        <ul class="crancy-wc__form-verify">
                          <input id="code-verification-1" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-2" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-3" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                          <input id="code-verification-4" class="crancy-wc__form-input" type="text" name="text" placeholder="0"
                            required="required">
                        </ul>
                      </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="crancy-wc__button crancy-wc__button--initial">
                        <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="verifyCode()">
                          {{label.entrar}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-footer></app-footer>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>