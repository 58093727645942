<div class="crancy-body-area ">
  <app-menu></app-menu>
  <app-header></app-header>

  <section class="crancy-adashboard crancy-show">
    <div class="container">

    <div class="row">
      <div class="col-12 mg-top-30">
        <img src="assets/img/banner//{{pastaImagens}}/banner.png" style="border-radius: 10px;width: 100%;"/>
      </div>
    </div>
      <div class="row">
        <div class="col-10 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <h4 class="crancy-sidebar__title">{{label.titulo}}</h4>
          </div>
        </div>
        <div class="col-2 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <div class="div-filtro" (click)="dinamicaFiltros()">
              <span><i class="fa-solid fa-filter"></i></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-dsinner">

              <div *ngIf="mostrarFiltros==true">
                <div class="crancy-page-inner crancy-pricing" style="padding: 15px 10px 7px 30px ">
                  <div class="row">
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col row-div-filtro">
                          <div class="div-filtro-field-j" style="width: 25%;">
                            <label class="label-field-filtro">Emissão de</label>
                          </div>
                          <div class="div-filtro-field-j" style="width: 20%;">
                            <input class="input-field-filtro" type="date" id="inputFilEmissaoDe">
                          </div>
                          <div class="div-filtro-field-c" style="width: 15%;">
                            <label class="label-field-filtro">até</label>
                          </div>
                          <div class="div-filtro-field-j" style="width: 20%;">
                            <input class="input-field-filtro" type="date" id="inputFilEmissaoAte">
                          </div>
                        </div>
                      </div>
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col row-div-filtro">
                          <div class="div-filtro-field-j" style="width: 25%;">
                            <label class="label-field-filtro">NF(s) - separe com ';'</label>
                          </div>
                          <div class="div-filtro-field-j" style="width: 55%;">
                            <input class="input-field-filtro" type="text" id="inputFilNotas" placeholder="Ex: 123;456;789">
                          </div>
                        </div>
                      </div>
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col row-div-filtro">
                          <div class="div-filtro-field-j" style="width: 25%;">
                            <label class="label-field-filtro">Produto</label>
                          </div>
                          <div class="div-filtro-field-j" style="width: 55%;">
                            <input class="input-field-filtro" type="text" id="inputFilProduto" maxlength="50">
                          </div>
                          <div class="div-filtro-field-c" style="width: 10%;">
                            <span class="btn btn-success span-exec-filtro" (click)="buscarComFiltros()">
                              <i class="fa-solid fa-bolt"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngFor="let compra of minhasCompras">
                <div class="crancy-page-inner crancy-pricing mg-top-30">
                  <div class="row">
                    <div class=" col-12">
                      <div class="crancy-section-title mg-btm-20low row ">
                        <div class="col">
                          <div class="col text-strong"> {{label.numPedido}} </div>
                          <div class="col"> #{{compra.doc}} </div>
                          <div class="col text-purchase"> {{ (compra.ser=="U")?"NF de serviço":"NF de produto" }} </div>
                        </div>
                        <div class="col">
                          <div class="col text-strong"> {{label.data}} </div>
                          <div class="col"> {{compra.ems}} </div>
                          <div class="col text-purchase"> {{compra.origem}} </div>
                        </div>
                        <div class="col">
                          <button class="btn dropdown-toggle text-destaque text-strong" type="button" data-bs-toggle="dropdown" aria-expanded="false"> {{label.downloads}} </button>
                          <ul class="dropdown-menu">
                            <li><div class="dropdown-item" (click)="baixarDocumento(compra, null , 'NF')"> <i class="fa-solid fa-download"></i> {{label.baixarNfse}}</div></li>
                            <li><div class="dropdown-item" *ngIf="compra.temCanhoto" (click)="baixarSetCanhoto(compra)"> <i class="fa-solid fa-download"></i> {{label.setCanhoto}}</div></li>
                          </ul>
                        </div>
                        <button class=" btn col text-strong text-destaque" (click)="toggleDetalhes(compra)">
                          {{label.detalhePedido}} 
                          <span *ngIf="!compra.temAtraso">
                            <i id="itd-{{compra.orc}}" class="fa fa-angle-down"></i>
                            <i id="itu-{{compra.orc}}" class="fa fa-angle-up d-none"></i>
                          </span>
                          <i *ngIf="compra.temAtraso" class="fa-solid fa-circle-exclamation danger-atraso-icon"></i>
                        </button>
                      </div>
                      <div class="crancy-section-title mg-btm-0 d-none" id="box-detalhe-compra-{{compra.orc}}">
                        <hr>
                        <!-- info da compra -->
                        <div class="box-produtos row">
                          <div class="text-left col text-strong mb-3">{{label.prods}}</div>
                          <div class="text-end col text-strong">{{label.total}}</div>
                          <hr>
                          <div class="info-produto mb-3 row" *ngFor="let produto of compra.produtos">
                            <div class="text-left col-12 col-md-6 row">
                              <div class="text-left col-12 col-md-4 text box-desc-prod">
                                <img src="{{produto.img}}" alt="cpp" class="img-fluid">
                              </div>
                              <div class=" text-left col-12 col-md-8">
                                {{produto.descricao}}
                                <br>
                                {{label.qtd}}: {{produto.quantidade}}
                              </div>
                            </div>
                            <div class="text-end col-12 col-md-6">
                              R$ {{produto.valor}}
                            </div>
                          </div>
                          <div class="div-inf-sts-prds">
                            <span class="span-inf-sts-prds">{{compra.infProdutos}}</span>
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='1'">🙄</span> <!-- Pendente             -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='2'">😁</span> <!-- Separacao            -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='3'">😁</span> <!-- Conferencia          -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='4'">🤝</span> <!-- Entregue             -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='5'">😵</span> <!-- Req.Separacao        -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='6'">🤷‍</span> <!-- Separacao Divergente -->
                            <span class="span-ico-sts-prd" *ngIf="compra.stsProdutos=='7'">🤝</span> <!-- Cliente Retirar      -->                           
                          </div>
                        </div>
                        <hr>
                        <!-- informações do pagamento -->
                        <div class="box-pagamentos row" *ngIf="compra.pagamentos.length>0">
                          <div class="text-left col text-strong mb-3">{{label.pags}}</div>
                          <hr>
                          <div class="info-produto text-left mb-3" *ngFor="let pagamento of compra.pagamentos">
                            <div class="row">
                              <div class="text-left col-12 col-md-3 col-sm-6">
                                {{pagamento.descricao}}
                              </div>
                              <div class="text-left col-12 col-md-3 col-sm-6">
                                {{pagamento.vencimento}}
                              </div>
                              <div class="text-left col-12 col-md-3 col-sm-6">
                                <span  class="col-12 col-sm-6" *ngIf="pagamento.total_parcelas!='x'">
                                  {{pagamento.parcela}}/{{pagamento.total_parcelas}} - R${{pagamento.valor}}
                                </span>
                                <span  class="col-12 col-sm-6" *ngIf="pagamento.total_parcelas=='x'">
                                  R${{pagamento.valor}} em {{pagamento.parcela}}x
                                </span>
                              </div>
                              <div class="text-end row col-12 col-md-3 col-sm-6 justify-content-between">
                                <p *ngIf="pagamento.status == 'pago'" class="col-12  crancy-table__status">
                                  <!-- <i class="fa-solid fa-circle-check"></i> -->
                                  {{label.pago}}
                                </p>
                                <p *ngIf="pagamento.status == 'pendente' && pagamento.tipo !='BL'" class="col-12 crancy-table__status crancy-table__status--paid">
                                  {{label.avencer}}
                                </p>
                                <p *ngIf="pagamento.status == 'pendente' && pagamento.tipo =='BL'" class="col-6 crancy-table__status crancy-table__status--paid">
                                  {{label.avencer}}
                                </p>
                                <p *ngIf="pagamento.status == 'pendente' && pagamento.tipo =='BL'" class=" col-5 crancy-table__status crancy-table__status--paid" (click)="baixarDocumento(compra, pagamento, 'BL')">
                                  <i class="fa-solid fa-download" ></i>
                                </p>
                                <p *ngIf="pagamento.status == 'atrasado'" class="col-12 crancy-table__status crancy-table__status--unpaid">
                                  {{label.atrasado}}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr>
                        <!-- informações adicionais -->
                        <div class="box-pagamentos row">
                          <div class="text-left col text-strong mb-3">{{label.infoAdd}}</div>
                          <hr>
                          <div class="info-produto text-left mb-3">
                            <div class="row">
                              <div class="text-left col-12 text">
                                {{compra.infoAdd}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr>
                        <!-- info dos valores -->
                        <div class="row">
                          <div class="col-6 text-left text-strong">
                            {{label.subtotal}}
                          </div>
                          <div class="col-6 text-end text-strong">
                            R$ {{compra.subtotal}}
                          </div>
                          <div class="col-6 text-left text-success text-strong">
                            {{label.desconto}}
                          </div>
                          <div class="col-6 text-end text-success text-strong">
                            R$ {{compra.desconto}}
                          </div>
                          <div class="col-6 text-left">
                            {{label.frete}}
                          </div>
                          <div class="col-6 text-end">
                            R$ {{compra.frete}}
                          </div>
                          <hr class="mt-3">
                          <div class="col-6 text-left text-strong text-destaque">
                            {{label.totalPedido}}
                          </div>
                          <div class="col-6 text-end text-strong text-destaque">
                            R$ {{compra.total}}
                          </div>
                        </div>

                        <div class="d-flex justify-content-end mt-5">
                          <button class="btn btn-padrao" (click)="solicitarDevTro( compra.doc, compra.ser )"> <i class="fa fa-people-carry-box"></i> Devolução/Troca</button>
                          <button class="btn btn-padrao" (click)="baixarDocumento(compra, null, 'NF')"> <i class="fa-solid fa-download"></i> {{label.baixarNfse}}</button>
                          <button class="btn btn-padrao" *ngIf="compra.temCanhoto" (click)="baixarSetCanhoto(compra)"> <i class="fa-solid fa-download"></i> {{label.setCanhoto}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="mt-5 box-mais" *ngIf="abrirMais == true">
              <p class="btn-mais" (click)=" buscarMaisCompras()" >
                {{label.vermais}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>

  <div class='modal modal-background-color' tabindex="-1" role="dialog" [ngStyle]="{'display':displayModalDevTro}">
    <div class="modal-dialog modal-dialog-centered">
        <div class='modal-content modal-content-detcli'>
            <div class='modal-header'>
                <h4 class='modal-title'>
                    <input type="hidden" id="hdnNumeroSerie" value="">
                    <span class="span-tit-modal"></span>
                    <br>
                    <span class="span-tit2-modal" id="spanTit2"></span>
                </h4>
            </div>

            <div class='modal-body' *ngIf="stepDevTro==1">
                <div id="divFormulario" class="step-inner-content" style="padding-bottom: 0px;">
                  <div class="info-produto mb-3 row" *ngFor="let produto of produtosDispDevTro.produtos">
                    <div class="text-left col-10 col-md-10 row">
                      <div class="text-left col-4 col-md-4 text box-desc-prod">
                        <img src="{{produto.img}}" alt="cpp" class="img-fluid">
                      </div>
                      <div class="text-left col-8 col-md-8 div-label-prd">
                        {{produto.descricao}}
                      </div>
                    </div>
                    <div class="text-left col-2 col-md-2 row form-check form-switch">
                      <div class="div-lst-prd div-label-chk">
                        <label class="crancy__item-switch">
                          <input type="checkbox"
                                 name="produtosDevTro"
                                 id="chk_{{produto.codigo}}"
                                 (click)="mostrarFormulario( produtosDispDevTro.doc, produtosDispDevTro.ser, produto.codigo )">
                          <span class="crancy__item-switch--slide crancy__item-switch--round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="text-left col-12 col-md-12 row" style="display: none;" id="divDevTro_{{produto.codigo}}">
                      <div class="row">
                        <div class="col-8 col-md-8">
                          <label class="label-default-modal">{{label.modal_lbl_tipsol}} <span class="modal-required">*</span></label>
                          <select class="input-default-modal" id="selTipDevTro_{{produto.codigo}}">
                            <option value=""  >{{label.modal_opt_selecione}}</option>
                            <option value="D" >{{label.modal_opt_devolucao}}</option>
                            <option value="T" >{{label.modal_opt_troca}}</option>
                          </select>
                        </div>
                        <div class="col-4 col-md-4">
                          <label class="label-default-modal">{{label.modal_lbl_qtdprd}} <span class="modal-required">*</span></label>
                          <select class="input-default-modal" id="selQtdDevTro_{{produto.codigo}}">
                            <option value=""  >{{label.modal_opt_selecione}}</option>
                            <option *ngFor="let qtd of produto.qtdDevTro" value="{{qtd}}" >{{qtd}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_motsol}} <span class="modal-required">*</span></label>
                          <select class="input-default-modal" id="selMotDevTro_{{produto.codigo}}">
                            <option value=""  >{{label.modal_opt_selecione}}</option>
                            <option value="1" >{{label.modal_opt_arrependimento}}</option>
                            <option value="2" >{{label.modal_opt_atrasonaentrega}}</option>
                            <option value="3" >{{label.modal_opt_recebiproderrado}}</option>
                            <option value="4" >{{label.modal_opt_pedidoincompleto}}</option>
                            <option value="5" >{{label.modal_opt_naogosteiqualidade}}</option>
                            <option value="6" >{{label.modal_opt_naogosteiproduto}}</option>
                            <option value="7" >{{label.modal_opt_produtocomdefeito}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_dessol}}</label>
                          <textarea class="input-default-modal" id="txtDesDevTro_{{produto.codigo}}" style="height: 50px;" maxlength="250"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <div class='modal-body' *ngIf="stepDevTro==2">
                <div id="divEndereco" class="step-inner-content" style="padding-bottom: 0px;">
                  <div class="info-endereco mb-3 row">
                    <div class="text-left col-12 col-md-12 row">

                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_cep}}</label>
                          <input class="input-default-modal" type="text" id="txtCep" value="{{enderecoDevTro.cep}}" readonly>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8 col-md-8">
                          <label class="label-default-modal">{{label.modal_lbl_cid}}</label>
                          <input class="input-default-modal" type="text" id="txtCid" value="{{enderecoDevTro.municipio}}" readonly>
                        </div>
                        <div class="col-4 col-md-4">
                          <label class="label-default-modal">{{label.modal_lbl_est}}</label>
                          <input class="input-default-modal" type="text" id="txtEst" value="{{enderecoDevTro.estado}}" readonly>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_bai}}</label>
                          <input class="input-default-modal" type="text" id="txtBai" value="{{enderecoDevTro.bairro}}" readonly>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_lgr}}/{{label.modal_lbl_nro}}</label>
                          <input class="input-default-modal" type="text" id="txtLgr" value="{{enderecoDevTro.endereco}}" readonly>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12">
                          <label class="label-default-modal">{{label.modal_lbl_com}}</label>
                          <textarea class="input-default-modal" id="txtCom" style="height: 50px;" maxlength="250">{{enderecoDevTro.complemento}}</textarea>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>

            <div class='modal-footer' id='buttons'>
              <div class="div-footer-modal">
                <span class="btn btn-padrao" style="width: auto;" (click)="voltarStepDevPro()" *ngIf="selecinouPrdDevTro==true && stepDevTro>1">
                  <i class='fa fa-arrow-left'></i>
                  {{label.modal_btn_voltar}}
                </span>
                
                <span class="btn btn-padrao" style="width: auto;" (click)="continuarStepDevPro()" *ngIf="selecinouPrdDevTro==true">
                  {{label.modal_btn_continuar}}
                  <i class='fa fa-arrow-right'></i>
                </span>

                <span class="btn btn-padrao" style="width: auto;" (click)="fecharModal()">
                  {{label.modal_btn_fechar}}
                  <i class='fa fa-sign-out'></i>
                </span>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>