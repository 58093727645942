<div class="crancy-body-area ">
  <app-menu></app-menu>
  <app-header></app-header>

  <section class="crancy-adashboard crancy-show">
    <div class="container">
      <div class="row">
        <div class="col-12 mg-top-30">
          <div class="crancy-pbreadcrumb">
            <h4 class="crancy-sidebar__title">{{label.titulo}}</h4>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="box == '1'">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-dsinner">
              <div class="crancy-personals">
                <div class="row">

                  <div class="col-lg-3 col-md-2 col-12 crancy-personals__list mg-top-30">
                    <div class="crancy-psidebar">

                      <h2 class="crancy-psidebar__heading">{{label.lbl_Opcoes}}</h2>
                      <div class="list-group crancy-psidebar__list" id="list-tab" role="tablist">
                        <a class="list-group-item active" data-bs-toggle="list" href="#idIP" role="tab">
                          <span class="crancy-psidebar__icon">
                            <i class="fas fa-user"></i>
                          </span>
                          <span class="crancy-psidebar__title">{{label.lbl_InfPes}}</span>
                        </a>
                        <a class="list-group-item" data-bs-toggle="list" href="#idCN" role="tab">
                          <span class="crancy-psidebar__icon">
                            <i class="fas fa-bell"></i>
                          </span>
                          <span class="crancy-psidebar__title">{{label.lbl_ConNot}}</span>
                        </a>
                        <a class="list-group-item" data-bs-toggle="list" href="#idAS" role="tab">
                          <span class="crancy-psidebar__icon">
                            <i class="fas fa-lock"></i>
                          </span>
                          <span class="crancy-psidebar__title">{{label.lbl_AltSen}}</span>
                        </a>
                        <a class="list-group-item" data-bs-toggle="list" href="#idTL" role="tab" (click)="getTimeline();">
                          <span class="crancy-psidebar__icon">
                            <i class="fas fa-clock-rotate-left"></i>
                          </span>
                          <span class="crancy-psidebar__title">{{label.lbl_TimLine}}</span>
                        </a>
                      </div>

                    </div>
                  </div>
                  
                  <div class="col-lg-9 col-md-10 col-12 crancy-personals__content mg-top-30">
                    <div class="crancy-ptabs">
                      <div class="crancy-ptabs__inner">
                        <div class="tab-content" id="nav-tabContent">

                          <div class="tab-pane fade show active" id="idIP" role="tabpanel">
                            <div action="#">
                              <div class="row">
                                <div class="col-12">
                                  <div class="crancy-ptabs__form-main">
                                    <div class="crancy__item-group">
                                      <h4 class="crancy__item-group__title">{{label.lbl_InfPes_titulo}}</h4>

                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-12 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_nome}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesNome" maxlength="40" required="required">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-12 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_razaosocial}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesRazao" maxlength="20" required="required">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_datanascimento}}*</label>
                                              <input class="crancy__item-input" type="date" id="inputInfPesNascimento" required="required">
                                            </div>
                                          </div>
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_genero}}*</label>
                                              <select class="form-select crancy__item-input" aria-label="Default select example" id="selectInfPesGenero" required="required">
                                                <option value="0" selected>{{label.lbl_InfPes_genero0}}</option>
                                                <option value="1">{{label.lbl_InfPes_genero1}}</option>
                                                <option value="2">{{label.lbl_InfPes_genero2}}</option>
                                                <option value="9">{{label.lbl_InfPes_genero9}}</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_email}}*</label>
                                              <input class="crancy__item-input" type="email" id="inputInfPesEmail" required="required">
                                            </div>
                                          </div>
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_telefone}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesFone" required="required">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="crancy-ptabs__form-main">
                                    <div class="crancy__item-group">
                                      <h4 class="crancy__item-group__title">{{label.lbl_InfPes_endereco}}</h4>

                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-4 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_cep}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriCep" (blur)="buscarCep('ori')" required="required">
                                            </div>
                                          </div>
                                          <div class="col-lg-3 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_est}}*</label>
                                              <!-- <input class="crancy__item-input" type="text" id="inputInfPesEndOriEst" required="required"> -->
                                              <select class="form-select crancy__item-input" aria-label="Default select example" id="inputInfPesEndOriEst" required="required">
                                                <option value="" selected>{{label.lbl_InfPes_end_selest}}</option>
                                                <option value="AC">AC-Acre</option>
                                                <option value="AL">AL-Alagoas</option>	
                                                <option value="AP">AP-Amapá</option>
                                                <option value="AM">AM-Amazonas</option>
                                                <option value="BA">BA-Bahia</option>
                                                <option value="CE">CE-Ceará</option>
                                                <option value="DF">DF-Brasília</option>
                                                <option value="ES">ES-Espírito Santo</option>	
                                                <option value="GO">GO-Goiás</option>
                                                <option value="MA">MA-Maranhão</option>
                                                <option value="MT">MT-Mato Grosso</option>	
                                                <option value="MS">MS-Mato Grosso do Sul</option>	
                                                <option value="MG">MG-Minas Gerais</option>
                                                <option value="PA">PA-Pará</option>
                                                <option value="PB">PB-Paraíba</option>	
                                                <option value="PR">PR-Paraná</option>	
                                                <option value="PE">PE-Pernambuco</option>	
                                                <option value="PI">PI-Piauí</option>
                                                <option value="RJ">RJ-Rio de Janeiro</option>	
                                                <option value="RN">RN-Rio Grande do Norte</option>	
                                                <option value="RS">RS-Rio Grande do Sul</option>
                                                <option value="RO">RO-Rondônia</option>
                                                <option value="RR">RR-Roraima</option>	
                                                <option value="SC">SC-Santa Catarina</option>	
                                                <option value="SP">SP-São Paulo</option>
                                                <option value="SE">SE-Sergipe</option>	
                                                <option value="TO">TO-Tocantins</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div class="col-lg-5 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_mun}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriMun" required="required">
                                              <input type="hidden" id="hdfInfPesOriIbge" value="">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-8 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_lgr}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriLgr" required="required">
                                            </div>
                                          </div>
                                          <div class="col-lg-4 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_nro}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriNro" required="required">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_bai}}*</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriBai" required="required">
                                            </div>
                                          </div>
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy__item-form--group  mg-top-form-20">
                                              <label class="crancy__item-label">{{label.lbl_InfPes_end_com}}</label>
                                              <input class="crancy__item-input" type="text" id="inputInfPesEndOriCom">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="crancy-ptabs__form-main">
                                    <div class="crancy__item-group">
                                      <h4 class="crancy__item-group__title">{{label.lbl_InfPes_enderecoent}}</h4>

                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-12 col-12">
                                            <div style="text-align: right;padding-bottom: 10px;padding-top: 10px;">
                                              <span style="cursor: pointer;" (click)="changeCheckbox('chkConsideraEndOri');">
                                                Considerar endereço residencial&nbsp;&nbsp;
                                              </span>
                                              <div style="float: right;padding-top: 3px;padding-left: 3px;">
                                                <input type="checkbox" id="chkConsideraEndOri" (change)="changeConsidera();">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div *ngIf="consideraEndOri==false">
                                        <div class="crancy__item-form--group">
                                          <div class="row">
                                            <div class="col-lg-4 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_cep}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntCep" (blur)="buscarCep('ent')">
                                              </div>
                                            </div>
                                            <div class="col-lg-3 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_est}}</label>
                                                <!-- <input class="crancy__item-input" type="text" id="inputInfPesEndEntEst"> -->
                                                <select class="form-select crancy__item-input" aria-label="Default select example" id="inputInfPesEndEntEst">
                                                  <option value="" selected>{{label.lbl_InfPes_end_selest}}</option>
                                                  <option value="AC">AC-Acre</option>
                                                  <option value="AL">AL-Alagoas</option>	
                                                  <option value="AP">AP-Amapá</option>
                                                  <option value="AM">AM-Amazonas</option>
                                                  <option value="BA">BA-Bahia</option>
                                                  <option value="CE">CE-Ceará</option>
                                                  <option value="DF">DF-Brasília</option>
                                                  <option value="ES">ES-Espírito Santo</option>	
                                                  <option value="GO">GO-Goiás</option>
                                                  <option value="MA">MA-Maranhão</option>
                                                  <option value="MT">MT-Mato Grosso</option>	
                                                  <option value="MS">MS-Mato Grosso do Sul</option>	
                                                  <option value="MG">MG-Minas Gerais</option>
                                                  <option value="PA">PA-Pará</option>
                                                  <option value="PB">PB-Paraíba</option>	
                                                  <option value="PR">PR-Paraná</option>	
                                                  <option value="PE">PE-Pernambuco</option>	
                                                  <option value="PI">PI-Piauí</option>
                                                  <option value="RJ">RJ-Rio de Janeiro</option>	
                                                  <option value="RN">RN-Rio Grande do Norte</option>	
                                                  <option value="RS">RS-Rio Grande do Sul</option>
                                                  <option value="RO">RO-Rondônia</option>
                                                  <option value="RR">RR-Roraima</option>	
                                                  <option value="SC">SC-Santa Catarina</option>	
                                                  <option value="SP">SP-São Paulo</option>
                                                  <option value="SE">SE-Sergipe</option>	
                                                  <option value="TO">TO-Tocantins</option>
                                                </select>
                                              </div>
                                            </div>
                                            <div class="col-lg-5 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_mun}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntMun">
                                                <input type="hidden" id="hdfInfPesEntIbge" value="">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="crancy__item-form--group">
                                          <div class="row">
                                            <div class="col-lg-8 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_lgr}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntLgr">
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_nro}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntNro">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="crancy__item-form--group">
                                          <div class="row">
                                            <div class="col-lg-6 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_bai}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntBai">
                                              </div>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                              <div class="crancy__item-form--group  mg-top-form-20">
                                                <label class="crancy__item-label">{{label.lbl_InfPes_end_com}}</label>
                                                <input class="crancy__item-input" type="text" id="inputInfPesEndEntCom">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="crancy-ptabs__form-main">
                                    <div class="crancy__item-group">
                                      <h4 class="crancy__item-group__title">Imagem de perfil</h4>

                                      <div class="crancy__item-form--group">
                                        <div class="row">
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy-ptabs__sheading">
                                              <p class="crancy-ptabs__stext">
                                                {{label.msg_InfPes_tamimg}} <b>300x300.</b>
                                                <!-- <br>
                                                Gifs work too. <b>Max 5mb.</b> -->
                                              </p>
                                            </div>
                                            <div class="crancy-ptabs__sauthor-img crancy-ptabs__pthumb" style="padding-top: 30px;">
                                              <label class="crancy-ptabs__sedit" for="file-input">
                                                <span>
                                                  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                                                      fill="white">
                                                    </path>
                                                    <path d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                                                      fill="white">
                                                    </path>
                                                  </svg>
                                                </span>
                                              </label>
                                              <input id="file-input" type="file" (change)="getBase64()" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                                            </div>
                                          </div>
                                          <div class="col-lg-6 col-12">
                                            <div class="crancy-ptabs__sauthor">
                                              <img id="imgPreview" src="">
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="crancy__item-button--group crancy__item-button--group--fix crancy__ptabs-bottom">
                                <button class="crancy-btn crancy-color8__bg" (click)="Prosseguir()">{{label.btn_salvar}}</button>
                              </div>
                            </div>
                          </div>

                          <div class="tab-pane fade" id="idCN" role="tabpanel">
                            <form action="#">
                              <div class="crancy-paymentm crancy__item-group">
                                <h4 class="crancy__item-group__title mg-btm-20">{{label.lbl_ConNot_titulo}}</h4>

                                <ul class="crancy-paymentm__list crancy-paymentm__list--notify pt-0">
                                  <li class="crancy-paymentm__single crancy-paymentm__single--notify">
                                    <div class="crancy-paymentm__name">
                                      <div class="crancy-paymentm__icon crancy-paymentm__icon--notify ntfmax__bgc--1">
                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="30" cy="30" r="30" fill="#0A82FD" />
                                          <path
                                            d="M29.9703 43.9959C27.4494 43.9959 24.9286 44.0078 22.4077 43.9919C20.2721 43.9799 19.0338 42.7656 19.0197 40.6347C18.9955 37.2735 18.9915 33.9123 19.0197 30.5511C19.0378 28.4521 20.3265 27.2158 22.4481 27.2118C27.4212 27.2019 32.3964 27.2039 37.3695 27.2118C39.604 27.2158 40.8564 28.4641 40.8644 30.6909C40.8745 33.9862 40.8786 37.2815 40.8644 40.5767C40.8544 42.7616 39.6242 43.9819 37.43 43.9939C34.9434 44.0059 32.4569 43.9959 29.9703 43.9959ZM27.5321 33.9722C27.6108 34.1699 27.6592 34.789 27.9919 35.0466C28.8954 35.7476 28.7542 36.6544 28.7563 37.567C28.7563 37.9984 28.6272 38.5057 28.8107 38.8412C29.0467 39.2706 29.5367 39.8358 29.9219 39.8398C30.3131 39.8438 30.8496 39.3026 31.0613 38.8752C31.261 38.4698 31.1319 37.9006 31.1319 37.4053C31.1319 36.5525 31.0936 35.7716 31.868 35.0706C32.6041 34.4036 32.4871 33.1354 31.8841 32.3086C31.2811 31.4817 30.2002 31.1262 29.2019 31.4258C28.2521 31.7114 27.5483 32.6621 27.5321 33.9722Z"
                                            fill="white" />
                                          <path
                                            d="M37.5315 26.2691C36.3356 26.2691 35.2466 26.2691 34.0345 26.2691C34.0345 25.4044 34.0446 24.5856 34.0325 23.7647C33.9982 21.3202 32.2397 19.4709 29.9467 19.4589C27.6497 19.4469 25.8851 21.2803 25.8448 23.7368C25.8306 24.5596 25.8427 25.3844 25.8427 26.2672C24.6448 26.2672 23.5558 26.2672 22.4729 26.2672C21.7126 21.3322 23.5054 17.6674 27.7546 16.3473C32.5603 14.8535 37.3984 18.3624 37.5274 23.3493C37.5516 24.304 37.5315 25.2606 37.5315 26.2691Z"
                                            fill="white" />
                                        </svg>
                                      </div>
                                      <div class="crancy-paymentm__content">
                                        <h4 class="crancy-paymentm__title crancy-paymentm__title--notify">{{label.lbl_ConNot_todas}}</h4>
                                        <p class="crancy-paymentm__text crancy-paymentm__text--notify">{{label.lbl_ConNot_todas_desc}}</p>
                                      </div>
                                    </div>
                                    <div class="crancy-ptabs__notify-switch  crancy-ptabs__notify-switch--two">
                                      <label class="crancy__item-switch">
                                        <input type="checkbox" id="checkTD"  name="optNotif" (change)="alterarOpcaoNotificacao('TD')" checked>
                                        <span class="crancy__item-switch--slide crancy__item-switch--round"></span>
                                      </label>
                                    </div>
                                  </li>
                                  <li class="crancy-paymentm__single crancy-paymentm__single--notify">
                                    <div class="crancy-paymentm__name">
                                      <div class="crancy-paymentm__icon crancy-paymentm__icon--notify ntfmax__bgc--4">
                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="30" cy="30" r="30" fill="#EF5DA8" />
                                          <path
                                            d="M26.6593 16.1563C28.3179 16.165 29.6518 16.3439 30.8831 16.9656C33.0228 18.0459 34.1639 19.8365 34.4921 22.1742C34.7023 23.6678 34.5338 25.144 34.176 26.5977C33.7436 28.3484 33.16 30.0452 32.2326 31.603C31.6404 32.5982 30.904 33.4701 29.9106 34.0936C28.0678 35.252 25.8396 35.1339 24.0264 33.7931C22.9322 32.9838 22.1559 31.9157 21.5428 30.719C20.6658 29.0031 20.1743 27.1656 19.8356 25.2795C19.6428 24.2079 19.5768 23.1381 19.7505 22.0595C20.1986 19.2668 21.8103 17.4658 24.4502 16.5401C25.2612 16.2553 26.0984 16.1372 26.6593 16.1563Z"
                                            fill="white" />
                                          <path
                                            d="M36.2459 45.0001C36.166 45.0001 36.0982 45.0001 36.0288 45.0001C28.7239 45.0001 21.4191 45.0001 14.1143 45.0001C13.8538 45.0001 13.8451 45.0001 13.8468 44.7448C13.8538 43.6993 13.8382 42.6503 13.8833 41.6065C13.9319 40.5227 14.4929 39.696 15.3352 39.0482C16.1689 38.4091 17.131 38.0409 18.1227 37.7352C20.1061 37.1239 22.1451 36.8095 24.2066 36.6324C26.0059 36.4778 27.8087 36.4709 29.6114 36.5681C30.0977 36.5942 30.5805 36.6567 31.0668 36.688C31.2388 36.7001 31.2787 36.7678 31.2787 36.9328C31.2735 38.5498 31.6243 40.0816 32.4493 41.4832C33.3125 42.9507 34.516 44.0605 36.0288 44.8403C36.093 44.8733 36.1573 44.9046 36.2215 44.9393C36.2302 44.9463 36.2302 44.9619 36.2459 45.0001Z"
                                            fill="white" />
                                          <path
                                            d="M33.9961 35.6042C34.0881 35.6042 34.1663 35.6042 34.2444 35.6042C35.4098 35.6042 36.5734 35.6042 37.7388 35.6042C37.9993 35.6042 38.0011 35.6042 38.0011 35.3332C38.0011 34.1678 38.0011 33.0042 38.0011 31.8389C38.0011 31.7624 38.0011 31.6843 38.0011 31.5957C39.1473 31.5957 40.2675 31.5957 41.4155 31.5957C41.4155 32.9295 41.4155 34.2529 41.4155 35.6024C42.7667 35.6024 44.0902 35.6024 45.4257 35.6024C45.4257 36.7296 45.4257 37.8324 45.4257 38.9596C44.0954 38.9596 42.7702 38.9596 41.4173 38.9596C41.4173 40.316 41.4173 41.6463 41.4173 42.9906C41.327 42.9958 41.2627 43.0028 41.1984 43.0028C40.205 43.0028 39.2116 42.9993 38.2182 43.008C38.0445 43.0097 37.9959 42.9611 37.9959 42.7874C38.0028 41.6029 37.9993 40.4185 37.9993 39.234C37.9993 39.1558 37.9993 39.0777 37.9993 38.9717C36.6568 38.9717 35.3334 38.9717 33.9961 38.9717C33.9961 37.8394 33.9961 36.7383 33.9961 35.6042Z"
                                            fill="white" />
                                        </svg>
                                      </div>
                                      <div class="crancy-paymentm__content">
                                        <h4 class="crancy-paymentm__title crancy-paymentm__title--notify">{{label.lbl_ConNot_cliente}}</h4>
                                        <p class="crancy-paymentm__text crancy-paymentm__text--notify">{{label.lbl_ConNot_cliente_desc}}</p>
                                      </div>
                                    </div>
                                    <div class="crancy-ptabs__notify-switch  crancy-ptabs__notify-switch--two">
                                      <label class="crancy__item-switch">
                                        <input type="checkbox" id="checkCL" name="optNotif" (change)="alterarOpcaoNotificacao('CL')" checked>
                                        <span class="crancy__item-switch--slide crancy__item-switch--round"></span>
                                      </label>
                                    </div>
                                  </li>
                                  <li class="crancy-paymentm__single crancy-paymentm__single--notify">
                                    <div class="crancy-paymentm__name">
                                      <div class="crancy-paymentm__icon crancy-paymentm__icon--notify ntfmax__bgc--4">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                                          <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                            <path d="M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 67.511 58.015 c 0 1.8 -1.46 3.26 -3.26 3.26 H 25.749 c -1.8 0 -3.26 -1.46 -3.26 -3.26 V 39.692 L 45 47.34 l 22.511 -7.647 V 58.015 z M 67.511 35.013 L 45 42.66 l -22.511 -7.647 v -3.028 c 0 -1.8 1.46 -3.26 3.26 -3.26 h 38.501 c 1.8 0 3.26 1.46 3.26 3.26 V 35.013 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255, 0, 0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                          </g>
                                        </svg>
                                      </div>
                                      <div class="crancy-paymentm__content">
                                        <h4 class="crancy-paymentm__title crancy-paymentm__title--notify">{{label.lbl_ConNot_email}}</h4>
                                        <p class="crancy-paymentm__text crancy-paymentm__text--notify">{{label.lbl_ConNot_email_desc}}</p>
                                      </div>
                                    </div>
                                    <div class="crancy-ptabs__notify-switch  crancy-ptabs__notify-switch--two">
                                      <label class="crancy__item-switch">
                                        <input type="checkbox" id="checkEM" name="optNotif" (change)="alterarOpcaoNotificacao('EM')" checked>
                                        <span class="crancy__item-switch--slide crancy__item-switch--round"></span>
                                      </label>
                                    </div>
                                  </li>
                                  <li class="crancy-paymentm__single crancy-paymentm__single--notify">
                                    <div class="crancy-paymentm__name">
                                      <div class="crancy-paymentm__icon crancy-paymentm__icon--notify ntfmax__bgc--4">
                                        <svg fill="#23d72f" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xml:space="preserve" stroke="#23d72f">
                                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                          <g id="SVGRepo_iconCarrier">
                                            <g id="XMLID_468_">
                                              <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"></path> <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"></path>
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div class="crancy-paymentm__content">
                                        <h4 class="crancy-paymentm__title crancy-paymentm__title--notify">{{label.lbl_ConNot_whats}}</h4>
                                        <p class="crancy-paymentm__text crancy-paymentm__text--notify">{{label.lbl_ConNot_whats_desc}}</p>
                                      </div>
                                    </div>
                                    <div class="crancy-ptabs__notify-switch  crancy-ptabs__notify-switch--two">
                                      <label class="crancy__item-switch">
                                        <input type="checkbox" id="checkWH" name="optNotif" (change)="alterarOpcaoNotificacao('WH')" checked>
                                        <span class="crancy__item-switch--slide crancy__item-switch--round"></span>
                                      </label>
                                    </div>
                                  </li>
                                </ul>

                              </div>
                            </form>
                          </div>

                          <div class="tab-pane fade" id="idAS" role="tabpanel">
                            <div class="crancy-paymentm crancy__item-group">
                              <h4 class="crancy__item-group__title">{{label.lbl_AltSen_titulo}}</h4>

                              <div class="row align-items-center">
                                <div class="col-12">
                                  
                                    <div class="form-group">
                                      <label class="crancy-wc__form-label">{{label.lbl_AltSen_senatu}}</label>
                                      <div class="form-group__input">
                                        <input class="crancy-wc__form-input" id="intpu-senha-atu" type="password">
                                        <span class="crancy-wc__toggle togle-pass" (click)="togglePass('intpu-senha-atu')"><i class="fas fa-eye"></i></span>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="crancy-wc__form-label">{{label.lbl_AltSen_sennew1}}</label>
                                      <div class="form-group__input">
                                        <input class="crancy-wc__form-input" id="intpu-senha-new1" (keyup)="strongPass()" type="password">
                                        <span class="crancy-wc__toggle togle-pass" (click)="togglePass('intpu-senha-new1')"><i class="fas fa-eye"></i></span>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label class="crancy-wc__form-label">{{label.lbl_AltSen_sennew2}}</label>
                                      <div class="form-group__input">
                                        <input class="crancy-wc__form-input" id="intpu-senha-new2" (keyup)="strongPass()" type="password">
                                        <span class="crancy-wc__toggle togle-pass" (click)="togglePass('intpu-senha-new2')"><i class="fas fa-eye"></i></span>
                                      </div>
                                    </div>

                                    <div class="tab-content">
                                      <div class="tab-pane fade show active" >
                                        <div class="form-group form-mg-top30">
                                          <div class="mb-3 mt-3">

                                            <!-- senha com no mínimo de 8 caracteres -->
                                            <p *ngIf="strongPassmin8 == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPassmin8}}
                                            </p>
                                            <p *ngIf="strongPassmin8 == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPassmin8}}
                                            </p>

                                            <!-- senha com no mínimo de 1 letra maiúscula -->
                                            <p *ngIf="strongPass1Upper == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPass1Upper}}
                                            </p>
                                            <p *ngIf="strongPass1Upper == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPass1Upper}}
                                            </p>

                                            <p *ngIf="strongPass1Lower == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPass1Lower}}
                                            </p>
                                            <p *ngIf="strongPass1Lower == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPass1Lower}}
                                            </p>

                                            <p *ngIf="strongPass1Number == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPass1Number}}
                                            </p>
                                            <p *ngIf="strongPass1Number == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPass1Number}}
                                            </p>

                                            <p *ngIf="strongPass1Especial == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPass1Especial}}
                                            </p>
                                            <p *ngIf="strongPass1Especial == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPass1Especial}}
                                            </p>
                                            
                                            <p *ngIf="strongPassIgual == true" class="text-primary">
                                              <i class="fa-regular fa-circle-check"></i>
                                              {{label.strongPassIgual}}
                                            </p>
                                            <p *ngIf="strongPassIgual == false" class="text-danger">
                                              <i class="fa-regular fa-circle-xmark"></i>
                                              {{label.strongPassIgual}}
                                            </p>
                
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="crancy__item-button--group crancy__item-button--group--fix crancy__ptabs-bottom">
                                      <!-- <button class="crancy-btn crancy-btn__nostyle crancy-color4 p-0">{{label.btn_cancelar}}</button> -->
                                      <button class="crancy-btn crancy-color8__bg" (click)="alterarSenha()">{{label.btn_salvar}}</button>
                                    </div>
                                  
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" style="display: none;">
                                  <div class="crancy-password__img">
                                    <img src="img/password-reset.png" alt>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="tab-pane fade" id="idTL" role="tabpanel">
                            <div action="#">
                              <div class="row">
                                <div class="col-12">
                                  <div class="crancy-ptabs__form-main">
                                    <div class="crancy__item-group">
                                      <h4 class="crancy__item-group__title">{{label.lbl_TimLine}} 😅</h4>

                                      <br>
                                      <div id="divTimeline">
                                        <div class="container-tl">
                                          <div class="wrapper">
                                            <ul class="sessions">
                                              <li class="li-tl" *ngFor="let item of colTimeLine">
                                                <div class="time">{{item.dat}}</div>
                                                <p>Acessou página de {{item.pag}}, {{item.des}}</p><p *ngIf="item.sts==false">{{item.msg}} 👿</p>
                                              </li>
                                            </ul>
                                            <div class="mt-5 box-mais" *ngIf="timelineRows<colTimeLineTotal.length">
                                              <p class="btn-mais" (click)="buscarMais()" >
                                                {{label.vermais}}
                                              </p>
                                            </div>
                                          </div>
                                        </div> 
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- bloco de tipo de comunicação -->
      <div class="row" *ngIf="box == '2'">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-personals">
              <div class="row">
                <div class="col-lg-12">

                  <div class="crancy-wc__form crancy-wc__form-tip-com">
                    <div class="crancy-ptabs">
                      <div class="crancy-ptabs__inner" >
                        <div class="crancy-wc__heading">
                          <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB2}}</h3>
                          <p>{{label.txt1}}</p>
                        </div>
                        <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                          <h4 class="crancy-wc__form--inner--heading">{{label.txt2}}</h4>
                          <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                              <label class="form-group crancy-wp__form--group label-tip-com" for="wayWhats">
                                <input class="radioButton" type="radio" id="wayWhats" name="wayContact" value="whats">
                                <label class="homec-form-label" for="wayWhats">
                                  {{label.getSMS}}
                                  <br>
                                  <span>{{clienteSql.smsHidden}}</span>
                                </label>
                              </label>
                              <label class="form-group crancy-wp__form--group mg-top-20 label-tip-com" for="wayEmail">
                                <input class="radioButton" type="radio" id="wayEmail" name="wayContact" value="email">
                                <label class="homec-form-label" for="wayEmail">
                                  {{label.getEmail}}
                                  <br>
                                  <span>{{clienteSql.emailHidden}}</span>
                                </label>
                              </label>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="crancy-wc__button crancy-wc__button--initial" style="float: left;width: 40%;">
                              <button style="width: 90%;background: indianred;" class="ntfmax-wc__btn ntfmax-wc__btn--login button-tip-com" (click)="canceltWayContact()">
                                <span style="background: indianred;position: unset;"><i class="fa-solid fa-arrow-left"></i></span>
                                Cancelar
                              </button>
                            </div>
                            <div class="crancy-wc__button crancy-wc__button--initial" style="float: left;width: 40%;">
                              <button style="width: 90%" class="ntfmax-wc__btn ntfmax-wc__btn--login button-tip-com" (click)="getWayContact()">
                                {{label.go}}
                                <span style="position: unset;"><i class="fa-solid fa-arrow-right"></i></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- bloco de confirmação de token -->
      <div class="row" *ngIf="box == '3'">
        <div class="col-12">
          <div class="crancy-body">
            <div class="crancy-personals">
              <div class="row">
                <div class="col-lg-12">

                  <div class="crancy-wc__form crancy-wc__form-tip-com">
                    <div class="crancy-ptabs">
                      <div class="crancy-wc__form-inner">
                        <div class="crancy-wc__heading">
                          <h3 class="crancy-wc__form-title crancy-wc__form-title__one">{{label.tituloB3}}</h3>
                          <p>{{label.txt1}}</p>
                        </div>
                        <div class="crancy-wc__form-main crancy-wc__form-main--select mg-top-30" >
                          <h4 class="crancy-wc__form--inner--heading">{{label.txt4}}</h4>
                          <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="confirm-tab-two" role="tabpanel">
                              <div class="form-group form-mg-top30">
                              <ul class="crancy-wc__form-verify">
                                <input id="code-verification-1" class="crancy-wc__form-input" type="text" name="text" placeholder="0" required="required">
                                <input id="code-verification-2" class="crancy-wc__form-input" type="text" name="text" placeholder="0" required="required">
                                <input id="code-verification-3" class="crancy-wc__form-input" type="text" name="text" placeholder="0" required="required">
                                <input id="code-verification-4" class="crancy-wc__form-input" type="text" name="text" placeholder="0" required="required">
                              </ul>
                            </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="crancy-wc__button crancy-wc__button--initial">
                              <button class="ntfmax-wc__btn ntfmax-wc__btn--login" (click)="verifyCode()">
                                {{label.go}}
                                <span><i class="fa-solid fa-arrow-right"></i></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>