import { Injectable } from '@angular/core';
interface Translate {
    [key: string]: {
        pt: string;
        us: string;
    };
}
@Injectable()

class LibService {
    constructor() {}
    getSession(key: string) {
        const item = localStorage.getItem(key);
        if (item !== null) {
            return JSON.parse(item);
        }
        return null;
    }

    setSession(key: string, value: any = null) {
        if (value === null) {
            localStorage.removeItem(key);
            return;
        }
        localStorage.setItem(key, JSON.stringify(value));
    }

    removeSession(key: string) {
        localStorage.removeItem(key);
    }

    getTranslate(translate: Translate, language:any = null) {
        language = (!language)? this.getSession('language') : language;
        let response: { [key: string]: string } = {};
        for (let key in translate) {
            response[key] = translate[key][language as 'pt' | 'us']// add os novos idiomas aqui
        }
        return response;
    }

    preloader(status: string) { // status = show or hide
        if(status == 'show'){
            document.getElementsByClassName('preloader')[0].classList.remove('hidden');
        }else{
            document.getElementsByClassName('preloader')[0].classList.add('hidden');
        }
    }

    changeLanguage(e: any) {
        let currentLanguage = lib.getSession('language');
        lib.setSession('language', e.value);
        if(currentLanguage != e.value)
            window.location.reload();
    }

    getTranslateDataTable() {
        let translateDataTable = '';
        switch (lib.getSession('language')) {
        case 'pt':
            translateDataTable = 'Portuguese-Brasil';
            break;
        case 'en':
            translateDataTable = 'English';
            break;
        case 'es':
            translateDataTable = 'Spanish';
            break;
        }
        return `https://cdn.datatables.net/plug-ins/1.10.21/i18n/${translateDataTable}.json`;
    }

    validarUsuarioLogado() {
        let user = lib.getSession('user');
        if(user == null){
            // window.location.href = '/login';
            return false;
        }
        return true;
    }

    convertMoney(value: any) {
        // return parseFloat(value).toFixed(2).replace('.', ',');
        //troca o ponto por virgula e limita em 2 casas decimais e separa os milhares com ponto
        return parseFloat(value).toLocaleString('pt-BR', {minimumFractionDigits: 2});
    }

    getPaymentType(paymentType: string) {
        let language = lib.getSession('language');
        let arrayPaymentType:any = {
            "CC": {
                pt:"Cartão de Crédito",
                en:"Credit Card",
                es:"Tarjeta de crédito"
            },
            "CD": {
                pt:"Cartão de Débito",
                en:"Debit Card",
                es:"Tarjeta de débito"
            },
            "BL": {
                pt:"Boleto",
                en:"Ticket",
                es:"Boleto"
            },
            "PI": {
                pt:"Pix",
                en:"Pix",
                es:"Pix"
            },
            "CT": {
                pt:"Carteira",
                en:"Wallet",
                es:"Billetera"
            },
            "DPF":{
                pt:"Duplicata Funcionário",
                en:"Employee Duplicate",
                es:"Duplicado de empleado"
            },
            "DP":{
                pt:"Duplicata",
                en:"Duplicate",
                es:"Duplicado"
            },
            "PGM":{
                pt:"Pagar-me",
                en:"Pagar-me",
                es:"Pagar-me"
            },
            "R$": {
                pt:"Dinheiro",
                en:"Money",
                es:"Dinero"
            },
            "CCCD": {
                pt:"Cartão de Crédito/Débito",
                en:"Credit/Debit Card",
                es:"Tarjeta de crédito/débito"
            },
        }
        try {
            return arrayPaymentType[paymentType][language as 'pt' | 'us' | 'es'];
        } catch (error) {
            return paymentType;
        }
    }

    validaCPF(cpf:string) {
        cpf = cpf.replace(/[^\d]+/g,'');
        if(cpf == '') return false;
        // Elimina CPFs inválidos conhecidos
        if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
                return false;
        // Valida 1o digito
        let add = 0;
        for (let i=0; i < 9; i ++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
            let rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(9)))
                return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i ++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
            rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(10)))
                return false;
        return true;
    }

    validaCNPJ(cnpj:string) {
        cnpj = cnpj.replace(/[^\d]+/g,'');
        if(cnpj == '') return false;
        if (cnpj.length != 14)
            return false;
        // Elimina CNPJs inválidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
                return false;
        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != parseInt(digitos.charAt(0)))
            return false;
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != parseInt(digitos.charAt(1)))
                return false;
        return true;
    }

    getPrefOriDescription(prefOri:string, filial:string){
        let language = lib.getSession('language');
        var descFil = "";

        switch(filial)
        {
            case "0201": descFil = "Loja Matriz - Goiânia-GO"; break;
            case "0202": descFil = "Loja Burití - Goiânia-GO"; break;
            case "0203": descFil = "Loja Oeste - Goiânia-GO"; break;
            case "0204": descFil = "Loja Aparecida - Aparecida de Goiânia-GO"; break;
            case "0205": descFil = "Loja Rio Verde - Rio Verde-GO"; break;
        }

        let arrayPrefOri:any = {
            "OFI": {
                pt:"COMPRA FEITA EM OFICINA ESPECIALIZADA",
                en:"PURCHASE MADE IN SPECIALIZED WORKSHOP",
                es:"COMPRA REALIZADA EN TALLER ESPECIALIZADO"
            },
            "BAL": {
                pt:"COMPRA FEITA EM LOJA",
                en:"PURCHASE MADE IN STORE",
                es:"COMPRA REALIZADA EN TIENDA"
            },
            "VDF": {
                pt:"COMPRA FEITA EM LOJA",
                en:"PURCHASE MADE IN STORE",
                es:"COMPRA REALIZADA EN TIENDA"
            },
            "VEI": {
                pt:"",
                en:"",
                es:""
            },
            "EC0": {
                pt:"COMPRA FEITA PELO SITE",
                en:"PURCHASE MADE THE WEBSITE",
                es:"COMPRA REALIZADA A TRAVÉS DE LA PÁGINA WEB"
            }
        }

        if(prefOri == "VEI" || prefOri == "EC0"){descFil = "";};

        try {
            return { origem: arrayPrefOri[prefOri][language as 'pt' | 'us' | 'es'], descFilial: descFil};
        } catch (error) {
            return { origem: prefOri, descFilial: ""};
        }
    }

    replaceSpecialCharacters(strText:string){
        return strText
            .replaceAll("á","a")
            .replaceAll("à","a")
            .replaceAll("â","a")
            .replaceAll("ã","a")
            .replaceAll("ä","a")
            .replaceAll("Á","A")
            .replaceAll("À","A")
            .replaceAll("Â","A")
            .replaceAll("Ã","A")
            .replaceAll("Ä","A")
            .replaceAll("é","e")
            .replaceAll("è","e")
            .replaceAll("ê","e")
            .replaceAll("ê","e")
            .replaceAll("É","E")
            .replaceAll("È","E")
            .replaceAll("Ê","E")
            .replaceAll("Ë","E")
            .replaceAll("í","i")
            .replaceAll("ì","i")
            .replaceAll("î","i")
            .replaceAll("ï","i")
            .replaceAll("Í","I")
            .replaceAll("Ì","I")
            .replaceAll("Î","I")
            .replaceAll("Ï","I")
            .replaceAll("ó","o")
            .replaceAll("ò","o")
            .replaceAll("ô","o")
            .replaceAll("õ","o")
            .replaceAll("ö","o")
            .replaceAll("Ó","O")
            .replaceAll("Ò","O")
            .replaceAll("Ô","O")
            .replaceAll("Õ","O")
            .replaceAll("Ö","O")
            .replaceAll("ú","u")
            .replaceAll("ù","u")
            .replaceAll("û","u")
            .replaceAll("ü","u")
            .replaceAll("Ú","U")
            .replaceAll("Ù","U")
            .replaceAll("Û","U")
            .replaceAll("ç","c")
            .replaceAll("Ç","C")
            .replaceAll("ñ","n")
            .replaceAll("Ñ","N")
            .replace(/[^a-z0-9 ]/gi,'')
            .trim();
    }

    removeSpaceInText(strText:string){
        return strText.split(' ').join('');
    }

}
const lib = new LibService();

export { lib };