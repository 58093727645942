import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class PagamentosService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    criarCobrancaPix(data:any):Observable<objRetorno>{
        return this.http.post("PixItau/CriarCobrancaPix",  data);
    }

    consultarCobrancaPix(txid:string):Observable<objRetorno>{
        return this.http.get(`ConsultarCobrancaPix/${txid}/02`);
    }
}