<div class="crancy-smenu" id="CrancyMenu">

  <div class="admin-menu">

    <div class="logo crancy-sidebar-padding pd-right-0">
      <a class="crancy-logo" routerLink="/home">
        <!-- <img class="crancy-logo__main" src="assets/img/logo.png" alt="#"> -->
        <img class="crancy-logo__main" src="assets/img/{{pastaImagens}}/logo-full.png" alt="#">
        <img class="crancy-logo__main--small" src="assets/img/{{pastaImagens}}/logo-icon.png" alt="#" style="border-radius: 20px;padding: 10px;">
      </a>
      <div id="crancy__sicon" class="crancy__sicon close-icon">
        <img src="assets/img/arrow-icon.svg" >
      </div>
    </div>

    <div class="admin-menu__one crancy-sidebar-padding mg-top-20">
      <div class="menu-bar">
        <ul id="CrancyMenu" class="menu-bar__one crancy-dashboard-menu">
          <li class="li-menu-bar" *ngFor="let item of colMenu">
            <a class="collapsed" routerLink="{{item.url}}">
              <span class="menu-bar__text">
                <span class="crancy-menu-icon crancy-svg-icon__v1">
                  <div class="div-menu-bar-i-a">
                    <i class='{{item.ico}} menu-bar__icon'></i>
                  </div>
                  <div class="div-menu-bar-l">
                    <span class="menu-bar__name">{{item.des}}</span>
                  </div>
                </span>
              </span>
            </a>
          </li>
          <li class="li-menu-bar">
            <a class="collapsed not-move" href="#" (click)="sair()">
              <span class="menu-bar__text">
                <span class="crancy-menu-icon crancy-svg-icon__v1">
                  <div class="div-menu-bar-i-a">
                    <i class='fa fa-sign-out menu-bar__icon'></i>
                  </div>
                  <div class="div-menu-bar-l">
                    <span class="menu-bar__name">{{label.sair}}</span>
                  </div>
                </span>
              </span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>