<div class="crancy-body-area ">
  <app-menu></app-menu>
  <app-header></app-header>

  <section class="crancy-adashboard crancy-show">
    <div class="row">
      <div class="col-12 mg-top-30">
        <img src="assets/img/banner/{{pastaImagens}}/banner-head.jpg" style="border-radius: 10px;width: 100%;"/>
      </div>
    </div>
    <div class="row row-box-detcli" *ngFor="let item of telemetria">
      <div class="row" style="flex-wrap: unset;" *ngIf="item.id<4">
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box1_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            {{item.box1_info}}
            <span class="span-detclil-strong">{{item.box1_dest}}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box2_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            {{item.box2_info}}
            <span class="span-detclil-strong">{{item.box2_dest}}</span>
          </div>
        </div>
      </div>
      <div class="row" style="flex-wrap: unset;" *ngIf="item.id==4">
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box1_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            <div class="row" style="font-size: 12px;">
              <div class="col-3">{{item.box1_info}}</div>
              <div class="col-2">{{item.box1_dest}}</div>
              <div class="col-3">
                <div class="meter blue nostripes">
                    <span id="percomprase" style="display:none;"></span>
                </div>
              </div>
              <div class="col-4">{{item.box1_comp}}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box2_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            <div class="row" style="font-size: 12px;">
              <div class="col-3">{{item.box2_info}}</div>
              <div class="col-2">{{item.box2_dest}}</div>
              <div class="col-3">
                <div class="meter red nostripes">
                  <span id="percomprasv" style="display:none;"></span>
                </div>
              </div>
              <div class="col-4">{{item.box2_comp}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="flex-wrap: unset;" *ngIf="item.id==5">
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box1_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            <div class="row" style="font-size: 15px;">
              <span (click)="mostrarAtendentes()">
                  atendido por: <span id="divVerVendedores" class="span-detclil-strong-fones">ver</span>
              </span>
              <div id="divAtendentes" style="display: none;font-size: 12px;">
                <div *ngFor="let at of atendentes">
                  {{at}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row-detcli" *ngIf="item.box2_info!=''">
          <div class="col-1 col-align-ico-detcli">
            <i class='{{item.box2_icon}} icon-size-detcli'></i>
          </div>
          <div class="col-11 span-detclil-nostrong">
            {{item.box2_info}}
            <span class="span-detclil-strong">{{item.box2_dest}}</span>
            <span *ngIf="ultimaclas=='D'" class="span-nota-nps-Detrator">Detrator</span>
            <span *ngIf="ultimaclas=='N'" class="span-nota-nps-Neutro">Neutro</span>
            <span *ngIf="ultimaclas=='P'" class="span-nota-nps-Promotor">Promotor</span>
          </div>
        </div>
      </div>
    </div>

    <div class='modal modal-background-color' id='modal_termo' tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
      <div class="modal-dialog modal-dialog-centered">
          <div class='modal-content modal-content-detcli'>
              <div class='modal-header'>
                  <h4 class='modal-title'>
                      <span class="span-tit-modal"></span>
                  </h4>
              </div>
  
              <div class='modal-body'>
                  <div id="divTermo" class="step-inner-content" style="padding-bottom: 0px;">
                  </div>
              </div>
  
              <div class='modal-footer' id='buttons'>
                  <div class="div-footer-modal">
                      <span class="span-close-modal" (click)="fecharModalTermo();">
                          ACEITO
                          <i class='fa fa-sign-out'></i>
                      </span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>