import { Component , OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { lib } from 'src/app/shared/services/common/lib.service';
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import { EmailService } from 'src/app/shared/services/email/email.service';
import { WhatsappService } from 'src/app/shared/services/whatsapp/whatsapp.service';
import Swal from 'sweetalert2'
import * as translations from './login.translate.json';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.css',
  ]
})
export class LoginComponent implements OnInit {
  translate: any = translations;
  currentYear: any = new Date().getFullYear();
  language: any = lib.getSession('language') == null ? 'pt' : lib.getSession('language');
  label: any = {};
  infoCliente: any = {};
  lib = lib;
  box:string = '1';
  tokenInputFull:boolean = false;
  pastaImagens = lib.getSession('pastaImagens');
  linksRedes = (lib.getSession('pastaImagens') == 'cpp')?{
      "facebook":"https://www.facebook.com/casadopicapau",
      "instagram":"https://www.instagram.com/casadopicapau/",
      "linkedin":"https://www.linkedin.com/company/casadopicapau",
      "youtube":"https://www.youtube.com/@casadopica-pau"
  }:{
      "facebook":"https://www.facebook.com/hohljohndeere",
      "instagram":"https://www.instagram.com/hohljohndeere/",
      "linkedin":"https://www.linkedin.com/company/hohljohndeere",
      "youtube":"https://www.youtube.com/@hohljohndeere"
  };
  constructor(
    private router: Router,
    private loginSrv: LoginService,
    private clienteService: ClienteService,
    private emailService: EmailService,
    private whatsappService: WhatsappService
    ) {
      lib.getSession('language') == null ? lib.setSession('language', 'pt') : '';
      this.label = lib.getTranslate(this.translate);
  }
  
  ngOnInit() {
    //console.log(this.pastaImagens);
    
    lib.preloader('hide');
    if(lib.validarUsuarioLogado()){
      this.loginSucesso();    
    }else{
      this.lembrarLogin();
    }
  }

  loginSucesso(){
    this.router.navigate(['/home']);
    lib.setSession('cliente_edit', null);
    lib.setSession('obj_cliente', null);
  }

  checkLogin() {
    //get user and password ids
    let user = (<HTMLInputElement>document.getElementById('user')).value;
    let password = (<HTMLInputElement>document.getElementById('pass')).value;
    if(user == ""){
      Swal.fire( this.label.msgNotUser, '', 'info');
      return;
    }

    if(password == ""){
      Swal.fire( this.label.msgNotPass, '', 'info');
      return;
    }
    let token = navigator.userAgent;

    this.loginSrv.validaLogin(user, password, token).subscribe(
      res => {
        if(res.coderror != undefined && res.coderror.length > 0){
            Swal.fire(res.coderror[0][this.language as keyof typeof res.coderror[0]], '', 'info');
        }else{
            if(res.response.sts == true){
                // se o #checkbox estiver marcado, salva o usuário e senha no localStorage
                let remember = (<HTMLInputElement>document.getElementById('checkbox')).checked;
                if(remember){
                    lib.setSession('login', {user: user, password: password});
                }else{
                    lib.setSession('login', null);
                }
                this.infoCliente = res.response;
                if (res.response.safe == false) {
                  this.box = '2';
                  this.infoCliente.telefone = this.infoCliente.telefone.replace(/\D/g, '');//remove todos os caracteres que não são números
                  this.infoCliente.smsHidden = this.infoCliente.telefone.replace(/.(?=.{4})/g, '*');//pega os 4 últimos caracteres do telefone e substitui o resto por *
                  let email = this.infoCliente.email.split('@');//separa o email em 2 partes, antes e depois do @
                  let emailHidden = email[0].substr(0,5);//pega os 5 primeiros caracteres do email
                  emailHidden += '*'.repeat(email[0].length - 5);//substitui o resto por *
                  emailHidden += '@' + email[1].replace(/.(?=.{6})/g, '*');//pega os 6 últimos caracteres do email e substitui o resto por *
                  this.infoCliente.emailHidden = emailHidden;//atribui o emailHidden ao objeto infoCliente
                }else{
                  this.setDeviceToken();
                }
            }else{
                if(res.response.msg.codigo == "I002"){

                  Swal.fire({
                      title: "",
                      text: res.response.msg[this.language],
                      icon: 'info',
                      confirmButtonText: "OK"
                  }).then((result) => {
                    this.router.navigate(['/primeiro-acesso'])
                  })

                }else{
                  Swal.fire(res.response.msg[this.language], '', 'info');
                }
            }
        }
      },
      err => {
        console.log(err);
      }
    );
    
  }

  public lembrarLogin(){
    let login = lib.getSession('login');
    if(login){
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('user')).value = login.user;
        (<HTMLInputElement>document.getElementById('pass')).value = login.password;
        (<HTMLInputElement>document.getElementById('checkbox')).checked = true;
      }, 1000);
    }
  } 

  async getWayContact(){
    //get way contact from input radio name="wayContact"
    let way = (document.querySelector('input[name="wayContact"]:checked') as HTMLInputElement);//pega o input radio marcado
    if (!way) {//se não tiver nenhum marcado
      Swal.fire({
        title: this.label.msgWarningTitle,
        text: this.label.msgErrorWayContactText,
        icon: 'warning',
      });
      return;
    }
    this.infoCliente.wayContact = way.value;
    lib.preloader('show');
    if (way.value == 'email') {
      await this.emailService.enviarToken({codigo: this.infoCliente.codigo}).toPromise();
    }
    if (way.value == 'whats') {
      await this.whatsappService.enviarToken({number: this.infoCliente.telefone}).toPromise();
    }
    this.box = '3';
    this.ativarInputsToken();
    lib.preloader('hide');
  }
  
  async verifyCode(){
    let code1 = (document.getElementById('code-verification-1') as HTMLInputElement).value as string;
    let code2 = (document.getElementById('code-verification-2') as HTMLInputElement).value as string;
    let code3 = (document.getElementById('code-verification-3') as HTMLInputElement).value as string;
    let code4 = (document.getElementById('code-verification-4') as HTMLInputElement).value as string;
    if ( code1 == "" || code2 == "" || code3 == "" || code4 == "") {
      Swal.fire({
        title: this.label.msgErrorTitle,
        text: this.label.msgErrorNotCodeText,
        icon: 'error',
      })
      return;
    }
    let code = `${code1}${code2}${code3}${code4}`;
    lib.preloader('show');
    if (this.infoCliente.wayContact == 'email') {
      let verifyCode:any = await this.clienteService.verificarTokenEmail({codigo: this.infoCliente.codigo, token: code}).toPromise();
      lib.preloader('hide');
      //console.log(verifyCode);
      if(verifyCode.response == true){
        this.setDeviceToken();
        return;
      }
      if(verifyCode.coderror){
        Swal.fire( verifyCode.coderror[0][this.language as keyof typeof verifyCode.coderror], '', 'info');
        return;
      } 
    }
    if (this.infoCliente.wayContact == 'whats') {
      let verifyCode:any = await this.whatsappService.validarPin({number: this.infoCliente.telefone, pin: code}).toPromise();
      lib.preloader('hide');
      //console.log(verifyCode);
     if (verifyCode.msg == 'PIN code verified') {
        this.setDeviceToken();
        return;
      }
    }
  }

  ativarInputsToken(){
    setTimeout(() => {
    //quando o cliente clicar em um id code-verification-1, ele vai para o próximo
    let code1 = document.getElementById('code-verification-1') as HTMLInputElement;
    let code2 = document.getElementById('code-verification-2') as HTMLInputElement;
    let code3 = document.getElementById('code-verification-3') as HTMLInputElement;
    let code4 = document.getElementById('code-verification-4') as HTMLInputElement;
    code1.addEventListener('keyup', (event) => {
      if (code1.value.length == 1 && this.tokenInputFull == false) {
        code2?.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
      if (code1.value.length > 1) {
        this.tokenInputFull = true;
        let aux = code1.value.split('');
        code1.value = aux[0];
        code2.value = aux[1];
        code3.value = aux[2];
        code4.value = aux[3];
        this.verifyCode();
      }
    });
    code2.addEventListener('keyup', (event) => {
      if (code2.value.length == 1 && this.tokenInputFull == false) {
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code1.focus();
        code1.setSelectionRange(0, code1.value.length);
      }
    });
    code3.addEventListener('keyup', (event) => {
      if (code3.value.length == 1 && this.tokenInputFull == false) {
        code4.focus();
        code4.setSelectionRange(0, code4.value.length);
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code2.focus();
        code2.setSelectionRange(0, code2.value.length);
      }
    });
    //ao digitar o 4º digito e apertar Enter, verifica o código
    code4.addEventListener('keyup', (event) => {
      if (event.key == "Enter") {
        this.verifyCode();
      }
      if (event.key == "Backspace") {
        this.tokenInputFull = false;
        code3.focus();
        code3.setSelectionRange(0, code3.value.length);
      }
    });
    }, 1000);
  }

  togglePass(id:string){
    let pass = document.querySelector('#'+id) as HTMLInputElement;
    if (pass.type == 'password') {
      pass.type = 'text';
    }else{
      pass.type = 'password';
    }
  }

  async setDeviceToken(){
    let objDvcToken = {
      codigo: this.infoCliente.codigo,
      loja: this.infoCliente.loja,
      token: navigator.userAgent
    }
    lib.setSession('user', this.infoCliente);
    await this.clienteService.setDeviceToken(objDvcToken).toPromise() as any;
    this.loginSucesso();
  }
}
