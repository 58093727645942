import { Component } from '@angular/core';
import * as translations from './error.translate.json';
import { lib } from '../../shared/services/common/lib.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {
  translate: any = translations;
  label: any = {};
  constructor() {
    this.label = lib.getTranslate(this.translate);
  }

}
