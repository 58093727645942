import { Component, OnInit } from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { AdminService } from 'src/app/shared/services/admin/admin.service';
import { ClienteService } from 'src/app/shared/services/cliente/cliente.service';
import { DocumentosService } from 'src/app/shared/services/documentos/documentos.service';
import * as translations from './home.translate.json';
// Run this command in your terminal to install the @types/jquery package as a development dependency:
// npm install --save-dev @types/jquery
import * as $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.css','./home.meter.component.css',
  ],
})

export class HomeComponent implements OnInit {

  telemetria: any = [];
  atendentes: any = [];
  ultimanota: string = "";
  ultimaclas: string = "";

  label: any = {};
  translate: any = translations;
  dtOptions: DataTables.Settings = {};
  minhasCompras: any = [];
  
  displayStyle:string = "none";
  pastaImagens = lib.getSession('pastaImagens');

  constructor(private router: Router,
              private adminSRV: AdminService,
              private clienteSRV: ClienteService,
              private docSRV: DocumentosService){
    if (!lib.validarUsuarioLogado()) {
      this.router.navigate(['/login']);
    }

    this.label = lib.getTranslate(this.translate);
    //esconde o menu de troca a qtd de registros por pagina mas ainda permite paginação
  }

  async ngOnInit() {
    this.buscarTelemetriaCliente();
    lib.preloader('hide');

    //varifica optin termo termo
    var cliMongo:any = {};
    cliMongo.response = {};
    cliMongo = lib.getSession("obj_cliente");
    
    if(cliMongo == undefined || cliMongo.response == null){
      let objSes = lib.getSession('user');
      cliMongo = await this.clienteSRV.getClienteMongo(objSes.codigo).toPromise();
    }
    
    if(cliMongo != undefined && cliMongo.response != undefined){
      if(cliMongo.response.optin == undefined || cliMongo.response.optin.length == 0){
        var respOptin = await this.clienteSRV.getOptIn(cliMongo.response.codigo).toPromise();

        if(respOptin != undefined && respOptin.response != undefined){
          if(cliMongo.response.optin == undefined)
            cliMongo.response.optin = []

          var arrayOptin = JSON.parse(JSON.stringify(respOptin.response));
          arrayOptin.forEach((element:any) => {
            cliMongo.response.optin.push(element);
          });

          lib.setSession("obj_cliente", cliMongo);
        }
      }

      var qtdOptin1 = 0;
      if(cliMongo.response.optin != undefined && cliMongo.response.optin.length > 0){
        qtdOptin1 = cliMongo.response.optin.filter((w:any) => w.tipo == "1").length;
      }

      if(qtdOptin1==0){
        //abre termo
        let respDoc = await this.docSRV.getTermoPrivacidade().toPromise();

        if(respDoc != undefined && respDoc.response != undefined){
          $('#divTermo').html(respDoc.response)
          this.displayStyle = 'block';
          setTimeout(()=>{this.aplicaCssTermo();}, 500);
        }
      }
    }
  }

  aplicaCssTermo(){
    var eleDiv = document.getElementById('divTermo') as HTMLDivElement;

    for (var indexEle = 0; indexEle < eleDiv.children.length; indexEle++) {
        var ele = eleDiv.children[indexEle];
        var nome = `${ele.tagName}${indexEle}`;

        ele.setAttribute('id', nome)
        switch(ele.tagName)
        {
          case 'P': 
            var eleP = document.getElementById(nome) as HTMLElement;
            eleP.style.color = "#2F3032";
            eleP.style.fontSize = "13px";
            eleP.style.paddingTop = "10px";
            break;

          case 'UL':
            var eleUL = document.getElementById(nome) as HTMLElement;
            eleUL.style.color = "#2F3032";
            eleUL.style.fontSize = "13px";
            eleUL.style.paddingLeft = "20px";
            eleUL.style.listStyle = "inside";
            eleUL.style.listStylePosition = "outside";
            break;

          case 'LI':
            var eleLI = document.getElementById(nome) as HTMLElement;
            eleLI.style.paddingBottom = "10px";
            break;
        }
    }
  }

  fecharModalTermo(){
    this.displayStyle = 'none';
    let objSes = lib.getSession('user');
    this.clienteSRV.setOptIn(objSes.codigo, true, '1').subscribe();
  }

  datatableAction() {
    let table = $('#t_compras')
    console.log(table);
  }

  async buscarTelemetriaCliente(){
    let objSes = lib.getSession('user');
    let respTele = await this.adminSRV.getDetalheMeuCliente(objSes.codigo).toPromise();

    if(respTele != undefined){
      this.telemetria = [];
      this.atendentes = [];
      this.ultimanota = "";
      this.ultimaclas = "";

      var jsonDados = JSON.stringify(respTele);
      var objDados = JSON.parse(jsonDados)[0];

      var ultimaCompra   = this.formataData(objDados.ULTCOM);
      var clienteDesde   = this.formataData(objDados.PRICOM);

      this.telemetria.push
      (
        {
          "id"        : 1,
          "box1_icon" : `fa fa-money`,
          "box1_info" : `Total de compras`,
          "box1_dest" : `R$ ${lib.convertMoney(objDados.VLRCOMPRAST)}`,
          "box2_icon" : `fa fa-money-bills`,
          "box2_info" : `Total de compras no mês`,
          "box2_dest" : `R$ ${lib.convertMoney(objDados.VLRCOMPRASM)}`
        }
      )

      this.telemetria.push
      (
        {
          "id"        : 2,
          "box1_icon" : `fa fa-money-bill-transfer`,
          "box1_info" : `Ticket médio geral `,
          "box1_dest" : `R$ ${lib.convertMoney(objDados.TICMED)}`,
          "box2_icon" : `fa fa-cube`,
          "box2_info" : `Média de `,
          "box2_dest" : `${objDados.MEDPRD} ${(objDados.MEDPRD>1)?"produtos":"produto"} por compra`
        }
      )

      this.telemetria.push
      (
        {
          "id"        : 3,
          "box1_icon" : `fa fa-shopping-cart`,
          "box1_info" : `Última compra em `,
          "box1_dest" : `${ultimaCompra}`,
          "box2_icon" : `fa fa-calendar`,
          "box2_info" : `Cliente desde `,
          "box2_dest" : `${clienteDesde}`
        }
      )

      this.telemetria.push
      (
        {
          "id"        : 4,
          "box1_icon" : `fa fa-desktop`,
          "box1_info" : `site`,
          "box1_dest" : `${objDados.PERCOMPRASE}%`,
          "box1_comp" : `R$ ${lib.convertMoney(objDados.VLRCOMPRASE)}`,
          "box1_perc" : objDados.PERCOMPRASE,
          "box2_icon" : `fa fa-shopping-bag`,
          "box2_info" : `loja`,
          "box2_dest" : `${objDados.PERCOMPRASV}%`,
          "box2_comp" : `R$ ${lib.convertMoney(objDados.VLRCOMPRASV)}`,
          "box2_perc" : objDados.PERCOMPRASV,
        }
      )

      if(objDados.JSONVEND != undefined && objDados.JSONVEND.length > 0){
        var vendedores = "";
        for(var indV = 0; indV < objDados.JSONVEND.length; indV++){
          if(objDados.JSONVEND[indV].NOM.trim() != ""){
            this.atendentes.push(objDados.JSONVEND[indV].RED)
          }
        }
      }

      if(objDados.JSONULTNFE != undefined && objDados.JSONULTNFE.length > 0){
        var Mes = objDados.JSONULTNFE[0].EMSNFE.substr(4, 2);
        var Ano = objDados.JSONULTNFE[0].EMSNFE.substr(0, 4);
        var mesref = `${Mes}_${Ano}`;
        var chave = objDados.JSONULTNFE[0].CHVNFE.trim();
        let avaliacoes = await this.adminSRV.getRelatorioAvaliacoes(mesref).toPromise();

        if(avaliacoes != undefined && avaliacoes.toString().trim() != ""){
          var dataNPS = JSON.parse(JSON.stringify(avaliacoes));
          var avaliacao = dataNPS.filter((w:any) => w.chave_nfe == chave);
          if(avaliacao != undefined && avaliacao.length > 0){
              this.ultimanota = avaliacao[0].NPS;

            if( parseFloat(this.ultimanota) >= 0 && parseFloat(this.ultimanota) <= 6 ){
              this.ultimaclas = "D";
            }else if( parseFloat(this.ultimanota) >= 7 && parseFloat(this.ultimanota) <= 8 ){
              this.ultimaclas = "N";
            }else{
              this.ultimaclas = "P";
            }
          }
        }
      }
      
      var box1_icon_id5 = "";
      var box1_info_id5 = "";
      var box1_dest_id5 = "";
      var box2_icon_id5 = "";
      var box2_info_id5 = "";
      var box2_dest_id5 = "";

      if(this.atendentes.length>0 && this.ultimanota!=""){
        box1_icon_id5 = "fa fa-people-roof";
        box1_info_id5 = "Atendido por:";
        box1_dest_id5 = "";
        box2_icon_id5 = "fa fa-medal";
        box2_info_id5 = "Nota último NPS foi ";
        box2_dest_id5 = `${this.ultimanota}`;
      }else if(this.atendentes.length>0 && this.ultimanota==""){
        box1_icon_id5 = "fa fa-people-roof";
        box1_info_id5 = "Atendido por:";
        box1_dest_id5 = "";
        box2_icon_id5 = "";
        box2_info_id5 = "";
        box2_dest_id5 = "";
      }

      this.telemetria.push
        (
          {
            "id"        : 5,
            "box1_icon" : box1_icon_id5,
            "box1_info" : box1_info_id5,
            "box1_dest" : box1_dest_id5,
            "box2_icon" : box2_icon_id5,
            "box2_info" : box2_info_id5,
            "box2_dest" : box2_dest_id5
          }
        )

      var PERCOMPRASE = objDados.PERCOMPRASE
      var PERCOMPRASV = objDados.PERCOMPRASV
      
      setTimeout(
        function(){
          $("#percomprase").css("width", `${PERCOMPRASE}%`);
          $("#percomprasv").css("width", `${PERCOMPRASV}%`);

          $("#percomprase").show();
          $("#percomprasv").show();
        }, 1000)
    }
  }

  formataData(data:string){
      var Dia = data.substr(6, 2)
      var Mes = data.substr(4, 2)
      var Ano = data.substr(0, 4)

      switch(Mes)
      {
          case '01': Mes = 'jan'; break;
          case '02': Mes = 'fev'; break;
          case '03': Mes = 'mar'; break;
          case '04': Mes = 'abr'; break;
          case '05': Mes = 'mai'; break;
          case '06': Mes = 'jun'; break;
          case '07': Mes = 'jul'; break;
          case '08': Mes = 'ago'; break;
          case '09': Mes = 'set'; break;
          case '10': Mes = 'out'; break;
          case '11': Mes = 'nov'; break;
          case '12': Mes = 'dez'; break;
      }

      return `${Dia} ${Mes} ${Ano}`;
  }

  mostrarAtendentes(){
    var ele1 = document.getElementById('divAtendentes') as HTMLDivElement;
    ele1.style.display = 'block';

    var ele2 = document.getElementById('divVerVendedores') as HTMLDivElement;
    ele2.style.display = 'none';
  }
}