import { Component } from '@angular/core';
import * as translations from './footer.translate.json';
import { lib } from 'src/app/shared/services/common/lib.service';
import { DocumentosService } from 'src/app/shared/services/documentos/documentos.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {

  translate: any = translations;
  currentYear: any = new Date().getFullYear();
  language: any = lib.getSession('language') == null ? 'pt' : lib.getSession('language');
  label: any = {};
  lib = lib;
  displayStyleAceito:string = 'none';

  constructor(private docSRV: DocumentosService) {
    lib.getSession('language') == null ? lib.setSession('language', 'pt') : '';
      this.label = lib.getTranslate(this.translate);
      document.getElementsByTagName('html')[0].setAttribute('lang', this.language);
  }

  async abreModalTermo(){
    let respDoc = await this.docSRV.getTermoPrivacidade().toPromise();

    if(respDoc != undefined && respDoc.response != undefined){
      $('#divTermoAceito').html(respDoc.response)
      this.displayStyleAceito = 'block';
      setTimeout(()=>{this.aplicaCssTermo();}, 500);
    }
  }

  aplicaCssTermo(){
    var eleDiv = document.getElementById('divTermoAceito') as HTMLDivElement;

    for (var indexEle = 0; indexEle < eleDiv.children.length; indexEle++) {
        var ele = eleDiv.children[indexEle];
        var nome = `${ele.tagName}${indexEle}a`;

        ele.setAttribute('id', nome)
        switch(ele.tagName)
        {
          case 'P': 
            var eleP = document.getElementById(nome) as HTMLElement;
            eleP.style.color = "#2F3032";
            eleP.style.fontSize = "13px";
            eleP.style.paddingTop = "10px";
            break;

          case 'UL':
            var eleUL = document.getElementById(nome) as HTMLElement;
            eleUL.style.color = "#2F3032";
            eleUL.style.fontSize = "13px";
            eleUL.style.paddingLeft = "20px";
            eleUL.style.listStyle = "inside";
            eleUL.style.listStylePosition = "outside";
            break;

          case 'LI':
            var eleLI = document.getElementById(nome) as HTMLElement;
            eleLI.style.paddingBottom = "10px";
            break;
        }
    }
  }

  fecharModalTermo(){
    this.displayStyleAceito = 'none';
  }
}
