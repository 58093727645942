import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()

export class HttpService<T>{

    constructor(protected $http: HttpClient){}

    get(endPoint : string): Observable<T>{
        return this.$http.get<T>(environment.api.baseUrl + endPoint);
    }

    post(endPoint : string, body : any): Observable<T>{
        return this.$http.post<T>(environment.api.baseUrl + endPoint, body)
    }

    patch(endPoint : string, body : any): Observable<T>{
        return this.$http.patch<T>(environment.api.baseUrl + endPoint, body);
    }

    delete(endPoint : string, data : any): any{
        const option = { body : data};
        return this.$http.delete(environment.api.baseUrl + endPoint, option);
    }

}