import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ComprasComponent } from './pages/compras/compras.component';
import { CompraDetalhesComponent } from './pages/compradetalhes/compradetalhes.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PrimeiroAcessoComponent } from './pages/primeiro-acesso/primeiro-acesso.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { RenegociaComponent } from './pages/renegocia/renegocia.component';
import { DevolucaotrocaComponent } from './pages/devolucaotroca/devolucaotroca.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'compras',  component: ComprasComponent },
  { path: 'compra-detalhes',  component: CompraDetalhesComponent },
  { path: 'primeiro-acesso',  component: PrimeiroAcessoComponent },
  { path: 'esqueceu-senha',  component: PrimeiroAcessoComponent },
  { path: 'perfil',  component: PerfilComponent },
  { path: 'renegocia',  component: RenegociaComponent },
  { path: 'devolucaotroca',  component: DevolucaotrocaComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }