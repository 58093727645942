import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/common/http.service';

/** Components **/
//import { FooterComponent } from './footer/footer.component';
//import { NavbarComponent } from './navbar/navbar.component';

import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
      HttpService,
    ]
  })
  export class SharedModule { }