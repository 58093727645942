import { Component, OnInit } from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { DevolucaotrocaService } from 'src/app/shared/services/devolucaotroca/devolucaotroca.service';
import * as translations from './devolucaotroca.translate.json';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

@Component({
  selector: 'app-devolucaotroca',
  templateUrl: './devolucaotroca.component.html',
  styleUrls: ['./devolucaotroca.component.css'],
})

export class DevolucaotrocaComponent implements OnInit {

  label: any = {};
  language: any = lib.getSession('language');
  translate: any = translations;
  pastaImagens = lib.getSession('pastaImagens');
  
  minhasCompras:any = []

  constructor(private router: Router,
              private dtSRV: DevolucaotrocaService) {
    if (!lib.validarUsuarioLogado()) {
      this.router.navigate(['/home']);
    }
    this.label = lib.getTranslate(this.translate);
  }

  async ngOnInit() {
    lib.preloader('show');
    await this.buscarDevTro();
    lib.preloader('hide');
  }

  async buscarDevTro(){
    let objSes = lib.getSession('user');
    let cod_cliente = objSes.codigo;

    try{
      let res = await this.dtSRV.getDevolucaotroca(cod_cliente).toPromise() as any;

      if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado") {
        return lib.preloader('hide');
      }else{
        this.minhasCompras = []
        this.minhasCompras = res.response;

        this.minhasCompras.forEach( (itemNF:any)  => {

          var colProdutos = itemNF.produtos;

          itemNF.devolucoes.forEach( (item:any)  => {
            var prod = colProdutos.filter((w:any) => w.codigo == item.produto);
  
            item.desproduto = prod[0].descricao
  
            switch(item.tipo)
            {
              case 'D': item.destipo = this.label.opt_devolucao; break;
              case 'T': item.destipo = this.label.opt_troca; break;
            }
  
            switch(item.motivo)
            {
              case '1': item.desmotivo = this.label.opt_arrependimento; break;
              case '2': item.desmotivo = this.label.opt_atrasonaentrega; break;
              case '3': item.desmotivo = this.label.opt_recebiproderrado; break;
              case '4': item.desmotivo = this.label.opt_pedidoincompleto; break;
              case '5': item.desmotivo = this.label.opt_naogosteiqualidade; break;
              case '6': item.desmotivo = this.label.opt_naogosteiproduto; break;
              case '7': item.desmotivo = this.label.opt_produtocomdefeito; break;
            }
  
            item.desproduto = prod[0].descricao;
            item.valor = prod[0].valor;
            item.img = prod[0].img;
          });

        });
      }

      lib.preloader('hide');
    }catch (err) {
      console.log(err);
    }

  }

  async toggleDetalhes(item:any){
    //item é um ponteiro para o objeto da compra
    let orc = item.orc;
    let obj = document.getElementById(`box-detalhe-compra-${orc}`) as any;
    let iconDown = document.getElementById(`itd-${orc}`) as any;
    let iconUp = document.getElementById(`itu-${orc}`) as any;

    let visivel = obj.classList.contains('d-none');
    if (visivel) {
      obj.classList.remove('d-none');
      iconDown?.classList.add('d-none');
      iconUp?.classList.remove('d-none');
    } else {  
      obj.classList.add('d-none');
      iconDown?.classList.remove('d-none');
      iconUp?.classList.add('d-none');
    }
  }
}