import { Component, OnInit } from '@angular/core';
import { lib } from 'src/app/shared/services/common/lib.service';
import { ComprasService } from 'src/app/shared/services/compras/compras.service';
import { DocumentosService } from 'src/app/shared/services/documentos/documentos.service';
import { TitulosService } from 'src/app/shared/services/titulos/titulos.service';
import { TimelineService } from 'src/app/shared/services/timeline/timeline.service';
import { objTimeline } from 'src/app/shared/entities/timeline.entity';
import { DevolucaotrocaService } from 'src/app/shared/services/devolucaotroca/devolucaotroca.service';
import * as translations from './compras.translate.json';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';

const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.css'],
})

export class ComprasComponent implements OnInit {

  abrirMais: boolean = false;
  paginaAtual: number = 1;
  linhasPagina: number = 10

  minhasCompras: any = [];
  meusTitulos: any = [];
  label: any = {};
  language: any = lib.getSession('language');
  dtOptions: DataTables.Settings = {};
  translate: any = translations;
  pastaImagens = lib.getSession('pastaImagens');

  mostrarFiltros: boolean = false;
  displayModalDevTro:string = 'none';
  produtosDispDevTro: any = {};
  stepDevTro:number = 1;
  selecinouPrdDevTro:boolean = false;
  minhasDevTro:any = {};
  enderecoDevTro:any = {};

  constructor(
    private comprasSRV: ComprasService, 
    private documentosSRV: DocumentosService,
    private titulosSRV: TitulosService,
    private router: Router,
    private tlSRV: TimelineService,
    private dtSRV: DevolucaotrocaService
  ) {
    if (!lib.validarUsuarioLogado()) {
      this.router.navigate(['/home']);
    }
    this.label = lib.getTranslate(this.translate);
  }

  async ngOnInit() {
    lib.preloader('show');
    await this.buscarHistoricoCompras();
    this.setTimeline();
    lib.preloader('hide');
  }

  buscarMaisCompras() {
    this.paginaAtual = this.paginaAtual + 1;
    lib.preloader('show');
    this.abrirMais = false;
    this.buscarHistoricoCompras();
  }

  async buscarHistoricoCompras() {

    let objSes = lib.getSession('user');
    let cod_cliente = objSes.codigo;

    try {
      let res = await this.comprasSRV.getHistoricoCompras(cod_cliente, this.paginaAtual, this.linhasPagina).toPromise() as any;

      if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado") {
        // Swal.fire({
        //   icon: 'error',
        //   title: this.label.erro,
        //   text: res.coderror[this.language]
        // });
        return lib.preloader('hide');
      } else {
        res.response.SL1 = Object.values(res.response);//converte o objeto em array

        //se tiver o maximo de linhas == a quantidade de linhas por pagina
        //indica que tem que abrir mais registros
        if(res.response.SL1.length == this.linhasPagina){
          this.abrirMais = true;
        }
        
        for (var index = 0; index < res.response.SL1.length; index++) {
          const objSL1 = res.response.SL1[index];
          const objSL2 = Object.values(res.response.SL1[index].produtos);
          const objSL4 = Object.values(res.response.SL1[index].titulos);

          const objSL4_com_bol = objSL4.filter((w:any) => w.E1_TIPO == "BOL" || w.E1_TIPO == "BL");
          const objSL4_sem_bol = objSL4.filter((w:any) => w.E1_TIPO != "BOL" && w.E1_TIPO != "BL");

          let ems = `${objSL1.F2_EMISSAO.substring(6, 8)}/${objSL1.F2_EMISSAO.substring(4, 6)}/${objSL1.F2_EMISSAO.substring(0, 4)}`;
          let val = lib.convertMoney(objSL1.F2_VALBRUT);
          let desconto = 0;
          let total = 0;
          let produtos: any = [];
          let pagamentos: any = [];
          let frete = parseFloat(objSL1.F2_FRETE);
          let temAtraso = false;
          
          var infProdutos = "";
          switch(objSL1.F2_XSTAEN)
          {
            case "1": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_1}.` ;break;
            case "2": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_2}.` ;break;
            case "3": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_3}.` ;break;
            case "4": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_4}.` ;break;
            case "5": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_5}.` ;break;
            case "6": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_6}.` ;break;
            case "7": infProdutos = `${this.label.stsInfo} ${this.label.stsProd_7}.` ;break;
          };      

          //cria o array de produtos
          for (let j = 0; j < objSL2.length; j++) {
            //if (objSL1.L1_NUM == objSL2[j].L2_NUM) {
              var jP = `produto${j+1}`
              desconto += parseFloat(objSL1.produtos[jP].D2_DESC);
              total += parseFloat(objSL1.produtos[jP].D2_TOTAL);
              let img = './assets/img/box-cpp.png';

              if (typeof objSL1.produtos[jP]?.links?.link1?.url != 'undefined' && objSL1.produtos[jP]?.links?.link1?.url != '') {
                //valida se a imagem que retorna está ok usando base64
                try{
                  var b64 = await imageUrlToBase64(objSL1.produtos[jP].links.link1.url);
                  if(b64 != undefined)
                    img = objSL1.produtos[jP].links.link1.url;
                }catch{
                }
              }

              var qtdMax = parseFloat(objSL1.produtos[jP].D2_QUANT);
              var qtdDevTro = [];
              for(var indexDT = 0; indexDT < qtdMax; indexDT++){
                qtdDevTro.push(indexDT+1);
              }

              produtos.push({
                codigo        : objSL1.produtos[jP].D2_COD.trim(),
                descricao     : objSL1.produtos[jP].B1_DESC,
                quantidade    : objSL1.produtos[jP].D2_QUANT,
                valor         : lib.convertMoney(parseFloat(objSL1.produtos[jP].D2_PRCVEN) + 
                                parseFloat(objSL1.produtos[jP].D2_DESC)),
                valorUnitario : lib.convertMoney(objSL1.produtos[jP].D2_TOTAL),
                img           : img,
                qtdDevTro     : qtdDevTro
              });
            //}
          }
          
          //cria o array de pagamentos SEM BOLETO
          var lstTipo = [...new Set(objSL4_sem_bol.map((item:any) => item.E1_TIPO))];
          lstTipo.forEach((itTipo:any) => {
            var titDoTipo = objSL4_sem_bol.filter((w:any) => w.E1_TIPO == itTipo)

            var valorDotipo = 0;
            var data_vencimento = "";

            titDoTipo.forEach((itSum:any) => {
               valorDotipo = valorDotipo + parseFloat(itSum.E1_VALOR);

              //yyyymmdd para dd/mm/yyyy
              data_vencimento = `${itSum.E1_VENCTO.substring(6, 8)}/${itSum.E1_VENCTO.substring(4, 6)}/${itSum.E1_VENCTO.substring(0, 4)}`
            });

            if(itTipo=="NF"){
              if(titDoTipo.length>1){
                itTipo = "CC"; //pgto NF, como tem mais de um título, claro que foi no crédito
              }else{
                itTipo = "CCCD"; //pgto NF, como tem um título, não fica claro se foi no crédito ou débito
              }
            }

            pagamentos.push({
             tipo            : itTipo,
             descricao       : lib.getPaymentType(itTipo),
             valor           : lib.convertMoney(valorDotipo),
             vencimento      : "",
             data_vencimento : "",
             parcela         : titDoTipo.length,
             parcelaString   : "",
             total_parcelas  : "x",
             status          : "pago"
            });
          })

          //cria o array de pagamentos COM BOLETO
          for (let ii = 0; ii < objSL4_com_bol.length; ii++) {
            var iiP = `titulos${ii+1}`
            let status = '';
            let date_now = new Date();
            let month    = '' + (date_now.getMonth() + 1);
            let day      = '' + date_now.getDate();
            let year     = date_now.getFullYear();

            if (month.length < 2) 
                month = '0' + month;

            if (day.length < 2) 
                day = '0' + day;

            let date_now_str = year + month + day; // `${date_now.getFullYear()}${date_now.getMonth()+1}${date_now.getDate()}`;//yyyymmdd

            if (objSL1.titulos[iiP].E1_SALDO==0) {//se o saldo for 0, o titulo está pago
              status = 'pago';
            }else{
              if (objSL1.titulos[iiP].E1_VENCTO < date_now_str) {//se a data de vencimento for menor que a data atual, o titulo está atrasado
                status = 'atrasado';
                temAtraso = true;
              }else{//se a data de vencimento for maior que a data atual, o titulo está pendente
                status = 'pendente';
              }
            }

            //yyyymmdd para dd/mm/yyyy
            let data_vencimento = `${objSL1.titulos[iiP].E1_VENCTO.substring(6, 8)}/${objSL1.titulos[iiP].E1_VENCTO.substring(4, 6)}/${objSL1.titulos[iiP].E1_VENCTO.substring(0, 4)}`;
            
            //total_parcelas = onde o numero do pedido, numero do documento e filial forem iguais, conta quantos titulos tem
            let total_parcelas = Object.values(objSL1.titulos).filter((item:any)=>{
              return item.E1_NUM==objSL1.F2_DOC && 
                     item.E1_FILIAL==objSL1.F2_FILIAL &&
                     item.E1_TIPO==objSL1.titulos[iiP].E1_TIPO;}).length;
            
            //se o tipo do titulo for diferente de 0, adiciona no array de pagamentos
            if (objSL1.titulos[iiP].E1_TIPO) {
              pagamentos.push({
                tipo            : objSL1.titulos[iiP].E1_TIPO,
                descricao       : lib.getPaymentType(objSL1.titulos[iiP].E1_TIPO),
                valor           : lib.convertMoney(objSL1.titulos[iiP].E1_VALOR),
                vencimento      : data_vencimento,
                data_vencimento : objSL1.titulos[iiP].E1_VENCTO,
                parcela         : pagamentos.length + 1, //conta quanto pagamentos tem com o mesmo tipo
                parcelaString   : objSL1.titulos[iiP].E1_PARCELA,
                total_parcelas  : total_parcelas,
                status          : status
              });
            } 
          }

          if(objSL1.F2_PREFORI != undefined && objSL1.F2_PREFORI.trim() == ""){
            if(objSL1.F2_SERIE == "U"){
              objSL1.F2_PREFORI = "VDF";
            }
          }
          var resultadoPrefOri: any = lib.getPrefOriDescription(objSL1.F2_PREFORI, objSL1.F2_FILIAL);
          var origem: string = resultadoPrefOri.origem;
          var descFilial: string = resultadoPrefOri.descFilial;

          //cria o objeto da compra, verificando se o status é 5 (faturado) e se tem pagamentos e produtos
          this.minhasCompras.push({
            orc         : objSL1.F2_DOC.trim(),
            doc         : objSL1.F2_DOC.trim(),
            ser         : objSL1.F2_SERIE,
            fil         : objSL1.F2_FILIAL,
            ems         : ems,
            val         : val,
            dataEmissao : objSL1.F2_EMISSAO,
            cli         : cod_cliente,
            frete       : lib.convertMoney(frete),
            subtotal    : lib.convertMoney(total + desconto),
            desconto    : lib.convertMoney(desconto* -1),
            total       : lib.convertMoney(total + frete),
            infProdutos : infProdutos,
            stsProdutos : objSL1.F2_XSTAEN,
            produtos    : produtos,
            pagamentos  : pagamentos,
            infoAdd     : objSL1.F2_MENNOTA,
            temCanhoto  : true,
            temAtraso   : temAtraso,
            loja        : objSL1.F2_LOJA,
            origem      : origem,
            descFilial  : descFilial
          });
        }
      }
      //console.log(this.minhasCompras);
      
      lib.preloader('hide');
    } catch (err) {
      console.log(err);
    }
  }

  async toggleDetalhes(item:any) {
    //item é um ponteiro para o objeto da compra
    let orc = item.orc;
    let obj = document.getElementById(`box-detalhe-compra-${orc}`) as any;
    let iconDown = document.getElementById(`itd-${orc}`) as any;
    let iconUp = document.getElementById(`itu-${orc}`) as any;

    let visivel = obj.classList.contains('d-none');
    if (visivel) {
      obj.classList.remove('d-none');
      iconDown?.classList.add('d-none');
      iconUp?.classList.remove('d-none');
    } else {  
      obj.classList.add('d-none');
      iconDown?.classList.remove('d-none');
      iconUp?.classList.add('d-none');
    }
  }

  async baixarDocumento(compra: any, pagamento:any , tipo: string) {
    //console.log(compra, pagamento);
    
    //tipo = NF ou BL
    //item é um ponteiro para o objeto da compra
    let json = {
        "filial": compra.fil,
        "num": compra.doc,
        "cliente": compra.cli,
        "loja": compra.loja,
        "serie": compra.ser,
        "tipo": tipo,
        "parcela": ''
      }
      
    if (tipo == 'BL') {
      json.parcela = pagamento.parcelaString;
    }
    
    lib.preloader('show');
    const res = await this.documentosSRV.getDocument(json).toPromise() as any;
    var objRet = JSON.parse(res.dados.replace('\\t','').replaceAll( '\\', '').replaceAll(' ', '').replace('"{"dados":', '').replace(',}}"', '}'));
    var strBase64 = "";
    var nomArquivo = "";

    if(tipo == "NF"){
        strBase64 = objRet.Nota64;
        var arrayNome = objRet.Nota.split('/');
        nomArquivo = arrayNome[arrayNome.length - 1];
    }

    if(tipo == "BL"){
        strBase64 = objRet.Boleto64;
        var arrayNome = objRet.Boleto.split('/');
        nomArquivo = arrayNome[arrayNome.length - 1];
    }
    
    if (strBase64 != undefined && strBase64.trim() != ""){
        var link = document.createElement('a');
        link.href = 'data:application/pdf;base64,' + strBase64;
        link.download = nomArquivo;
        link.target = "pdf-frame";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } 
    lib.preloader('hide');
  }

  async baixarSetCanhoto(item: any) {
    lib.preloader('show');
    //item é um ponteiro para o objeto da compra
    let resMonitoraNota = await this.titulosSRV.monitorarNF(item.doc, item.ser, item.fil).toPromise() as any;
    if(resMonitoraNota.dados){
      resMonitoraNota = resMonitoraNota.dados;
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao baixar canhoto'
      });
      return;
    }
    let resCanhoto = await this.titulosSRV.retornarSetCanhoto(resMonitoraNota.DOC_CHV).toPromise() as any;
    if(resCanhoto.coderror){
      alert(resCanhoto.coderror);
      return;
    }
    resCanhoto = resCanhoto.response.dados;
    let imgCanhoto = resCanhoto.pedido_recebedor_assinatura;
    if(imgCanhoto == '' || imgCanhoto == undefined || imgCanhoto == null){
      Swal.fire({
        icon: 'error',
        title: 'Atenção',
        text: 'Não foi encontrado canhoto para este pedido'
      });
      lib.preloader('hide');
      return false;
    }
    var link = document.createElement('a');
    link.href = imgCanhoto;
    link.download = "canhoto.jpg";
    link.target = "img-frame";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    lib.preloader('hide');
    return true;
  }

  setTimeline(){
    let objSes = lib.getSession('user');

    var objTl:objTimeline = 
    {
        cod: objSes.codigo,
        pag: "compras",
        sts: true,
        msg: ""
    };

    this.tlSRV.setTimeline(objTl).toPromise();
  }

  dinamicaFiltros(){
    this.mostrarFiltros = !this.mostrarFiltros;
  }

  async buscarComFiltros(){
    lib.preloader('show');
    this.minhasCompras = [];
    this.mostrarFiltros = false;

    let objSes = lib.getSession('user');
    let cod_cliente = objSes.codigo;

    var emissao    = $('#inputFilEmissaoDe').val()?.toString() || "";
    var emissaoate = $('#inputFilEmissaoAte').val()?.toString() || "";
    var notas      = $('#inputFilNotas').val();
    var produto    = $('#inputFilProduto').val();

    try {
      let res = await this.comprasSRV.getHistoricoComprasFiltros( cod_cliente,
                                                                  this.paginaAtual,
                                                                  this.linhasPagina,
                                                                  emissao.split('-').join(''),
                                                                  emissaoate.split('-').join(''),
                                                                  notas,
                                                                  produto ).toPromise() as any;

      if ((res.coderror != undefined && res.coderror != '') || res.response == "Nenhum dado para ser consultado") {
        return lib.preloader('hide');
      } else {
        res.response.SL1 = Object.values(res.response);//converte o objeto em array

        //se tiver o maximo de linhas == a quantidade de linhas por pagina
        //indica que tem que abrir mais registros
        if(res.response.SL1.length == this.linhasPagina){
          this.abrirMais = true;
        }
        
        for (var index = 0; index < res.response.SL1.length; index++) {
          const objSL1 = res.response.SL1[index];
          const objSL2 = Object.values(res.response.SL1[index].produtos);
          const objSL4 = Object.values(res.response.SL1[index].titulos);

          const objSL4_com_bol = objSL4.filter((w:any) => w.E1_TIPO == "BOL" || w.E1_TIPO == "BL");
          const objSL4_sem_bol = objSL4.filter((w:any) => w.E1_TIPO != "BOL" && w.E1_TIPO != "BL");

          let ems = `${objSL1.F2_EMISSAO.substring(6, 8)}/${objSL1.F2_EMISSAO.substring(4, 6)}/${objSL1.F2_EMISSAO.substring(0, 4)}`;
          let val = lib.convertMoney(objSL1.F2_VALBRUT);
          let desconto = 0;
          let total = 0;
          let produtos: any = [];
          let pagamentos: any = [];
          let frete = parseFloat(objSL1.F2_FRETE);
          let temAtraso = false;
          
          //cria o array de produtos
          for (let j = 0; j < objSL2.length; j++) {
            //if (objSL1.L1_NUM == objSL2[j].L2_NUM) {
              var jP = `produto${j+1}`
              desconto += parseFloat(objSL1.produtos[jP].D2_DESC);
              total += parseFloat(objSL1.produtos[jP].D2_TOTAL);
              let img = './assets/img/box-cpp.png';
              if (typeof objSL1.produtos[jP]?.links?.link1?.url != 'undefined' && objSL1.produtos[jP]?.links?.link1?.url != '') {
                img = objSL1.produtos[jP].links.link1.url;
              }
              produtos.push({
                descricao: objSL1.produtos[jP].B1_DESC,
                quantidade: objSL1.produtos[jP].D2_QUANT,
                valor: lib.convertMoney(parseFloat(objSL1.produtos[jP].D2_PRCVEN) + 
                       parseFloat(objSL1.produtos[jP].D2_DESC)),
                valorUnitario: lib.convertMoney(objSL1.produtos[jP].D2_TOTAL),
                img: img
              });
            //}
          }
          
          //cria o array de pagamentos SEM BOLETO
          var lstTipo = [...new Set(objSL4_sem_bol.map((item:any) => item.E1_TIPO))];
          lstTipo.forEach((itTipo:any) => {
            var titDoTipo = objSL4_sem_bol.filter((w:any) => w.E1_TIPO == itTipo)

            var valorDotipo = 0;
            var data_vencimento = "";

            titDoTipo.forEach((itSum:any) => {
               valorDotipo = valorDotipo + parseFloat(itSum.E1_VALOR);

              //yyyymmdd para dd/mm/yyyy
              data_vencimento = `${itSum.E1_VENCTO.substring(6, 8)}/${itSum.E1_VENCTO.substring(4, 6)}/${itSum.E1_VENCTO.substring(0, 4)}`
            });

            if(itTipo=="NF"){
              if(titDoTipo.length>1){
                itTipo = "CC"; //pgto NF, como tem mais de um título, claro que foi no crédito
              }else{
                itTipo = "CCCD"; //pgto NF, como tem um título, não fica claro se foi no crédito ou débito
              }
            }

            pagamentos.push({
             tipo            : itTipo,
             descricao       : lib.getPaymentType(itTipo),
             valor           : lib.convertMoney(valorDotipo),
             vencimento      : "",
             data_vencimento : "",
             parcela         : titDoTipo.length,
             parcelaString   : "",
             total_parcelas  : "x",
             status          : "pago"
            });
          })

          //cria o array de pagamentos COM BOLETO
          for (let ii = 0; ii < objSL4_com_bol.length; ii++) {
            var iiP = `titulos${ii+1}`
            let status = '';
            let date_now = new Date();
            let month    = '' + (date_now.getMonth() + 1);
            let day      = '' + date_now.getDate();
            let year     = date_now.getFullYear();

            if (month.length < 2) 
                month = '0' + month;

            if (day.length < 2) 
                day = '0' + day;

            let date_now_str = year + month + day; // `${date_now.getFullYear()}${date_now.getMonth()+1}${date_now.getDate()}`;//yyyymmdd

            if (objSL1.titulos[iiP].E1_SALDO==0) {//se o saldo for 0, o titulo está pago
              status = 'pago';
            }else{
              if (objSL1.titulos[iiP].E1_VENCTO < date_now_str) {//se a data de vencimento for menor que a data atual, o titulo está atrasado
                status = 'atrasado';
                temAtraso = true;
              }else{//se a data de vencimento for maior que a data atual, o titulo está pendente
                status = 'pendente';
              }
            }

            //yyyymmdd para dd/mm/yyyy
            let data_vencimento = `${objSL1.titulos[iiP].E1_VENCTO.substring(6, 8)}/${objSL1.titulos[iiP].E1_VENCTO.substring(4, 6)}/${objSL1.titulos[iiP].E1_VENCTO.substring(0, 4)}`;
            
            //total_parcelas = onde o numero do pedido, numero do documento e filial forem iguais, conta quantos titulos tem
            let total_parcelas = Object.values(objSL1.titulos).filter((item:any)=>{
              return item.E1_NUM==objSL1.F2_DOC && 
                     item.E1_FILIAL==objSL1.F2_FILIAL &&
                     item.E1_TIPO==objSL1.titulos[iiP].E1_TIPO;}).length;
            
            //se o tipo do titulo for diferente de 0, adiciona no array de pagamentos
            if (objSL1.titulos[iiP].E1_TIPO) {
              pagamentos.push({
                tipo            : objSL1.titulos[iiP].E1_TIPO,
                descricao       : lib.getPaymentType(objSL1.titulos[iiP].E1_TIPO),
                valor           : lib.convertMoney(objSL1.titulos[iiP].E1_VALOR),
                vencimento      : data_vencimento,
                data_vencimento : objSL1.titulos[iiP].E1_VENCTO,
                parcela         : pagamentos.length + 1, //conta quanto pagamentos tem com o mesmo tipo
                parcelaString   : objSL1.titulos[iiP].E1_PARCELA,
                total_parcelas  : total_parcelas,
                status          : status
              });
            } 
          }

          if(objSL1.F2_PREFORI != undefined && objSL1.F2_PREFORI.trim() == ""){
            if(objSL1.F2_SERIE == "U"){
              objSL1.F2_PREFORI = "VDF";
            }
          }
          var resultadoPrefOri: any = lib.getPrefOriDescription(objSL1.F2_PREFORI, objSL1.F2_FILIAL);
          var origem: string = resultadoPrefOri.origem;
          var descFilial: string = resultadoPrefOri.descFilial;

          //cria o objeto da compra, verificando se o status é 5 (faturado) e se tem pagamentos e produtos
          this.minhasCompras.push({
            orc         : objSL1.F2_DOC,
            doc         : objSL1.F2_DOC,
            ser         : objSL1.F2_SERIE,
            fil         : objSL1.F2_FILIAL,
            ems         : ems,
            val         : val,
            dataEmissao : objSL1.F2_EMISSAO,
            cli         : cod_cliente,
            frete       : lib.convertMoney(frete),
            subtotal    : lib.convertMoney(total + desconto),
            desconto    : lib.convertMoney(desconto* -1),
            total       : lib.convertMoney(total + frete),
            produtos    : produtos,
            pagamentos  : pagamentos,
            infoAdd     : objSL1.F2_MENNOTA,
            temCanhoto  : true,
            temAtraso   : temAtraso,
            loja        : objSL1.F2_LOJA,
            origem      : origem,
            descFilial  : descFilial
          });
        }
      }
      
      lib.preloader('hide');
    } catch (err) {
      console.log(err);
    }
  }

  solicitarDevTro(numero:string, serie:string){
    this.displayModalDevTro = 'block';

    var labelNro = this.label.modal_tit;
    $('.span-tit-modal').html( `${labelNro}: ${numero}/${serie}` );
    $('#hdnNumeroSerie').val( `${numero}_${serie}` );

    var notaSel = this.minhasCompras.filter((w:any) => w.doc == numero && w.ser == serie);
    this.produtosDispDevTro = notaSel[0];
    this.stepDevTro = 1;

    var eleTit2 = document.getElementById('spanTit2') as HTMLSpanElement
    eleTit2.textContent = this.label.modal_tit_sptep1;

    this.selecinouPrdDevTro = false;
    var colChk = $('input[name=produtosDevTro]')

    if(colChk != undefined && colChk.length > 0){
      for(var index = 0; index < colChk.length; index++){
        var eleChk = colChk[index] as HTMLInputElement;
        eleChk.checked = false;
        var prd = eleChk.id.replace('chk_', '')
        var eleDiv = document.getElementById('divDevTro_'+prd) as HTMLDivElement;
        eleDiv.style.display = 'none';
      }
    }
  }

  voltarStepDevPro(){
    this.stepDevTro = this.stepDevTro - 1;

    switch(this.stepDevTro)
    {
      case 1:
        var eleTit2 = document.getElementById('spanTit2') as HTMLSpanElement
        eleTit2.textContent = this.label.modal_tit_sptep1;
        setTimeout( async() => { this.carregaDevTroInformada();}, 500)
        break;

      case 2:
        var eleTit2 = document.getElementById('spanTit2') as HTMLSpanElement
        eleTit2.textContent = this.label.modal_tit_sptep2;
        break;
    }  
  }
  
  carregaDevTroInformada(){
    var colDevTro = this.minhasDevTro.devolucoes;
    var colChk = $('input[name=produtosDevTro]')

    for(var index = 0; index < colChk.length; index++){
      var eleChk = colChk[index] as HTMLInputElement;
      var chave = eleChk.id.replace('chk_', '');
      var produtoSelecionado = colDevTro.filter((w:any)=> w.produto == chave);

      if(produtoSelecionado != undefined && produtoSelecionado.length > 0){
        eleChk.checked = true;
        var eleDiv = document.getElementById('divDevTro_'+chave) as HTMLDivElement;
        eleDiv.style.display = 'block';

        $('#selTipDevTro_'+chave).val(produtoSelecionado[0].tipo);
        $('#selQtdDevTro_'+chave).val(produtoSelecionado[0].quantidade);
        $('#selMotDevTro_'+chave).val(produtoSelecionado[0].motivo);
        $('#txtDesDevTro_'+chave).val(produtoSelecionado[0].descricao);
      }
    }
  }

  continuarStepDevPro(){
    switch(this.stepDevTro)
    {
      case 1:
        var arrayNumSer   = $('#hdnNumeroSerie').val()?.toString().split('_') || [];
        var numero        = arrayNumSer[0];
        var serie         = arrayNumSer[1];
        var notaSel       = this.minhasCompras.filter((w:any) => w.doc == numero && w.ser == serie);

        this.minhasDevTro = {};
        this.minhasDevTro = notaSel[0];
        this.minhasDevTro.devolucoes = [];

        var continuar = true;
        var colChk = $('input[name=produtosDevTro]')
        
        for(var index = 0; index < colChk.length; index++){
          var eleChk = colChk[index] as HTMLInputElement;
          
          if(eleChk.checked){
            var chave = eleChk.id.replace('chk_', '');
    
            var tip = $('#selTipDevTro_'+chave).val();
            var qtd = $('#selQtdDevTro_'+chave).val();
            var mot = $('#selMotDevTro_'+chave).val();
            var des = $('#txtDesDevTro_'+chave).val();
    
            if(tip == "" || qtd == "" || mot == ""){
              continuar = false;
              break;
            }else{
              //alimenta array
              this.minhasDevTro.devolucoes.push(
                {
                  produto    : chave,
                  tipo       : tip,
                  quantidade : qtd,
                  motivo     : mot,
                  descricao  : des
                }
              )

            }
          }
        }
    
        if(continuar){
          this.stepDevTro = this.stepDevTro + 1;

          var eleTit2 = document.getElementById('spanTit2') as HTMLSpanElement
          eleTit2.textContent = this.label.modal_tit_sptep2;

          var cliMongo:any = {};
      
          cliMongo.response = {};
          cliMongo = lib.getSession("obj_cliente");

          this.enderecoDevTro             = {}
          this.enderecoDevTro.cep         = cliMongo.response.cep;
          this.enderecoDevTro.municipio   = cliMongo.response.municipio;
          this.enderecoDevTro.estado      = cliMongo.response.estado;
          this.enderecoDevTro.bairro      = cliMongo.response.bairro;
          this.enderecoDevTro.endereco    = cliMongo.response.endereco;
          this.enderecoDevTro.complemento = cliMongo.response.complemento;

          console.log(this.minhasDevTro);
        }else{
          this.minhasDevTro = {};
          Swal.fire({
            title: "",
            text: "Todos os campos com (*) são obrigatórios",
            icon: 'info',
            confirmButtonText: "OK"
          }).then((result) => {})
        }
        break;

      case 2:
        Swal.fire({
          title: "Pronto!",
          text: "Os dados para devolução ou troca foram informados corretamente. Deseja concluir?",
          icon: 'question',
          confirmButtonText: this.label.qst_sim,
          showCancelButton: true,
          cancelButtonText: this.label.qst_nao,
        }).then((result) => {
            if (result.isConfirmed) {
                let objSes = lib.getSession('user');
                this.minhasDevTro.cliente = objSes.codigo;

                let respSave = this.dtSRV.setDevolucaotroca(this.minhasDevTro).toPromise() as any;
                this.fecharModal();

                Swal.fire({
                  title: "Solicitação realizada com sucesso!",
                  text: "Você pode acompnhar o status da solicitação na opção Devoluções/Trocas do menu.",
                  icon: 'success',
                  confirmButtonText: "OK"
                }).then((result) => {})
            }
        })
        break;

    }    
  }

  fecharModal(){
    this.stepDevTro = 1;
    this.displayModalDevTro = 'none';
  }

  mostrarFormulario(num:string, ser:string, prd:string){
    var eleDiv = document.getElementById('divDevTro_'+prd) as HTMLDivElement

    if(eleDiv.style.display == 'none')
      eleDiv.style.display = 'block';
    else
      eleDiv.style.display = 'none';

    var colChk = $('input[name=produtosDevTro]')
    var qtdChk = 0;

    for(var index = 0; index < colChk.length; index++){
      var eleChk = colChk[index] as HTMLInputElement;
      if(eleChk.checked){qtdChk++}
    }

    if(qtdChk>0)
      this.selecinouPrdDevTro = true;
    else
      this.selecinouPrdDevTro = false;
  }
}