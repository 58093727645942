import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class MenuService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getMenus(sigla_sistema:string):Observable<objRetorno>{
        return this.http.get("menus/" + sigla_sistema);
    }
}