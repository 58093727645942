import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../common/http.service';
import { objRetorno } from '../../entities/retorno.entity';

@Injectable({providedIn: 'root'})

export class AdminService{
    
    constructor (private http: HttpService<objRetorno>){
    }

    getDetalheMeuCliente(cliente:string):Observable<objRetorno>{
        return this.http.get("admin/WsGetDetalheMeuCliente/000000/" + cliente);
    }

    getRelatorioAvaliacoes(mesref:string):Observable<objRetorno>{
        return this.http.get("admin/WsGetRelatorioAvaliacoes/" + mesref);
    }
}